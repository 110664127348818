import React from 'react'
import styled from 'styled-components'
import { Typography, Row, Col, Spin } from 'antd'

import { useDownload } from '@hooks'

const FileLink = styled(Typography)`
  color: ${({ theme }) => theme.primaryColor};
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
`
const StyledCol = styled(Col)`
  margin: 10px 0 10px;
`

type Props = {
  file: { key: string; name: string }
}

const UploadedFile: React.FC<Props> = ({ file }) => {
  const { downloadFile, isLoading } = useDownload()

  return (
    <Spin spinning={isLoading}>
      <Row>
        <StyledCol
          onClick={() => {
            downloadFile({ key: file.key, name: file.name })
          }}
        >
          <FileLink>{file.name}</FileLink>
        </StyledCol>
      </Row>
    </Spin>
  )
}
export { UploadedFile }
