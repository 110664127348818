import { api } from '@api'
import { User } from '@types'

interface GetUserResponse {
  user: User
}

const getUser = async (id?: number) => {
  const response = await api.get<GetUserResponse>(`/users/${id}`)

  return response.data
}

export const UsersApi = { getUser }
