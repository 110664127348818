import { api } from '@api'
import { Supplier, SupplierFilters } from '@types'

interface GetSuppliersResponse {
  suppliers: Supplier[]
}

interface FilteredSuppliersResponse {
  suppliers: Supplier[]
  total: number
}

interface GetFilteredSuppliersResponse {
  suppliers: FilteredSuppliersResponse
}

type Params = {
  filters?: SupplierFilters
}

export interface Form {
  supplierId: number
}

const getSuppliers = async () => {
  const response = await api.get<GetSuppliersResponse>('/suppliers')

  return response.data.suppliers
}

const getSuppliersWithFilters = async (params: Params) => {
  const { filters } = params
  const response = await api.get<GetFilteredSuppliersResponse>('/suppliers/filters', {
    params: {
      ...filters,
    },
  })

  return response.data.suppliers
}

const changeOptions = async (payload: Form) => {
  const { supplierId, ...restOfValues } = payload
  const response = await api.put<GetSuppliersResponse>(`/suppliers/${supplierId}`, {
    supplier: restOfValues,
  })

  return response.data
}

export const SuppliersApi = { getSuppliers, getSuppliersWithFilters, changeOptions }
