import moment from 'moment'

const getExpiresInMiliseconds = () => {
  const now = moment()
  const midnight = moment().add(1, 'day').startOf('day')
  return midnight.diff(now, 'milliseconds', true)
}

export const AuthUtils = {
  getExpiresInMiliseconds,
}
