import styled from 'styled-components'
import { Col, Row, Select, Button } from 'antd'

interface ButtonProps {
  backgroundColor?: string
}

const ContainerTable = styled.div`
  padding: 2%;
`
const StyledSelect = styled(Select)`
  width: 100%;
`
const StyledRow = styled(Row)`
  margin: 15px 0 15px;
`
const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const StyledButton = styled(Button)<ButtonProps>`
  width: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor && backgroundColor};
  color: white;

  & p {
    width: 100%;
  }

  & > span > svg {
    color: white;
  }
`
const StyledSubButton = styled(Button)`
  padding: 0;
  margin: 0;
  white-space: break-spaces;
`

export { ContainerTable, StyledRow, StyledCol, StyledSelect, StyledButton, StyledSubButton }
export { AddSupplierDepotModal } from './AddSupplierDepotModal'
export { DepotAddressModal } from './DepotAddressModal'
