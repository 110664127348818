import { api } from '@api'
import { Invoice } from '@types'

import { Form } from '../../validations'

interface CreatePaymentResponse {
  invoice: Invoice
}

const createPayment = async (payload: Form & { id: number }) => {
  const { paymentFile, id, ...restOfPaymentValues } = payload

  const paymentPayload = new FormData()

  paymentPayload.append('paymentFile', paymentFile)
  paymentPayload.append('invoice', JSON.stringify(restOfPaymentValues))

  const response = await api.put<CreatePaymentResponse>(`/invoices/${id}/payment`, paymentPayload)

  return response.data
}

export const PaymentApi = { createPayment }
