import React from 'react'
import { useLocation } from 'react-router-dom'

import { useScrollToTop } from '@hooks'

import { StyledLayout } from './components'

const Layout: React.FC = ({ children }) => {
  const { pathname } = useLocation()
  useScrollToTop(pathname)

  return <StyledLayout>{children}</StyledLayout>
}

export { Layout }
