import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'

import { AuthApi, setAuthHeader } from '@api'
import { LOCAL_STORAGE_KEYS } from '@constants'

import { useLoginResponseManager } from '../useLoginResponseManager'
import { useLogout } from '../useLogout'

const useTokenLogin = (): { isLoginLoading: boolean } => {
  const [isLoginLoading, setIsLoginLoading] = useState(true)

  const { logout } = useLogout()

  const { onSucces } = useLoginResponseManager({ onSucces: () => setIsLoginLoading(false) })

  const { mutate: loginWithToken } = useMutation(AuthApi.loginWithToken, {
    onSuccess: ({ token, user }) => {
      setAuthHeader(token)
      window.localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, token)
      onSucces(user.id)
    },
    onError: () => {
      logout()
      setIsLoginLoading(false)
    },
  })

  useEffect(() => {
    const login = async () => {
      const token = window.localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN)
      if (!token) {
        setIsLoginLoading(false)
        return
      }
      setAuthHeader(token)
      loginWithToken()
    }
    login()
  }, [loginWithToken])

  return { isLoginLoading }
}

export { useTokenLogin }
