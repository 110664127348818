import React from 'react'
import { Modal, Col, Spin } from 'antd'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import { Ticket } from '@types'

import { DeleteTicketForm } from '../../../validations'
import { StyledRow, StyledSelect } from './components'

type Props = {
  open: boolean
  onClose: () => void
  onOk: (form: DeleteTicketForm, reset: () => void) => void
  title: string
  tickets?: Ticket[]
  isLoading: boolean
}

const DeleteTicketModal: React.FC<Props> = ({ open, onClose, title, onOk, tickets, isLoading }) => {
  const { t } = useTranslation()

  const validationDeleteTicketSchema = yup.object().shape({
    ticketId: yup.number().required(t('globals.obligatory_field')),
  })

  const { control, handleSubmit, reset } = useForm<DeleteTicketForm>({
    resolver: yupResolver(validationDeleteTicketSchema),
  })

  const handleDeleteTicket = (form: DeleteTicketForm) => {
    onOk(form, reset)
  }

  return (
    <Modal
      visible={open}
      onCancel={onClose}
      title={title}
      closable={false}
      okText="Ok"
      cancelText="Cancel"
      onOk={handleSubmit(handleDeleteTicket)}
    >
      <Spin spinning={isLoading}>
        <StyledRow>
          <Col span={8}>{t('suppliers_router.delete_ticket_modal.select_yard')}</Col>
          <Col span={12}>
            <Controller
              name="ticketId"
              control={control}
              render={({ field }) => (
                <StyledSelect
                  {...field}
                  placeholder={t('globals.placeholder_select')}
                  options={tickets?.map(ticket => {
                    return {
                      label: ticket.origin.address,
                      value: ticket.id,
                    }
                  })}
                />
              )}
            />
          </Col>
        </StyledRow>
      </Spin>
    </Modal>
  )
}

export { DeleteTicketModal }
