import { Table, Col, Row, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useEffect, useState } from 'react'

import { CarrierModeEnum, Invoice, Destination, Ticket } from '@types'

import { Wrapper, Container, SupplierDocumentation, NewInvoice, PaymentVoucher } from './components'
import { RailcarDocumentation } from './components/RailcarDocumentation/index'

interface TicketDetailProps {
  ticket: Ticket
}

const TAInvoice = ({ ticket }: TicketDetailProps) => {
  const { t } = useTranslation()
  const [newInvoice, setNewInvoice] = useState('')
  const [isUpdated, setIsUpdated] = useState(false)
  const [invoiceStatus, setInvoiceStatus] = useState<string | undefined>('')
  const dateFormat = 'MMM DD, hh:mm a'
  const dateHourFormat = 'MMM DD, hh:mm a'

  useEffect(() => {
    if (newInvoice) setIsUpdated(true)
    else setIsUpdated(false)
  }, [newInvoice])

  const columns = [
    {
      title: t('admin_router.admin_dashboard.columns.trip_id'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('admin_router.admin_dashboard.columns.date'),
      dataIndex: 'createAt',
      key: 'id',
      render: (date: Date) => moment(date).format(dateFormat),
    },
    {
      title: t('admin_router.admin_dashboard.columns.supplier'),
      dataIndex: 'supplier',
      key: 'businessName',
      render: (supplier?: { id: number; businessName: string }) =>
        supplier && <>{supplier.businessName}</>,
    },
    {
      title: t('admin_router.admin_dashboard.columns.invoice_number'),
      render: (showTicket: Ticket) =>
        showTicket.truckDocumentation ? (
          <>{showTicket.truckDocumentation.invoiceNumber}</>
        ) : (
          showTicket.railcarDocumentation && <>{showTicket.railcarDocumentation.invoiceNumber}</>
        ),
    },
    {
      title: t('admin_router.admin_dashboard.columns.ta_inv'),
      dataIndex: 'invoice',
      key: 'name',
      render: (invoice?: Invoice) =>
        isUpdated ? <>{newInvoice}</> : invoice && <>{invoice.number}</>,
    },
    {
      title: t('admin_router.admin_dashboard.columns.carrier'),
      dataIndex: 'carrier',
      key: 'id',
      render: (carrier?: { id: number; businessName: string }) =>
        carrier && <>{carrier.businessName}</>,
    },
    {
      title: t('admin_router.admin_dashboard.columns.origin'),
      dataIndex: 'origin',
      key: 'id',
      render: (depot?: {
        id: number
        city: string
        address: string
        location: { type: string; coordinates: [number, number] }
      }) => {
        const handleLinkClick = async () => {
          if (depot) {
            const mapUrl = `https://www.google.com/maps/search/?api=1&query=${depot.location.coordinates[0]},${depot.location.coordinates[1]}`
            window.open(mapUrl, '_blank')
          }
        }
        return (
          <>
            {depot && (
              <Button type="link" onClick={handleLinkClick}>
                {depot.city}
              </Button>
            )}
          </>
        )
      },
    },
    {
      title: t('admin_router.admin_dashboard.columns.destination'),
      dataIndex: 'destination',
      key: 'destination',
      render: (destination?: Destination) => destination?.name,
    },
    {
      title: t('tpl_router.tpl_dashboard.columns.withdrawal_date'),
      dataIndex: 'withdrawalDate',
      key: 'withdrawalDate',
      render: (withdrawalDate?: Date | string) => {
        if (!withdrawalDate || withdrawalDate === '') {
          return '-'
        }
        return moment(withdrawalDate).format(dateHourFormat)
      },
    },
    {
      title: t('admin_router.admin_dashboard.columns.status'),
      dataIndex: 'status',
      key: 'id',
      render: (status?: { name: string }) =>
        isUpdated ? <>{invoiceStatus}</> : <>{status?.name}</>,
    },
  ]

  return (
    <Container>
      <Table columns={columns} dataSource={ticket ? [ticket] : []} pagination={false} />
      <Row>
        <Col span={12}>
          <Wrapper>
            {ticket.carrier.mode === CarrierModeEnum.RAILCAR && (
              <RailcarDocumentation
                ticketId={ticket.id}
                supplierId={ticket.supplier.id}
                ticketRPA={ticket.invoice?.rpa?.id}
              />
            )}
            {ticket.carrier.mode === CarrierModeEnum.TRUCK && (
              <SupplierDocumentation
                ticketId={ticket.id}
                supplierId={ticket.supplier.id}
                ticketRPA={ticket.invoice?.rpa?.id}
              />
            )}
          </Wrapper>
        </Col>
        <Col span={12}>
          <Wrapper>
            <NewInvoice
              ticket={ticket}
              update={setNewInvoice}
              isUpdated={isUpdated}
              updateInvoiceStatus={setInvoiceStatus}
            />
            <PaymentVoucher ticketId={ticket.id} ticketStatus={ticket.status.name} />
          </Wrapper>
        </Col>
      </Row>
    </Container>
  )
}

export { TAInvoice }
