import { initializeApp } from 'firebase/app'
import { Analytics, getAnalytics } from 'firebase/analytics'

import { config } from '@config'

const firebaseConfig = {
  apiKey: config.apiKey,
  authDomain: config.authDomain,
  projectId: config.projectId,
  storageBucket: config.storageBucket,
  messagingSenderId: config.messagingSenderId,
  appId: config.appId,
  measurementId: config.measurementId,
}

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)

// Initialize Analytics and get a reference to the service
export const analytics: Analytics = getAnalytics(firebaseApp)
