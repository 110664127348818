/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useContext } from 'react'

import { User } from '@types'

type ContextState = {
  state: User | undefined
  setState: (newstate: User | undefined) => void
}

const initialState = {
  state: undefined,
  setState: () => {},
}

const UserContext = React.createContext<ContextState>(initialState)

export function UserProvider({ children }: { children: React.ReactNode }) {
  const [state, setState] = useState<User>()

  return <UserContext.Provider value={{ state, setState }}>{children}</UserContext.Provider>
}

export function useUser(): ContextState {
  const context = useContext(UserContext)
  if (!context) throw Error('This hook can only be used under the UserProvider component')
  return context
}
