import { api } from '@api'
import { Ticket } from '@types'

import { Form } from '../../../validations'

interface GetTicketsResponse {
  tickets: Ticket[]
}

const createTicket = async (payload: Form) => {
  const response = await api.post<GetTicketsResponse>('/tickets', { ticket: payload })

  return response.data
}

export const TicketsApi = { createTicket }
