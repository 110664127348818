import React, { useState } from 'react'
import { Col, DatePicker, InputNumber, Input, Modal, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import moment, { Moment } from 'moment'

import { useCarriers } from '@hooks'
import { CarrierModeEnum } from '@types'

import { StyledRow, StyledSelect, StyledText } from './components'
import { validationSchema, Form } from '../../validations'

type Props = {
  open: boolean
  loading: boolean
  onClose: () => void
  onOk: (form: Form, reset: () => void) => void
}

const CreateContractModal: React.FC<Props> = ({ open, loading, onClose, onOk }) => {
  const { t } = useTranslation()
  const { TextArea } = Input
  const [startDate, setStartDate] = useState<Moment>()
  const [endDate, setEndDate] = useState<Moment>()

  const { carriersFiltered, isLoading } = useCarriers({ mode: CarrierModeEnum.TRUCK })
  const { control, handleSubmit, reset } = useForm<Form>({
    resolver: yupResolver(validationSchema),
  })

  const submitHandler = (form: Form) => {
    onOk(form, reset)
  }

  const disabledStartDate = (current: Moment) => {
    if (endDate) return current.dayOfYear() >= endDate.dayOfYear()
    return false
  }

  const disabledEndDate = (current: Moment) => {
    if (startDate) return current.dayOfYear() <= startDate.dayOfYear()
    return false
  }

  const handleClose = () => {
    reset()
    setStartDate(undefined)
    setEndDate(undefined)
  }

  return (
    <Modal
      visible={open}
      onCancel={onClose}
      title={t('admin_router.admin_dashboard.contracts.carrier_contracts.title_create')}
      closable={false}
      okText={t('admin_router.admin_dashboard.contracts.button_ok')}
      cancelText={t('admin_router.admin_dashboard.contracts.button_cancel')}
      onOk={handleSubmit(submitHandler)}
      afterClose={handleClose}
      destroyOnClose
    >
      <Spin spinning={loading}>
        <StyledRow>
          <Col span={8}>
            <StyledText>
              {t('admin_router.admin_dashboard.contracts.carrier_contracts.TPL')}:
            </StyledText>
          </Col>
          <Col span={16}>
            <Controller
              name="carrierId"
              control={control}
              render={({ field }) => (
                <StyledSelect
                  placeholder={t('globals.placeholder_select')}
                  options={carriersFiltered}
                  loading={isLoading}
                  {...field}
                />
              )}
            />
          </Col>
        </StyledRow>
        <StyledRow>
          <Col span={8}>
            <StyledText>
              {t('admin_router.admin_dashboard.contracts.carrier_contracts.start_date')}:
            </StyledText>
          </Col>
          <Col span={8}>
            <Controller
              name="from"
              control={control}
              defaultValue={undefined}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  allowClear={false}
                  showToday={false}
                  value={field.value ? moment(field.value).utc() : undefined}
                  picker="date"
                  format="ll"
                  disabledDate={disabledStartDate}
                  onSelect={dateSelected => setStartDate(dateSelected)}
                />
              )}
            />
          </Col>
        </StyledRow>
        <StyledRow>
          <Col span={8}>
            <StyledText>
              {t('admin_router.admin_dashboard.contracts.carrier_contracts.finish_date')}:
            </StyledText>
          </Col>
          <Col span={8}>
            <Controller
              name="to"
              control={control}
              defaultValue={undefined}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  allowClear={false}
                  showToday={false}
                  value={field.value ? moment(field.value).utc().local() : undefined}
                  picker="date"
                  format="ll"
                  disabledDate={disabledEndDate}
                  onSelect={dateSelected => setEndDate(dateSelected)}
                />
              )}
            />
          </Col>
        </StyledRow>
        <StyledRow>
          <Col span={8}>
            <StyledText>
              {t('admin_router.admin_dashboard.contracts.carrier_contracts.price')}:
            </StyledText>
          </Col>
          <Col span={8}>
            <Controller
              name="price"
              control={control}
              render={({ field }) => <InputNumber {...field} min={1} />}
            />
          </Col>
        </StyledRow>
        <StyledRow>
          <Col span={8}>
            <StyledText>
              {t('admin_router.admin_dashboard.contracts.carrier_contracts.description')}:
            </StyledText>
          </Col>
          <Col span={16}>
            <Controller
              name="description"
              control={control}
              render={({ field }) => <TextArea {...field} />}
            />
          </Col>
        </StyledRow>
      </Spin>
    </Modal>
  )
}

export { CreateContractModal }
