import { createGlobalStyle } from 'styled-components'

import { theme } from './theme'

const GlobalStyles = createGlobalStyle`

  .anticon svg{
    color: ${theme.primaryColor};
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: white;
    border-color: black;
    &:after {
    border-color:black
  }
}

.ant-table-thead > tr > th {
    border-bottom: 1px solid black;
    &:before {
   width: 0px
  }
  }

`
export { GlobalStyles }
