import { notification } from 'antd'
import { useQuery } from 'react-query'

import { QUERY_KEYS } from '@api'

import { CountTickesApi } from './api'

const onError = () => {
  notification.error({
    message: 'Error al obtener los datos de carriers',
  })
}

const useCountTickesByCarrier = () => {
  const { data: carriers, isLoading } = useQuery(
    QUERY_KEYS.CARRIER_TICKETS_COUNT,
    CountTickesApi.getCount,
    {
      onError,
    },
  )

  return {
    carriers,
    isLoading,
  }
}

export { useCountTickesByCarrier }
