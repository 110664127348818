import styled from 'styled-components'
import { Drawer, Checkbox, Col, Input, InputNumber, Row, Radio } from 'antd'

const StyledDrawer = styled(Drawer)`
  & .ant-drawer-content {
    background-color: ${props => props.theme.lightGray};
  }
  & .ant-drawer-content .ant-drawer-header {
    background-color: ${props => props.theme.lightGray};
  }
  & .ant-drawer-content-wrapper {
    width: 100% !important;
  }
`

const StyledCheckboxGroup = styled(Checkbox.Group)`
  width: 100%;
  & > p {
    margin-bottom: 5px;
    margin-top: 15px;
    font-weight: bold;
  }
`

const StyledRadioGroup = styled(Radio.Group)`
  width: 100%;
  font-size: 14px !important;
  & > p {
    margin-bottom: 5px;
    margin-top: 15px;
    font-weight: bold;
  }
`

const ContainerCheckbox = styled(Col)`
  & > label {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 4px 0 4px;
    & span:last-child {
      padding-left: 0;
      flex: 1;
    }
  }
`

const ContainerRadio = styled(Col)`
  & > label {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 4px 0 4px;
    & span:last-child {
      padding-left: 0;
      flex: 1;
    }
  }
`

const Title = styled.p`
  font-weight: bold;
  margin-bottom: 0;
`

const StyledInput = styled(Input)`
  width: 100%;
`

const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`

const RowFilter = styled(Row)`
  margin-bottom: 10px;
`

export {
  StyledInputNumber,
  RowFilter,
  StyledInput,
  ContainerCheckbox,
  ContainerRadio,
  StyledCheckboxGroup,
  StyledDrawer,
  StyledRadioGroup,
  Title,
}
