import { notification } from 'antd'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'

import { TruckDocumentationApi } from './api'

const onError = () => {
  notification.error({
    message: 'Error updating truck',
  })
}

const useUpdateTruckDocumentation = () => {
  const history = useHistory()

  const onSuccess = () => {
    notification.success({
      message: 'Truck successfully loaded',
    })
    history.push('/dashboard/suppliers')
  }

  const { mutate, isLoading } = useMutation(TruckDocumentationApi.updateTruckDocumentation, {
    onSuccess,
    onError,
  })

  return {
    updateTruckDocumentation: mutate,
    isLoading,
  }
}

export { useUpdateTruckDocumentation }
