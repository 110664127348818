import React, { useState } from 'react'
import { Upload, Col, Typography, notification } from 'antd'
import styled from 'styled-components'
import { RcFile, UploadChangeParam } from 'antd/lib/upload'
import { useTranslation } from 'react-i18next'

import { UploadedFile } from '@components'

const StyledUploadButton = styled(Typography)`
  color: ${({ theme }) => theme.primaryColor};
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
  cursor: pointer;
`

interface Props {
  onChange: (file: File | null) => void
  defaultFiles?: { key?: string; name: string }[]
}

const UploadSingleFile: React.FC<Props> = ({ onChange, defaultFiles }) => {
  const [isImageUpload, setIsImageUpload] = useState(false)
  const { t } = useTranslation()

  const uploadButton = <StyledUploadButton>{t('globals.add_file')}...</StyledUploadButton>

  const handleChange = ({ fileList }: UploadChangeParam) => {
    if (fileList.length) {
      setIsImageUpload(true)
      onChange(fileList[0].originFileObj as File)
    }
  }

  const handleRemove = () => {
    setIsImageUpload(false)
    onChange(null)
  }

  return (
    <Col span={24}>
      <Upload
        className="avatar-uploader"
        beforeUpload={(file: RcFile) => {
          if (file.type !== 'application/pdf') {
            notification.error({
              message: t('globals.pdf_format'),
            })
          }
          return file.type === 'application/pdf' ? false : Upload.LIST_IGNORE
        }}
        onChange={handleChange}
        onRemove={handleRemove}
      >
        {!isImageUpload && uploadButton}
      </Upload>
      {defaultFiles?.map(file => (
        <UploadedFile
          key={file.key}
          file={{
            key: file.key!,
            name: file.name,
          }}
        />
      ))}
    </Col>
  )
}
export { UploadSingleFile }
