// import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Table, Col } from 'antd'
import { SelectValue } from 'antd/lib/select'

import { useDepotFilters } from '@contexts'
import { useDepots, useSuppliers } from '@hooks'
import { Depot, DepotFilters } from '@types'
import { theme } from '@styles'

import { useDepotsPagination } from './hooks'
import {
  AddSupplierDepotModal,
  // DepotAddressModal,
  ContainerTable,
  StyledRow,
  StyledCol,
  StyledSelect,
  StyledButton,
  StyledSubButton,
} from './components'

const DepotsAbm = () => {
  const { t } = useTranslation()
  const { filters, setFilters } = useDepotFilters()
  const { suppliersOptions, isLoading: isSuppliersLoading } = useSuppliers()
  const {
    depots,
    filteredDepots,
    isFilteredLoading,
    isDepotOptionsModalOpen,
    setIsDepotOptionsModalOpen,
    createDepot,
  } = useDepots(filters)
  const pagination = useDepotsPagination({ filters, setFilters }, filteredDepots?.total)

  const depotCities = Array.from(new Set(depots?.map(depot => depot.city))).map(city => ({
    label: city,
    value: city,
  }))

  const columns = [
    {
      title: t('admin_router.admin_dashboard.depotsabm.columns.depot_id'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('admin_router.admin_dashboard.depotsabm.columns.address'),
      dataIndex: 'addressLocation',
      key: 'address',
      render: (
        text: string,
        depot?: {
          id: number
          city: string
          address: string
          location: { type: string; coordinates: [number, number] }
        },
      ) => {
        const handleLinkClick = async () => {
          if (depot) {
            const mapUrl = `https://www.google.com/maps/search/?api=1&query=${depot.location.coordinates[0]},${depot.location.coordinates[1]}`
            window.open(mapUrl, '_blank')
          }
        }
        return (
          <>
            {depot && (
              <StyledSubButton type="link" onClick={handleLinkClick}>
                {depot.address}
              </StyledSubButton>
            )}
          </>
        )
      },
    },
    {
      title: t('admin_router.admin_dashboard.depotsabm.columns.city'),
      dataIndex: 'city',
      key: 'city',
      render: (depot: Depot) => <>{depot}</>,
    },
    {
      title: t('admin_router.admin_dashboard.depotsabm.columns.description'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: `${t('admin_router.admin_dashboard.depotsabm.columns.supplier')} ${t(
        'admin_router.admin_dashboard.depotsabm.columns.business_name',
      )}`,
      dataIndex: 'supplier',
      key: 'businessName',
      render: (supplier?: { id: number; businessName: string }) =>
        supplier && <>{supplier.businessName}</>,
    },
  ]
  // ------- PARA MOSTRAR DIRECCIONES / CONTENIDO EN UN MODAL
  /* 
  const [isDepotAddressModalVisible, setIsModalVisible] = useState(false)
  const [selectedDepotAddress, setSelectedDepotAddress] = useState('')

  const showDepotAddressModal = (address: string) => {
    setSelectedDepotAddress(address)
    setIsModalVisible(true)
  }

  const handleDepotAddressClose = () => {
    setIsModalVisible(false)
    setSelectedDepotAddress('')
  }
  */
  const handleSupplierFilterChange = (value: SelectValue) => {
    setFilters((prevState: DepotFilters) => ({
      ...prevState,
      page: 1,
      supplierId: [...(value as number[])],
    }))
  }

  const handleCityFilterChange = (value: SelectValue) => {
    console.log(value)
    setFilters((prevState: DepotFilters) => ({
      ...prevState,
      page: 1,
      city: value as string,
    }))
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFilterOptions = (input: string, option: any) => {
    if (option.label) return option.label.toString().toLowerCase().includes(input.toLowerCase())
    return true
  }

  const handleAddDepot = (
    supplierId: number,
    city: string,
    address: string,
    location: { type: string; coordinates: [number, number] },
    description: string,
    borderCrossingId: number,
    resetForm: () => void,
  ) => {
    const payload = {
      supplierId,
      city,
      address,
      location,
      description,
      borderCrossingId,
    }
    createDepot(payload)
    return resetForm()
  }

  return (
    <ContainerTable>
      <StyledRow gutter={[20, 20]}>
        <Col span={6}>
          <StyledSelect
            mode="multiple"
            allowClear
            placeholder={t('admin_router.filter_options.supplier')}
            options={suppliersOptions}
            filterOption={handleFilterOptions}
            onChange={handleSupplierFilterChange}
            loading={isSuppliersLoading}
            value={filters?.supplierId}
          />
        </Col>
        <Col span={6}>
          <StyledSelect
            showSearch
            allowClear
            placeholder="City"
            options={depotCities}
            filterOption={handleFilterOptions}
            onChange={handleCityFilterChange}
            loading={isSuppliersLoading}
            value={filters?.city}
          />
        </Col>
        <StyledCol>
          <StyledButton
            onClick={() => {
              setIsDepotOptionsModalOpen(true)
            }}
            backgroundColor={theme.primaryColor}
          >
            ADD NEW DEPOT
          </StyledButton>
        </StyledCol>
      </StyledRow>
      <Table
        columns={columns}
        dataSource={filteredDepots?.depots}
        loading={isFilteredLoading}
        pagination={pagination}
        rowKey="id"
      />
      <AddSupplierDepotModal
        open={isDepotOptionsModalOpen}
        options={[]}
        loading={isFilteredLoading}
        onClose={() => setIsDepotOptionsModalOpen(false)}
        onOk={handleAddDepot}
      />

      {/*     
      // ------- PARA MOSTRAR DIRECCIONES / CONTENIDO EN UN MODAL
      <DepotAddressModal
        visible={isDepotAddressModalVisible}
        address={selectedDepotAddress}
        onClose={handleDepotAddressClose}
      /> */}
    </ContainerTable>
  )
}

export { DepotsAbm }
