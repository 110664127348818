import styled from 'styled-components'
import { Row, Select, Typography } from 'antd'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .rejected {
    color: #eb2f96 !important;
  }
`

const Container = styled.div`
  flex: 1;
  margin-top: 5%;
`

const StyledRow = styled(Row)`
  margin: 15px 0 15px;
`

const StyledText = styled(Typography)`
  font-weight: bold;
`

const StyledAcceptIcon = styled.span`
  background-color: green;
  display: flex;
  align-items: center;
  width: fit-content;
  border-radius: 100px;
  border: solid 1px green;
`

const StyledSelect = styled(Select)`
  width: 20rem;

  @media ${props => props.theme.sm} {
    width: 20rem;
  }
`

const StyledIcon = styled.div`
  font-size: 1.3rem;
  padding-left: 1.3rem;
`

export { Wrapper, Container, StyledRow, StyledText, StyledAcceptIcon, StyledSelect, StyledIcon }
export { UploadSingleFile } from './UploadSingleFile'
