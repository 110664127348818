import { notification } from 'antd'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'

import { TruckDocumentationApi } from './api'

const onError = () => {
  notification.error({
    message: 'Error al actualizar datos',
  })
}

const useUpdateTruckDocumentation = () => {
  const history = useHistory()

  const onSuccess = () => {
    notification.success({
      message: 'Datos actualizados con éxito',
    })
    history.push('/dashboard/admin')
  }

  const { mutate, isLoading } = useMutation(TruckDocumentationApi.updateTruckDocumentation, {
    onSuccess,
    onError,
  })

  return {
    updateTruckDocumentation: mutate,
    isLoading,
  }
}

export { useUpdateTruckDocumentation }
