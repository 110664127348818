import { notification } from 'antd'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'

import { RailcarDocumentationApi } from './api'

const useUpdateRailcarDocumentation = () => {
  const history = useHistory()

  const onSuccess = () => {
    notification.success({
      message: 'Railcar updated successfully',
    })
    history.push('/dashboard/suppliers')
  }

  const onError = () => {
    notification.error({
      message: 'Railcar update error',
    })

    history.push('/dashboard/suppliers')
  }
  const { mutate, isLoading } = useMutation(RailcarDocumentationApi.updateRailcarDocumentation, {
    onSuccess,
    onError,
  })

  return {
    updateRailcarDocumentation: mutate,
    isLoading,
  }
}

export { useUpdateRailcarDocumentation }
