import { notification } from 'antd'
import { useQuery } from 'react-query'

import { QUERY_KEYS } from '@api'

import { InvoiceApi } from './api'

const onError = () => {
  notification.error({
    message: 'Error al obtener las facturas',
  })
}

const useInvoices = (ticketId: number) => {
  const { data, isLoading } = useQuery(
    QUERY_KEYS.INVOICES_BY_TICKET_ID,
    () => InvoiceApi.getInvoices(ticketId),
    {
      enabled: !!ticketId,
      onError,
      refetchOnWindowFocus: false,
    },
  )

  return {
    invoices: data ?? [],
    isLoading,
  }
}

export { useInvoices }
