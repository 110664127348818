import { useMutation } from 'react-query'
import { notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { logEvent } from 'firebase/analytics'

import { useLoginResponseManager } from '@hooks'
import { AuthApi, setAuthHeader } from '@api'
import { LOCAL_STORAGE_KEYS } from '@constants'

import { analytics } from '../../../../firebase/firebase'

interface UseLoginProps {
  onError?: () => void
}

const useLogin = (props?: UseLoginProps) => {
  const { onSucces } = useLoginResponseManager()

  const { t } = useTranslation()

  const { mutate, isLoading } = useMutation(AuthApi.login, {
    onSuccess: ({ token, user }) => {
      logEvent(analytics, 'login TA')

      setAuthHeader(token)
      window.localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, token)
      onSucces(user.id)

      const firebaseToken = window.localStorage.getItem(LOCAL_STORAGE_KEYS.FIREBASE)
      if (firebaseToken) AuthApi.saveFirebaseToken(user.id, firebaseToken)
    },
    onError: () => {
      if (props?.onError) {
        props.onError()
      }
      notification.error({
        message: t('globals.incorrect_user_or_password'),
      })
    },
  })

  return { login: mutate, isLoading }
}

export { useLogin }
