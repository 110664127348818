import { Col, DatePicker, Button, Input, Spin } from 'antd'
import { CheckCircleTwoTone, ClockCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import moment from 'moment'
import { Dispatch, SetStateAction, useContext, useEffect } from 'react'
import * as yup from 'yup'

import { TitleAndDivider } from '@components'
import { StringUtils } from '@utils'
import { InvoiceCheckEnum, StatusTicketEnum, Ticket } from '@types'
import { useBorderCrossings } from '@hooks'
import { TicketNotifContext, useUser } from '@contexts'

import { Form } from './validations'
import {
  Wrapper,
  Container,
  StyledRow,
  StyledText,
  UploadSingleFile,
  StyledSelect,
  StyledIcon,
} from './components'
import { useCreateInvoice, useUpdateInvoice } from './hooks'

interface Props {
  ticket: Ticket
  update: (value: string) => void
  isUpdated: boolean
  updateInvoiceStatus: Dispatch<SetStateAction<string | undefined>>
}

const NewInvoice: React.FC<Props> = ({ ticket, update, isUpdated, updateInvoiceStatus }) => {
  const { t } = useTranslation()
  const { createInvoice, isLoading: isCreateLoading, createdInvoice } = useCreateInvoice(ticket)
  const { updateInvoice, isLoading: isUpdateLoading, updatedInvoice } = useUpdateInvoice()
  const { allBordersOptions, allBordersLoading } = useBorderCrossings()
  const { setState } = useContext(TicketNotifContext)
  const { state: user } = useUser()

  const validationSchema = yup.object().shape({
    number: yup.string().required('Campo obligatorio'),
    date: yup.date().required('Campo obligatorio'),
    borderCrossingId: yup.number().required('Campo obligatorio'),
    invoice: ticket?.invoice ? yup.mixed().optional() : yup.mixed().required('Campo obligatorio'),
  })

  const { control, handleSubmit, setValue, reset, watch } = useForm<Form>({
    resolver: yupResolver(validationSchema),
  })

  const numberWatch = watch('number')

  useEffect(() => {
    if (createdInvoice) {
      setValue('date', moment(createdInvoice.invoice.date).toDate())
      setValue('number', createdInvoice.invoice.number)
      update(createdInvoice.invoice!.number)
      updateInvoiceStatus(StatusTicketEnum.TA_INVOICED)
    } else if (updatedInvoice) {
      setValue('date', moment(updatedInvoice.invoice.date).toDate())
      setValue('number', updatedInvoice.invoice.number)
      update(updatedInvoice.invoice!.number)
      updateInvoiceStatus(StatusTicketEnum.TA_INVOICED)
    } else if (ticket.invoice) {
      setValue('date', moment(ticket.invoice.date).toDate())
      setValue('number', ticket.invoice.number)
      if (ticket.invoice.borderCrossing)
        setValue('borderCrossingId', ticket.invoice.borderCrossing.id)
    } else {
      reset({
        date: undefined,
        number: '',
        borderCrossingId: ticket.origin.borderCrossing.id,
      })
    }
  }, [
    ticket,
    createdInvoice,
    updatedInvoice,
    reset,
    setValue,
    isUpdated,
    update,
    updateInvoiceStatus,
  ])

  const handleForm = (form: Form) => {
    if (ticket?.invoice) {
      setState(ticket.id)
      return updateInvoice({ ...form, invoiceId: ticket!.invoice!.id! })
    }
    setState(ticket.id)
    return createInvoice({ ...form, ticketId: ticket!.id })
  }

  return (
    <Wrapper>
      <Spin spinning={isCreateLoading ?? isUpdateLoading}>
        <Container>
          <TitleAndDivider title={t('ta_invoice.new_invoice.title')} />
          <StyledRow>
            <Col span={8}>
              <StyledText>{t('ta_invoice.new_invoice.customs')}</StyledText>
            </Col>
            <Col span={8}>
              <Controller
                control={control}
                name="borderCrossingId"
                defaultValue={
                  ticket.invoice?.borderCrossing
                    ? ticket.invoice.borderCrossing.id
                    : ticket.origin.borderCrossing.id
                }
                render={({ field }) => (
                  <StyledSelect
                    {...field}
                    placeholder={t('globals.placeholder_select')}
                    options={allBordersOptions}
                    loading={allBordersLoading}
                    disabled={Boolean(ticket.invoice?.rpa)}
                    value={field.value}
                  />
                )}
              />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col span={8}>
              <StyledText>{t('ta_invoice.new_invoice.invoice_date')}:</StyledText>
            </Col>
            <Col span={8}>
              <Controller
                name="date"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    allowClear={false}
                    value={field.value ? moment(field.value).utc(true) : undefined}
                    picker="date"
                    format="ll"
                    disabled={Boolean(ticket.invoice?.rpa)}
                  />
                )}
              />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col span={8}>
              <StyledText>{t('ta_invoice.new_invoice.invoice_number')}:</StyledText>
            </Col>
            <Col span={8}>
              <Controller
                control={control}
                name="number"
                render={({ field }) => (
                  <Input
                    {...field}
                    className={
                      ticket.invoice?.sapValidationStatus === InvoiceCheckEnum.REJECTED &&
                      numberWatch === ticket.invoice.number
                        ? 'rejected'
                        : ''
                    }
                    disabled={Boolean(ticket.invoice?.rpa)}
                  />
                )}
              />
            </Col>
            <Col span={8}>
              <StyledIcon>
                {ticket.invoice &&
                  (ticket.invoice.sapValidationStatus === InvoiceCheckEnum.PENDING ||
                    numberWatch !== ticket.invoice.number) && (
                    <ClockCircleTwoTone twoToneColor="#3388ff" />
                  )}
                {ticket.invoice?.sapValidationStatus === InvoiceCheckEnum.CONFIRMED &&
                  numberWatch === ticket.invoice.number && (
                    <CheckCircleTwoTone twoToneColor="#33ff4e" />
                  )}
                {ticket.invoice?.sapValidationStatus === InvoiceCheckEnum.REJECTED &&
                  numberWatch === ticket.invoice.number && (
                    <CloseCircleTwoTone twoToneColor="#eb2f96" />
                  )}
              </StyledIcon>
            </Col>
          </StyledRow>
          <StyledRow>
            <Col span={8}>
              <StyledText>{t('ta_invoice.new_invoice.invoice_sell')}:</StyledText>
            </Col>
            <Col span={16}>
              <Controller
                control={control}
                name="invoice"
                render={({ field }) => (
                  <UploadSingleFile
                    {...field}
                    defaultFiles={
                      ticket.invoice &&
                      [ticket.invoice].map(invoice => ({
                        key: invoice!.invoiceFile,
                        name: StringUtils.formatFilename(
                          ticket.id,
                          invoice!.invoiceFile,
                          'ta-invoice',
                          'pdf',
                        ),
                      }))
                    }
                  />
                )}
              />
            </Col>
          </StyledRow>
          {user?.roles[0].name === 'ADMIN' && (
            <Button onClick={handleSubmit(handleForm)} disabled={Boolean(ticket.invoice?.rpa)}>
              {ticket?.invoice || isUpdated
                ? t('ta_invoice.new_invoice.update')
                : t('ta_invoice.new_invoice.upload')}
            </Button>
          )}
        </Container>
      </Spin>
    </Wrapper>
  )
}

export { NewInvoice }
