import { useState } from 'react'
import { notification } from 'antd'
import { useMutation, useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { QUERY_KEYS } from '@api'
import { DepotFilters } from '@types'

import { DepotsApi } from './api'

const onError = () => {
  notification.error({
    message: 'Error al obtener los depots',
  })
}

const useDepots = (filters?: DepotFilters) => {
  const history = useHistory()
  const { t } = useTranslation()
  const [isDepotOptionsModalOpen, setIsDepotOptionsModalOpen] = useState(false)

  const { data, isLoading } = useQuery([QUERY_KEYS.DEPOTS], DepotsApi.getAllDepots, {
    onError,
  })

  const { data: filteredData, isLoading: isFilteredLoading } = useQuery(
    [QUERY_KEYS.DEPOTS, { filters }],
    () => DepotsApi.getDepotsWithFilters({ filters }),
    {
      onError,
    },
  )

  // const depotsFiltered = filteredData?.depots.map(depots => {
  //   return {
  //     label: depots.city,
  //     value: `${depots.city}`,
  //   }
  // })

  const { mutate: createDepot } = useMutation(DepotsApi.createDepot, {
    onSuccess: dataCreated => {
      notification.success({
        message: `Depot ID ${dataCreated.id}: ${t(
          'admin_router.admin_dashboard.depotsabm.notifications.new_depot.success',
        )}`,
      })
      history.push('/dashboard/admin/depotsabm')
    },
    onError,
  })

  return {
    depots: data,
    filteredDepots: filteredData,
    isLoading,
    isFilteredLoading,
    // depotsFiltered,
    isDepotOptionsModalOpen,
    createDepot,
    setIsDepotOptionsModalOpen,
  }
}

export { useDepots }
