import React from 'react'
import { Row, Col, Modal, Input } from 'antd'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

import { StyledSelect } from './components'

type Props = {
  open: boolean
  options: { label: string; value: number }[] | undefined
  loading: boolean
  assignedCarrier: number | undefined
  onClose: () => void
  onOk: (carrierId: number, reason: string, reset: () => void) => void
}

type ChangeCarrierPayload = {
  carrierId: number
  reason: string
}

const { TextArea } = Input

const validationSchema = yup.object().shape({
  carrierId: yup.number().required('Campo obligatorio'),
  reason: yup.string().required('Campo obligatorio'),
})

const ChangeCarrierModal: React.FC<Props> = ({ open, options, assignedCarrier, onClose, onOk }) => {
  const { t } = useTranslation()

  const { control, handleSubmit, reset } = useForm<ChangeCarrierPayload>({
    resolver: yupResolver(validationSchema),
  })

  const handleChangeCarrier = (payload: ChangeCarrierPayload) => {
    const { carrierId, reason } = payload
    onOk(carrierId, reason, reset)
  }

  const filteredOptions = options?.filter(carrier => carrier.value !== assignedCarrier)

  return (
    <Modal
      visible={open}
      onCancel={onClose}
      title={t('tpl_router.tpl_dashboard.change_carrier_modal.title')}
      closable={false}
      okText={t('tpl_router.tpl_dashboard.change_carrier_modal.button_ok')}
      cancelText={t('tpl_router.tpl_dashboard.change_carrier_modal.button_cancel')}
      onOk={handleSubmit(handleChangeCarrier)}
      destroyOnClose
    >
      <Row>
        <Col span={8}>
          <p>{t('tpl_router.tpl_dashboard.change_carrier_modal.oldCarrier')}:</p>
        </Col>
        <Col span={16}>
          <StyledSelect
            options={options}
            defaultValue={assignedCarrier}
            disabled
            showArrow={false}
          />
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <p>{t('tpl_router.tpl_dashboard.change_carrier_modal.newCarrier')}: </p>
        </Col>
        <Col span={16}>
          <Controller
            name="carrierId"
            control={control}
            render={({ field }) => (
              <StyledSelect
                {...field}
                options={filteredOptions}
                placeholder={t('tpl_router.tpl_dashboard.change_carrier_modal.select_placeholder')}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <p>{t('tpl_router.tpl_dashboard.change_carrier_modal.reason')}:</p>
        </Col>
        <Col span={16}>
          <Controller
            name="reason"
            control={control}
            render={({ field }) => <TextArea {...field} />}
          />
        </Col>
      </Row>
    </Modal>
  )
}

export { ChangeCarrierModal }
