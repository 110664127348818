import React from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox, Radio, RadioChangeEvent } from 'antd'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import { valueType } from 'antd/lib/statistic/utils'

import { CarrierModeEnum, Filters, StatusTicketEnum } from '@types'
import { useDestinations } from '@hooks'
import { RailcarIcon, TruckIcon } from '@icons'

import {
  ContainerCheckbox,
  ContainerRadio,
  StyledCheckboxGroup,
  StyledDrawer,
  StyledRadioGroup,
} from './components'

type Props = {
  open: boolean
  onClose: () => void
  onChangeTicketIdFilter?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onChangeStatusFilter: (value: CheckboxValueType[]) => void
  onChangeOrderByFilter: (value: RadioChangeEvent) => void
  onChangeInvoiceNumberFilter?: (value: valueType) => void
  onChangeDestinationFilter: (value: CheckboxValueType[]) => void
  onChangeModeFilter: (value: CheckboxValueType[]) => void
  filters?: Filters
}

const MobileFilters: React.FC<Props> = ({
  open,
  onClose,
  onChangeStatusFilter,
  onChangeOrderByFilter,
  onChangeDestinationFilter,
  onChangeModeFilter,
  filters,
}) => {
  const { t } = useTranslation()
  const { destinationsOptions } = useDestinations()

  const statusCheckboxes = [
    {
      name: t('suppliers_router.filter_options.status.title'),
      checkboxes: [
        {
          label: `${t('suppliers_router.filter_options.status.pending')}`,
          value: StatusTicketEnum.PENDING,
        },
        {
          label: `${t('suppliers_router.filter_options.status.confirmed')}`,
          value: StatusTicketEnum.CONFIRMED,
        },
        {
          label: `${t('suppliers_router.filter_options.status.invoiced')}`,
          value: StatusTicketEnum.INVOICED,
        },
      ],
    },
  ]

  const carrierModeOptions = [
    {
      label: (
        <>
          <RailcarIcon height={15} width={15} />{' '}
          {t('suppliers_router.filter_options.carrier.railcar')}
        </>
      ),
      value: CarrierModeEnum.RAILCAR,
    },
    {
      label: (
        <>
          <TruckIcon height={15} width={15} /> {t('suppliers_router.filter_options.carrier.truck')}
        </>
      ),
      value: CarrierModeEnum.TRUCK,
    },
  ]

  const orderByCheckboxes = [
    {
      name: t('suppliers_router.filter_options.order_by.title'),
      radioButtons: [
        {
          label: `${t('suppliers_router.filter_options.order_by.ascending')}`,
          value: 'ASC',
        },
        {
          label: `${t('suppliers_router.filter_options.order_by.descending')}`,
          value: 'DESC',
        },
        {
          label: `${t('suppliers_router.filter_options.order_by.none')}`,
          value: undefined,
        },
      ],
    },
  ]

  return (
    <StyledDrawer
      title="Filter By"
      placement="right"
      onClose={onClose}
      visible={open}
      getContainer={false}
    >
      {statusCheckboxes.map(({ name, checkboxes }) => (
        <StyledCheckboxGroup onChange={onChangeStatusFilter} value={filters?.statusName}>
          <p>{name}</p>
          {checkboxes.map(({ label, value }) => (
            <ContainerCheckbox span={24}>
              <Checkbox value={value}>{label}</Checkbox>
            </ContainerCheckbox>
          ))}
        </StyledCheckboxGroup>
      ))}
      {orderByCheckboxes.map(({ name, radioButtons }) => (
        <StyledRadioGroup onChange={onChangeOrderByFilter} value={filters?.orderBy}>
          <p>{name}</p>
          {radioButtons.map(({ label, value }) => (
            <ContainerRadio span={24}>
              <Radio value={value}>{label}</Radio>
            </ContainerRadio>
          ))}
        </StyledRadioGroup>
      ))}
      <StyledCheckboxGroup onChange={onChangeDestinationFilter} value={filters?.destinationId}>
        <p>{t('suppliers_router.filter_options.destination.title')}</p>
        {destinationsOptions?.map(destination => (
          <ContainerCheckbox span={24}>
            <Checkbox value={destination.value}>{destination.label}</Checkbox>
          </ContainerCheckbox>
        ))}
      </StyledCheckboxGroup>
      <StyledCheckboxGroup onChange={onChangeModeFilter} value={filters?.carrierMode}>
        <p>{t('suppliers_router.filter_options.carrier.mode')}</p>
        {carrierModeOptions?.map(mode => (
          <ContainerCheckbox span={24}>
            <Checkbox value={mode.value}>{mode.label}</Checkbox>
          </ContainerCheckbox>
        ))}
      </StyledCheckboxGroup>
    </StyledDrawer>
  )
}

export { MobileFilters }
