import { api } from '@api'

type DownloadPayload = { key: string; name: string }
interface DownloadResponse {
  file: {
    Body: { data: ArrayBuffer }
  }
  name: string
}
const downloadFile = async ({ key, name }: DownloadPayload) => {
  const response = await api.put<DownloadResponse>('/download', { key, name })

  return response.data
}

export const DownloadApi = { downloadFile }
