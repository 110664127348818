import { Spin } from 'antd'
import styled from 'styled-components'

const SpinContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LoadingSpinner = () => (
  <SpinContainer>
    <Spin />
  </SpinContainer>
)

export { LoadingSpinner }
