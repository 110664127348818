import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import { useUser } from '../contexts'
import { LOCAL_STORAGE_KEYS } from '../constants'
import { removeAuthHeader } from '../api'

const setInterceptors = (api: AxiosInstance): AxiosInstance => {
  console.log('configuring interceptors')
  api.interceptors.response.use(
    (response: AxiosResponse) => {
      return response
    },
    async (error: AxiosError) => {
      console.debug(
        'Request error',
        '\n',
        error,
        error.response?.data.error.type,
        error.response?.data.error.message,
        error.response?.data.error.status,
      )
      if (error.response?.status === 401 || error.response?.data.message === '401 Unauthorized') {
        const { setState } = useUser()
        setState(undefined)
        removeAuthHeader()
        window.localStorage.removeItem(LOCAL_STORAGE_KEYS.TOKEN)

        return Promise.reject(error)
      }
      // return Promise.resolve(error)
      return Promise.reject(error)
    },
  )
  return api
}

export { setInterceptors }
