import { Col, Spin } from 'antd'
import moment from 'moment'

import { useTicketsCountByState } from '@hooks'
import { Filters, StatusTicketEnum } from '@types'
import { useFilters } from '@contexts'

import {
  StatusName,
  StyledCard,
  TotalContainer,
  StyledRow,
  TotalText,
  MoreThan24HoursText,
} from './components'

const statusList = [
  StatusTicketEnum.PENDING,
  StatusTicketEnum.CONFIRMED,
  StatusTicketEnum.SUPPLIER_INVOICED,
  StatusTicketEnum.TA_INVOICED,
  StatusTicketEnum.MX_TRANSIT,
]

const CountIndicator = () => {
  const { count, isLoading } = useTicketsCountByState()

  const { setFilters } = useFilters()

  const handleClickOnTotal = (value: string) => {
    setFilters((prevState: Filters) => ({
      ...prevState,
      page: 1,
      statusName: [value],
      beforeDate: undefined,
      ticketId: undefined,
      invoiceNumber: undefined,
      taInvoiceNumber: undefined,
    }))
  }

  const handleClickOnLastDay = (value: string) => {
    setFilters((prevState: Filters) => ({
      ...prevState,
      page: 1,
      statusName: [value],
      beforeDate: moment(new Date()).subtract(1, 'day').toDate(),
      ticketId: undefined,
      invoiceNumber: undefined,
      taInvoiceNumber: undefined,
    }))
  }

  return (
    <StyledRow gutter={[30, 20]}>
      {statusList.map(status => (
        <Col key={`${status}`}>
          <StyledCard>
            {isLoading && <Spin />}
            <TotalContainer>
              <TotalText onClick={() => handleClickOnTotal(status)}>
                {count && `${count[status]?.total ?? 0}`}
              </TotalText>
              {!!count &&
                (status === StatusTicketEnum.PENDING || status === StatusTicketEnum.CONFIRMED) &&
                !!count[status]?.oldestCount && (
                  <MoreThan24HoursText onClick={() => handleClickOnLastDay(status)}>
                    (<span>{count[status].oldestCount}</span>)
                  </MoreThan24HoursText>
                )}
            </TotalContainer>
            <StatusName onClick={() => handleClickOnTotal(status)}>{status}</StatusName>
          </StyledCard>
        </Col>
      ))}
    </StyledRow>
  )
}

export { CountIndicator }
