/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useContext, Dispatch, SetStateAction } from 'react'

import { Filters, StatusTicketEnum } from '@types'

type ContextState = {
  filters?: Filters
  setFilters: Dispatch<SetStateAction<Filters>>
}

const initialState = {
  filters: {
    statusName: [],
    page: 1,
    limit: 50,
  },
  setFilters: () => {},
}

const FiltersContext = React.createContext<ContextState>(initialState)

export function FiltersProvider({ children }: { children: React.ReactNode }) {
  const [filters, setFilters] = useState<Filters>({
    statusName: [
      StatusTicketEnum.PENDING,
      StatusTicketEnum.CONFIRMED,
      StatusTicketEnum.INVOICED,
      StatusTicketEnum.TRANSIT,
      StatusTicketEnum.DELIVERED,
      StatusTicketEnum.AWAITING,
      StatusTicketEnum.RELEASED,
      StatusTicketEnum.SUPPLIER_INVOICED,
      StatusTicketEnum.TA_INVOICED,
      StatusTicketEnum.MX_TRANSIT,
    ],
    page: 1,
    limit: 50,
  })

  return (
    <FiltersContext.Provider value={{ filters, setFilters }}>{children}</FiltersContext.Provider>
  )
}

export function useFilters(): ContextState {
  const context = useContext(FiltersContext)
  if (!context) throw Error('This hook can only be used under the FiltersProvider component')
  return context
}
