import { useTranslation } from 'react-i18next'
import { Col } from 'antd'

import { Ticket } from '@types'
import { StringUtils } from '@utils'
import { TitleAndDivider, UploadedFile } from '@components'

import { Wrapper, StyledRow, StyledText, ContainerRow } from './components'
import { CustomsTable } from '../components/CustomsTable'

interface TicketDetailProps {
  ticket: Ticket
}

const TruckDocumentation = ({ ticket }: TicketDetailProps) => {
  const { t } = useTranslation()

  const truckDocumentation = ticket.truckDocumentation!

  const getLastFile = (files: string[], name: string, extension: string) => {
    const file = files[files.length - 1]
    return {
      key: file,
      name: StringUtils.formatFilename(ticket.id, file, name, extension),
    }
  }

  const getLastTaInvoiceFile = () => {
    const lastFile = ticket.invoice!.invoiceFile
    return {
      key: lastFile,
      name: StringUtils.formatFilename(ticket.id, lastFile, 'TA-InvoiceSell', 'pdf'),
    }
  }

  return (
    <Wrapper>
      <CustomsTable dataSource={[ticket]} showActions={false} />
      <ContainerRow>
        <Col xs={24} md={12}>
          <TitleAndDivider title={t('customs_router.documentation.title')} />
          <StyledRow>
            <Col span={8}>
              <StyledText>
                {t(
                  'ta_invoice.add_truck_documentation.title_and_divider.supplier_documentation.weight_certificate',
                )}
                :
              </StyledText>
            </Col>
            <Col span={16}>
              <UploadedFile
                file={getLastFile(truckDocumentation.weightCertificate, 'WeightCertificate', 'pdf')}
              />
            </Col>
            <Col span={8}>
              <StyledText> B/L:</StyledText>
            </Col>
            <Col span={16}>
              <UploadedFile file={getLastFile(truckDocumentation.bl, 'bl', 'pdf')} />
            </Col>
            <Col span={8}>
              <StyledText>No Radioact. Cert.:</StyledText>
            </Col>
            <Col span={16}>
              <UploadedFile
                file={getLastFile(truckDocumentation.radioactiveCertificate, 'radioact', 'pdf')}
              />
            </Col>
            <Col span={8}>
              <StyledText>
                {t(
                  'ta_invoice.add_truck_documentation.title_and_divider.supplier_documentation.photos',
                )}
                :
              </StyledText>
            </Col>
            <Col span={16}>
              <UploadedFile file={getLastFile(truckDocumentation.photos, 'photo', 'png')} />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col span={8}>
              <StyledText>{t('customs_router.documentation.ta_invoice_sell')}:</StyledText>
            </Col>
            <Col span={16}>
              <UploadedFile file={getLastTaInvoiceFile()} />
            </Col>
          </StyledRow>
        </Col>
      </ContainerRow>
    </Wrapper>
  )
}

export { TruckDocumentation }
