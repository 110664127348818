import { notification } from 'antd'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { QUERY_KEYS } from '@api'
import { RPAFilters } from '@types'

import { RPAApi } from './api'

const useRPA = (filters?: RPAFilters) => {
  const queryClient = useQueryClient()

  const onError = () => {
    notification.error({
      message: 'Error retrieving data',
    })
  }

  const onSuccess = () => {
    queryClient.removeQueries(QUERY_KEYS.RPA, { inactive: true })
    queryClient.removeQueries(QUERY_KEYS.INVOICES_BY_DATE, { inactive: true })
  }

  const { data, isLoading } = useQuery(
    [QUERY_KEYS.RPA, { filters }],
    () => RPAApi.getRPA({ filters }),
    {
      onSuccess,
      onError,
    },
  )
  const { mutate: createRPA, isLoading: createLoading } = useMutation(RPAApi.createRPA, {
    onSuccess: rpa => {
      notification.success({
        message: `RPA created succesfully with ID: ${rpa.id}`,
      })
      queryClient.refetchQueries(QUERY_KEYS.RPA)
    },
    onError,
  })
  return {
    rpa: data,
    isLoading,
    createRPA,
    createLoading,
  }
}
export { useRPA }
