import { useState, useContext } from 'react'
import { Table, Row, Col, Pagination, Modal, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { SelectValue } from 'antd/lib/select'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import { valueType } from 'antd/lib/statistic/utils'

import { usePagination, useTickets } from '@hooks'
import { Filters, Depot, Invoice, StatusTicketEnum, Ticket, TicketAssignment } from '@types'
import { AcceptIcon, DeclineIcon, FilterIcon } from '@icons'
import { TicketNotifContext, useFilters } from '@contexts'

import {
  ContainerTable,
  DeclineTicketModal,
  AcceptTicketModal,
  StyledSelect,
  TicketCard,
  AcceptButton,
  DeclineButton,
  MobileFilters,
  StyledLink,
  StyledCol,
} from './components'

const TplDashboard = () => {
  const { t } = useTranslation()
  const { xs, sm, md } = useBreakpoint()

  const [ticketId, setTicketId] = useState<number>()
  const [isFiltersMobileOpen, setIsTicketsMobileOpen] = useState(false)

  const { filters, setFilters } = useFilters()

  const ticketNotification = useContext(TicketNotifContext)

  const {
    tickets,
    isLoading,
    declineTicket,
    acceptTicket,
    isAcceptTicketModalOpen,
    setIsAcceptTicketModalOpen,
    isDeclineTicketModalOpen,
    setIsDeclineTicketModalOpen,
    acceptTicketLoading,
    declineTicketLoading,
  } = useTickets(filters)

  const pagination = usePagination({ filters, setFilters }, tickets?.total)

  const dateFormat = 'll'
  const dateHourFormat = 'MMM DD YYYY, h:mm a'

  const getStatusName = (name: string) => {
    const statusName =
      name === StatusTicketEnum.SUPPLIER_INVOICED ||
      name === StatusTicketEnum.TA_INVOICED ||
      name === StatusTicketEnum.MX_TRANSIT
        ? StatusTicketEnum.TRANSIT
        : name

    return statusName
  }

  const getStateByName = (names: string[]) => {
    return names.filter(name => name === StatusTicketEnum.TRANSIT).length
      ? [
          ...names,
          StatusTicketEnum.SUPPLIER_INVOICED,
          StatusTicketEnum.TA_INVOICED,
          StatusTicketEnum.MX_TRANSIT,
        ]
      : names
  }

  const actionButtons = (status: TicketAssignment, ticket: Ticket) => (
    <>
      {status.toUpperCase() === TicketAssignment.PENDING &&
        ticket.status.name.toUpperCase() === StatusTicketEnum.PENDING && (
          <>
            <AcceptButton
              onClick={() => {
                setTicketId(ticket.id)
                setIsAcceptTicketModalOpen(true)
                ticketNotification.setState(ticket.id)
              }}
            >
              <AcceptIcon />
            </AcceptButton>
          </>
        )}
      {status.toUpperCase() === TicketAssignment.CONFIRMED &&
        ticket.status.name.toUpperCase() === StatusTicketEnum.CONFIRMED && (
          <DeclineButton
            onClick={() => {
              setTicketId(ticket.id)
              setIsDeclineTicketModalOpen(true)
              ticketNotification.setState(ticket.id)
            }}
          >
            <DeclineIcon />
          </DeclineButton>
        )}
    </>
  )

  const seeComments = (comments: string) => {
    Modal.info({
      title: t('tpl_router.tpl_dashboard.comment_modal.title'),
      content: (
        <div>
          {comments ? (
            <p>{comments}</p>
          ) : (
            <p>
              <i>{t('tpl_router.tpl_dashboard.comment_modal.no_comments')}</i>
            </p>
          )}
        </div>
      ),
      okText: t('tpl_router.tpl_dashboard.comment_modal.close_button'),
    })
  }

  const seeAddress = (address: string) => {
    Modal.info({
      title: t('tpl_router.tpl_dashboard.address_modal.title'),
      content: <div>{address ? <p>{address}</p> : <p>-</p>}</div>,
      okText: t('tpl_router.tpl_dashboard.address_modal.close_button'),
    })
  }

  const columns = [
    {
      title: t('tpl_router.tpl_dashboard.columns.trip_id'),
      dataIndex: 'id',
      key: 'id',
      render: (id: number) => <>{id}</>,
    },
    {
      title: t('tpl_router.tpl_dashboard.columns.order_date'),
      dataIndex: 'createAt',
      key: 'id',
      render: (date: Date) => moment(date).format(dateFormat),
    },
    {
      title: t('tpl_router.tpl_dashboard.columns.load_date'),
      key: 'id',
      render: (ticket: Ticket) =>
        ticket.ticketAssignments[0].status.name !== TicketAssignment.DECLINED &&
        ticket.truckDocumentation
          ? moment(ticket.truckDocumentation.createAt).format(dateFormat)
          : '',
    },
    {
      title: t('tpl_router.tpl_dashboard.columns.invoice_number'),
      dataIndex: 'invoice',
      key: 'name',
      render: (invoice?: Invoice) => invoice && <>{invoice.number}</>,
    },
    {
      title: t('tpl_router.tpl_dashboard.columns.supplier'),
      dataIndex: 'supplier',
      key: 'id',
      render: ({ businessName }: { businessName: string }, ticket: Ticket) => (
        <div>
          {businessName}
          <br />
          {ticket.comments && (
            <StyledLink
              onClick={() => {
                seeComments(ticket.comments)
              }}
            >
              ({t('tpl_router.tpl_dashboard.columns.comments')})
            </StyledLink>
          )}
        </div>
      ),
    },
    {
      title: t('tpl_router.tpl_dashboard.columns.origin_yard'),
      dataIndex: 'origin',
      key: 'id',
      render: (origin: Depot) =>
        origin.city && (
          <StyledLink
            onClick={() => {
              seeAddress(origin.address)
            }}
          >
            {origin.city}
          </StyledLink>
        ),
    },
    {
      title: t('tpl_router.tpl_dashboard.columns.destination'),
      key: 'destination',
      render: (ticket: Ticket) => ticket.destination && <>{ticket.destination.name}</>,
    },
    {
      title: t('tpl_router.tpl_dashboard.columns.withdrawal_date'),
      dataIndex: 'withdrawalDate',
      key: 'withdrawalDate',
      render: (withdrawalDate: Date) =>
        withdrawalDate && <>{moment(withdrawalDate).format(dateHourFormat)}</>,
    },
    {
      title: t('tpl_router.tpl_dashboard.columns.status'),
      dataIndex: 'status',
      key: 'id',
      render: ({ name }: { name: string }) => <>{t(getStatusName(name))}</>,
    },
    {
      title: t('tpl_router.tpl_dashboard.columns.actions'),
      render: (ticket: Ticket) => actionButtons(ticket.ticketAssignments[0].status.name, ticket),
    },
  ]

  const handleDeclineTicket = (payload: { reason: string }, reset: () => void) => {
    ticketNotification.setState(ticketId)
    declineTicket({ id: ticketId!, payload })
    reset()
  }

  const handleAcceptTicket = (withdrawalDate: { withdrawalDate: Date }) => {
    ticketNotification.setState(ticketId)
    acceptTicket({ id: ticketId!, withdrawalDate })
  }

  const filterOptions = [
    {
      label: `${t('tpl_router.filterOptions.pending')}`,
      value: StatusTicketEnum.PENDING,
    },
    {
      label: `${t('tpl_router.filterOptions.confirmed')}`,
      value: StatusTicketEnum.CONFIRMED,
    },
    {
      label: `${t('tpl_router.filterOptions.transit')}`,
      value: StatusTicketEnum.TRANSIT,
    },
    {
      label: `${t('tpl_router.filterOptions.delivered')}`,
      value: StatusTicketEnum.DELIVERED,
    },
  ]

  const orderByOptions = [
    {
      label: `${t('admin_router.filter_options.order_by.ascending')}`,
      value: 'ASC',
    },
    {
      label: `${t('admin_router.filter_options.order_by.descending')}`,
      value: 'DESC',
    },
  ]

  const handleStateChange = (value: SelectValue | CheckboxValueType[]) => {
    const state = getStateByName(value as string[])

    setFilters((prevState: Filters) => ({
      ...prevState,
      page: 1,
      statusName: [...state],
      ticketId: undefined,
      invoiceNumber: undefined,
      taInvoiceNumber: undefined,
    }))
  }

  const handleOrderByFilterChange = (value: SelectValue) => {
    setFilters((prevState: Filters) => ({
      ...prevState,
      page: 1,
      orderBy: value as string,
      ticketId: undefined,
      invoiceNumber: undefined,
      taInvoiceNumber: undefined,
    }))
  }

  const handleTicketIdFilterChange = (ticketNumber: string) => {
    setFilters((prevState: Filters) => ({
      ...prevState,
      page: 1,
      ticketId: ticketNumber,
    }))
  }

  const handleTAInvoiceNumberFilterChange = (taInvoiceNumber: valueType) => {
    setFilters((prevState: Filters) => ({
      ...prevState,
      page: 1,
      taInvoiceNumber: taInvoiceNumber as string,
    }))
  }

  const getStatusNameToSelect = (statusFilter: string[]) => {
    if (statusFilter.includes(StatusTicketEnum.TRANSIT)) {
      const filtered = statusFilter.filter(
        state =>
          state === StatusTicketEnum.PENDING ||
          state === StatusTicketEnum.CONFIRMED ||
          state === StatusTicketEnum.DELIVERED,
      )
      return [...filtered, StatusTicketEnum.TRANSIT]
    }
    return statusFilter
  }

  const handleCleanFilters = () => {
    setFilters((prevState: Filters) => ({
      ...prevState,
      page: 1,
      carrierId: [],
      carrierMode: [],
      statusName: [],
      supplierId: [],
      destinationId: [],
      borderCrossingId: [],
      orderBy: undefined,
      ticketId: undefined,
      invoiceNumber: undefined,
      taInvoiceNumber: undefined,
    }))
  }

  return (
    <ContainerTable>
      {xs || (sm && !md) ? (
        <>
          <Row>
            <Col span={2} offset={22}>
              <FilterIcon onClick={() => setIsTicketsMobileOpen(true)} />
            </Col>
          </Row>
          <TicketCard
            tickets={tickets?.tickets}
            setTicketId={setTicketId}
            setIsDeclineTicketModalOpen={setIsDeclineTicketModalOpen}
            setIsAcceptTicketModalOpen={setIsAcceptTicketModalOpen}
          />

          <Row>
            <Col flex={6} />
            <Col flex="auto">
              <Pagination
                current={pagination.current}
                total={pagination?.total}
                onChange={pagination.onChange}
                responsive
              />
            </Col>
            <Col flex={6} />
          </Row>

          <MobileFilters
            open={isFiltersMobileOpen}
            onClose={() => setIsTicketsMobileOpen(false)}
            onChange={handleStateChange}
            onChangeTicketIdFilter={e => handleTicketIdFilterChange(e.target.value)}
            onChangeTaInvoiceNumberFilter={handleTAInvoiceNumberFilterChange}
            filters={filters}
          />
        </>
      ) : (
        <>
          <Row gutter={[20, 20]}>
            <Col span={6}>
              <StyledSelect
                mode="multiple"
                allowClear
                placeholder={t('tpl_router.filterOptions.title')}
                options={filterOptions}
                onChange={handleStateChange}
                value={filters?.statusName && getStatusNameToSelect(filters.statusName)}
                maxTagCount="responsive"
              />
            </Col>
            <Col span={6}>
              <StyledSelect
                allowClear
                placeholder={t('admin_router.filter_options.order_by.title')}
                options={orderByOptions}
                onChange={handleOrderByFilterChange}
                value={filters?.orderBy}
              />
            </Col>
            <StyledCol>
              <Button onClick={handleCleanFilters}>Clean filters</Button>
            </StyledCol>
          </Row>
          <Table
            size="middle"
            columns={columns}
            dataSource={tickets?.tickets}
            pagination={pagination}
            loading={isLoading}
            rowKey="id"
            scroll={{ y: 'calc(100vh - 290px)' }}
          />
        </>
      )}

      <AcceptTicketModal
        open={isAcceptTicketModalOpen}
        onClose={() => setIsAcceptTicketModalOpen(false)}
        onOk={handleAcceptTicket}
        loading={acceptTicketLoading}
      />
      <DeclineTicketModal
        open={isDeclineTicketModalOpen}
        onClose={() => setIsDeclineTicketModalOpen(false)}
        onOk={handleDeclineTicket}
        loading={declineTicketLoading}
      />
    </ContainerTable>
  )
}

export { TplDashboard }
