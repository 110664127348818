import { Col, DatePicker, Input, Button, Spin, InputNumber, Tag, Divider, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { SelectValue } from 'antd/lib/select'
import moment from 'moment'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { ErrorMessage } from '@hookform/error-message'
import { InfoCircleOutlined } from '@ant-design/icons'

import { ArrayUtils, StringUtils } from '@utils'
import { usePurchases, useCancelTicket } from '@hooks'
import { StatusTicketEnum } from '@types'
import { useUser } from '@contexts'

import { UpdateForm, validationSchema } from './validations'
import {
  Wrapper,
  UploadButton,
  Container,
  StyledRow,
  StyledSelect,
  StyledText,
  UploadSingleFile,
  StyledErrorMessage,
} from './components'
import { useUpdateRailcarDocumentation, useRailcarDocumentation, useDestinations } from './hooks'

interface Props {
  ticketId: number
  supplierId: number
  ticketRPA: number | '-' | undefined
}

const RailcarDocumentation: React.FC<Props> = ({ ticketId, supplierId, ticketRPA }) => {
  const history = useHistory()
  const { state: user } = useUser()

  const { t } = useTranslation()
  const { updateRailcarDocumentation, isLoading } = useUpdateRailcarDocumentation()
  const { railcarDocumentation } = useRailcarDocumentation(ticketId)
  const { destinationsOptions, isLoading: isDestinationsLoading } = useDestinations()
  const {
    materialOptions,
    materialsLoading,
    getPurchasesByMaterial,
    documentNumberIdsLoading,
    documentIdsOptions,
  } = usePurchases(supplierId)

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<UpdateForm>({
    resolver: yupResolver(validationSchema),
  })

  useEffect(() => {
    reset({
      destinationId: railcarDocumentation?.ticket.destination?.id,
      invoiceDate: railcarDocumentation?.invoiceDate,
      invoiceNumber: railcarDocumentation?.invoiceNumber,
      waybill: railcarDocumentation?.waybill,
      carEquipment: railcarDocumentation?.carEquipment,
      invoiceAmount: railcarDocumentation?.invoiceAmount,
      grossWeight: railcarDocumentation?.grossWeight,
      tareWeight: railcarDocumentation?.tareWeight,
      materialId: railcarDocumentation?.materialId,
      materialDescription: railcarDocumentation?.materialDescription,
      purchaseOrderId: railcarDocumentation?.purchaseOrderId,
    })
  }, [railcarDocumentation, reset])

  const handleForm = async (form: UpdateForm) => {
    if (railcarDocumentation) {
      updateRailcarDocumentation({
        ...form,
        photos: form.photos ?? [],
        railcarDocumentationId: railcarDocumentation.id,
      })
    }
  }

  const handleMaterialChange = (value: SelectValue) => {
    setValue('purchaseOrderId', '')
    const material = materialOptions!.find(
      (option: { value: SelectValue }) => option.value === value,
    )
    setValue('materialDescription', material!.label)
    getPurchasesByMaterial({
      materialId: value as string,
      supplierId,
    })
  }

  const { cancelTicket } = useCancelTicket(ticketId)

  useEffect(() => {
    if (railcarDocumentation?.materialId)
      getPurchasesByMaterial({
        materialId: railcarDocumentation?.materialId,
        supplierId,
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [railcarDocumentation])

  const handleCancelTicket = (id: number) => {
    cancelTicket(id)

    history.push('/dashboard/admin')
  }

  return (
    <Wrapper>
      <Spin spinning={isLoading}>
        <Container>
          <StyledRow>
            <Col span={8}>
              <StyledText>
                {t(
                  'ta_invoice.add_truck_documentation.title_and_divider.supplier_documentation.title',
                )}
              </StyledText>
            </Col>
            <Col>
              {ticketRPA !== undefined ? (
                <Tag color="green">{`RPA #${ticketRPA}`}</Tag>
              ) : (
                <Tag color="default">RPA</Tag>
              )}
            </Col>
            <Divider />
          </StyledRow>
          <StyledRow>
            <Col sm={{ span: 16 }} md={{ span: 8 }}>
              <StyledText>
                {t('ta_invoice.add_truck_documentation.title_and_divider.new_truck.depot')}:
              </StyledText>
            </Col>
            <Col span={8}>
              <Controller
                control={control}
                name="destinationId"
                defaultValue={railcarDocumentation && railcarDocumentation.ticket.destination.id}
                render={({ field }) => (
                  <StyledSelect
                    {...field}
                    placeholder={t('globals.placeholder_select')}
                    options={destinationsOptions}
                    loading={isDestinationsLoading}
                    value={field.value}
                  />
                )}
              />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col span={8}>
              <StyledText>
                {t(
                  'ta_invoice.add_truck_documentation.title_and_divider.supplier_documentation.invoice_date',
                )}
                :
              </StyledText>
            </Col>
            <Col span={8}>
              <Controller
                name="invoiceDate"
                control={control}
                render={({ field: { value } }) => (
                  <DatePicker
                    value={moment(value)}
                    onChange={date => {
                      if (date) {
                        setValue('invoiceDate', date.toDate())
                      }
                    }}
                  />
                )}
              />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col span={8}>
              <StyledText>
                {t(
                  'ta_invoice.add_truck_documentation.title_and_divider.supplier_documentation.invoice_number',
                )}
                :
              </StyledText>
            </Col>
            <Col span={8}>
              <Controller
                control={control}
                name="invoiceNumber"
                defaultValue={railcarDocumentation && railcarDocumentation.invoiceNumber}
                render={({ field }) => <Input {...field} value={field.value ?? undefined} />}
              />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col span={8}>
              <StyledText>
                {t(
                  'ta_invoice.add_truck_documentation.title_and_divider.supplier_documentation.invoice_amount',
                )}
                :
              </StyledText>
            </Col>
            <Col span={8}>
              <Controller
                control={control}
                name="invoiceAmount"
                defaultValue={railcarDocumentation && railcarDocumentation.invoiceAmount}
                render={({ field }) => (
                  <InputNumber
                    style={{ width: '150px' }}
                    {...field}
                    value={field.value ?? undefined}
                    step="0.01"
                    precision={2}
                    formatter={value => {
                      if (!value) return '$ '
                      const decimals =
                        value?.toString().search(/\./) >= 0
                          ? value?.toString().split('.').reverse()[0].slice(0, 2)
                          : null
                      const number = `$ ${Math.floor(value)}`.replace(/\B(?=(\d{3})+(?!.))/g, ',')
                      if (decimals) return `${number}.${decimals}`
                      return `${number}`
                    }}
                  />
                )}
              />
              <Col>
                <StyledErrorMessage>
                  <ErrorMessage errors={errors} name="invoiceAmount" />
                </StyledErrorMessage>
              </Col>
            </Col>
          </StyledRow>
          <StyledRow>
            <Col span={8}>
              <StyledText>
                {t(
                  'ta_invoice.add_truck_documentation.title_and_divider.supplier_documentation.gross_weight',
                )}
                :
              </StyledText>
            </Col>
            <Col span={8}>
              <Controller
                control={control}
                name="grossWeight"
                defaultValue={railcarDocumentation && railcarDocumentation.grossWeight}
                render={({ field }) => (
                  <InputNumber
                    style={{ width: '150px' }}
                    {...field}
                    value={field.value ?? undefined}
                    step="0.01"
                    precision={2}
                    formatter={value => {
                      if (!value) return ' '
                      const decimals =
                        value?.toString().search(/\./) >= 0
                          ? value?.toString().split('.').reverse()[0].slice(0, 2)
                          : null
                      const number = ` ${Math.floor(value)}`.replace(/\B(?=(\d{3})+(?!.))/g, ',')
                      if (decimals) return `${number}.${decimals}`
                      return `${number}`
                    }}
                  />
                )}
              />
              <Col>
                <StyledErrorMessage>
                  <ErrorMessage errors={errors} name="grossWeight" />
                </StyledErrorMessage>
              </Col>
            </Col>
          </StyledRow>
          <StyledRow>
            <Col span={8}>
              <StyledText>
                {t(
                  'ta_invoice.add_truck_documentation.title_and_divider.supplier_documentation.tare_weight',
                )}
                :
              </StyledText>
            </Col>
            <Col span={8}>
              <Controller
                control={control}
                name="tareWeight"
                defaultValue={railcarDocumentation && railcarDocumentation.tareWeight}
                render={({ field }) => (
                  <InputNumber
                    style={{ width: '150px' }}
                    {...field}
                    value={field.value ?? undefined}
                    step="0.01"
                    precision={2}
                    formatter={value => {
                      if (!value) return ' '
                      const decimals =
                        value?.toString().search(/\./) >= 0
                          ? value?.toString().split('.').reverse()[0].slice(0, 2)
                          : null
                      const number = ` ${Math.floor(value)}`.replace(/\B(?=(\d{3})+(?!.))/g, ',')
                      if (decimals) return `${number}.${decimals}`
                      return `${number}`
                    }}
                  />
                )}
              />
              <Col>
                <StyledErrorMessage>
                  <ErrorMessage errors={errors} name="tareWeight" />
                </StyledErrorMessage>
              </Col>
            </Col>
          </StyledRow>
          <StyledRow>
            <Col span={8}>
              <StyledText>
                {t(
                  'ta_invoice.add_truck_documentation.title_and_divider.supplier_documentation.material',
                )}
                :
              </StyledText>
            </Col>
            <Col sm={{ span: 8 }} md={{ span: 16 }}>
              <Controller
                control={control}
                name="materialId"
                defaultValue={railcarDocumentation && railcarDocumentation.materialId}
                render={({ field }) => (
                  <StyledSelect
                    placeholder={t('globals.placeholder_select_material')}
                    options={materialOptions}
                    loading={materialsLoading}
                    onSelect={handleMaterialChange}
                    {...field}
                    value={field.value}
                  />
                )}
              />
              <Controller
                control={control}
                name="materialDescription"
                defaultValue={railcarDocumentation?.materialDescription}
                render={({ field }) => <Input {...field} value={field.value ?? undefined} hidden />}
              />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col span={8}>
              <StyledText>
                {t(
                  'ta_invoice.add_truck_documentation.title_and_divider.supplier_documentation.PO',
                )}
                :
              </StyledText>
            </Col>
            <Col sm={{ span: 8 }} md={{ span: 16 }}>
              <Controller
                control={control}
                name="purchaseOrderId"
                defaultValue={railcarDocumentation && railcarDocumentation.purchaseOrderId}
                render={({ field }) => (
                  <StyledSelect
                    placeholder={t('globals.placeholder_select_po')}
                    options={documentIdsOptions}
                    loading={documentNumberIdsLoading}
                    {...field}
                    value={field.value}
                  />
                )}
              />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col span={8}>
              <StyledText>
                {t(
                  'ta_invoice.add_truck_documentation.title_and_divider.supplier_documentation.waybill',
                )}{' '}
                :
                <Tooltip title="Waybill is the tracking number provided by the rail, must be a numerical value, eg: 764679.">
                  <InfoCircleOutlined style={{ marginLeft: '2px' }} />
                </Tooltip>
              </StyledText>
            </Col>
            <Col span={8}>
              <Controller
                control={control}
                name="waybill"
                defaultValue={railcarDocumentation && railcarDocumentation.waybill}
                render={({ field }) => <Input {...field} value={field.value ?? undefined} />}
              />
              <Col>
                <StyledErrorMessage>
                  <ErrorMessage errors={errors} name="waybill" />
                </StyledErrorMessage>
              </Col>
            </Col>
          </StyledRow>
          <StyledRow>
            <Col span={8}>
              <StyledText>
                {t(
                  'ta_invoice.add_truck_documentation.title_and_divider.supplier_documentation.car_equipment',
                )}{' '}
                :
                <Tooltip title="Car ID comprises the Car Initial (alphabetical value) and Number without symbols or spaces, eg: BNSF518280">
                  <InfoCircleOutlined style={{ marginLeft: '2px' }} />
                </Tooltip>
              </StyledText>
            </Col>
            <Col span={8}>
              <Controller
                control={control}
                name="carEquipment"
                defaultValue={railcarDocumentation && railcarDocumentation?.carEquipment}
                render={({ field }) => <Input {...field} value={field.value ?? undefined} />}
              />
              <Col>
                <StyledErrorMessage>
                  <ErrorMessage errors={errors} name="carEquipment" />
                </StyledErrorMessage>
              </Col>
            </Col>
          </StyledRow>
          <StyledRow>
            <Col span={8}>
              <StyledText>
                {t(
                  'ta_invoice.add_truck_documentation.title_and_divider.supplier_documentation.invoice_sell',
                )}
                :
              </StyledText>
            </Col>
            <Col span={16}>
              <Controller
                control={control}
                name="salesInvoice"
                render={({ field }) => (
                  <UploadSingleFile
                    {...field}
                    fileData={ArrayUtils.reverse(railcarDocumentation?.salesInvoice)?.map(
                      document => ({
                        key: document,
                        name: StringUtils.formatFilename(ticketId, document, 'InvoiceSell', 'pdf'),
                      }),
                    )}
                  />
                )}
              />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col span={8}>
              <StyledText>
                {t(
                  'ta_invoice.add_truck_documentation.title_and_divider.supplier_documentation.weight_certificate',
                )}
                :
              </StyledText>
            </Col>
            <Col span={16}>
              <Controller
                control={control}
                name="weightCertificate"
                render={({ field }) => (
                  <UploadSingleFile
                    {...field}
                    fileData={ArrayUtils.reverse(railcarDocumentation?.weightCertificate)?.map(
                      document => ({
                        key: document,
                        name: StringUtils.formatFilename(
                          ticketId,
                          document,
                          'WeightCertificate',
                          'pdf',
                        ),
                      }),
                    )}
                  />
                )}
              />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col span={8}>
              <StyledText>
                {t(
                  'ta_invoice.add_truck_documentation.title_and_divider.supplier_documentation.BL',
                )}
                :
              </StyledText>
            </Col>
            <Col span={16}>
              <Controller
                control={control}
                name="BL"
                render={({ field }) => (
                  <UploadSingleFile
                    {...field}
                    fileData={ArrayUtils.reverse(railcarDocumentation?.BL)?.map(document => ({
                      key: document,
                      name: StringUtils.formatFilename(ticketId, document, 'BL', 'pdf'),
                    }))}
                  />
                )}
              />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col span={8}>
              <StyledText>No Radioact. Cert.</StyledText>
            </Col>
            <Col span={16}>
              <Controller
                control={control}
                name="radioactiveCertificate"
                render={({ field }) => (
                  <UploadSingleFile
                    {...field}
                    fileData={ArrayUtils.reverse(railcarDocumentation?.radioactiveCertificate)?.map(
                      document => ({
                        key: document,
                        name: StringUtils.formatFilename(
                          ticketId,
                          document,
                          'RadioactiveCertificate',
                          'pdf',
                        ),
                      }),
                    )}
                  />
                )}
              />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col span={8}>
              <StyledText>
                {t(
                  'ta_invoice.add_truck_documentation.title_and_divider.supplier_documentation.photos',
                )}
                :
              </StyledText>
            </Col>
            <Col span={16}>
              <Controller
                control={control}
                name="photos"
                render={({ field }) => (
                  <UploadButton
                    accept="image/*"
                    {...field}
                    fileData={ArrayUtils.reverse(railcarDocumentation?.photos)?.map(photo => ({
                      key: `${photo}`,
                      name: StringUtils.formatFilename(ticketId, photo, 'photo', 'png'),
                    }))}
                  />
                )}
              />
            </Col>
          </StyledRow>

          {user?.roles[0].name === 'ADMIN' && (
            <>
              <Button
                style={{ marginRight: '40px' }}
                onClick={handleSubmit(handleForm)}
                disabled={railcarDocumentation?.ticket.status.name === StatusTicketEnum.CANCELED}
              >
                {t(
                  'ta_invoice.add_truck_documentation.title_and_divider.supplier_documentation.button',
                )}
              </Button>
              <Button
                style={{ marginRight: '40px' }}
                onClick={() => handleCancelTicket(railcarDocumentation!.ticket.id)}
                disabled={
                  railcarDocumentation?.ticket.status.name !== StatusTicketEnum.SUPPLIER_INVOICED
                }
              >
                {t('ta_invoice.add_truck_documentation.buttons.cancel')}
              </Button>
            </>
          )}
        </Container>
      </Spin>
    </Wrapper>
  )
}

export { RailcarDocumentation }
