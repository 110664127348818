import { api } from '@api'
import { StatusTicketEnum } from '@types'

interface TicketsCountResponse {
  count: {
    [key in StatusTicketEnum]: {
      total: number
      oldestCount: number
    }
  }
}

const getCount = async () => {
  const response = await api.get<TicketsCountResponse>('/tickets//count/states')

  return response.data.count
}

export const TicketsCountApi = { getCount }
