import { api } from '@api'

import { Form } from '../../validations'

interface CreateTruckDocumentationResponse {
  truckDocumentation: string
}

const createTruckDocumentation = async (payload: Form) => {
  const {
    salesInvoice,
    weightCertificate,
    BL,
    radioactiveCertificate,
    photos,
    ...restOfProfessionalValues
  } = payload

  const truckDocumentationPayload = new FormData()

  const files = [salesInvoice, weightCertificate, BL, radioactiveCertificate, ...photos]

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < files.length; i++) {
    truckDocumentationPayload.append('files[]', files[i])
  }

  truckDocumentationPayload.append('truckDocumentation', JSON.stringify(restOfProfessionalValues))
  const response = await api.post<CreateTruckDocumentationResponse>(
    '/trucks-documentation',
    truckDocumentationPayload,
  )

  return response.data
}

export const TruckDocumentationApi = { createTruckDocumentation }
