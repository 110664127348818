import { Col, Modal, Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useContext } from 'react'

import { StatusTicketEnum, Ticket, TicketAssignment } from '@types'
import { AcceptIcon, DeclineIcon } from '@icons'
import { TicketNotifContext } from '@contexts'

import {
  StyledText,
  StyledRow,
  StyledTitle,
  StyledDivider,
  StyledCard,
  AcceptButton,
  DeclineButton,
  StyledLink,
} from './components'

type Props = {
  tickets?: Ticket[]
  setTicketId: (id: number) => void
  setIsDeclineTicketModalOpen: (value: boolean) => void
  setIsAcceptTicketModalOpen: (value: boolean) => void
}

const TicketCard: React.FC<Props> = ({
  tickets,
  setTicketId,
  setIsDeclineTicketModalOpen,
  setIsAcceptTicketModalOpen,
}) => {
  const { t } = useTranslation()
  const dateFormat = 'MMM DD, hh:mm a'

  const ticketNotification = useContext(TicketNotifContext)

  const seeComments = (comments: string) => {
    Modal.info({
      title: t('tpl_router.tpl_dashboard.comment_modal.title'),
      content: (
        <div>
          {comments ? (
            <p>{comments}</p>
          ) : (
            <p>
              <i>{t('tpl_router.tpl_dashboard.comment_modal.no_comments')}</i>
            </p>
          )}
        </div>
      ),
      okText: t('tpl_router.tpl_dashboard.comment_modal.close_button'),
    })
  }

  const getStatusName = (name: string) => {
    const statusName =
      name === StatusTicketEnum.SUPPLIER_INVOICED ||
      name === StatusTicketEnum.TA_INVOICED ||
      name === StatusTicketEnum.MX_TRANSIT
        ? StatusTicketEnum.TRANSIT
        : name

    return statusName
  }

  return (
    <>
      {tickets?.map(ticket => {
        return (
          <StyledCard>
            <StyledRow justify="space-between">
              <Col span={4}>
                <StyledText>#{ticket.id}</StyledText>
              </Col>

              <Col span={4} offset={12}>
                <Tag color="volcano">{getStatusName(ticket.status.name)}</Tag>
              </Col>
            </StyledRow>
            <StyledRow justify="space-between">
              <Col span={12}>
                <StyledTitle>{t('tpl_router.tpl_dashboard.columns.order_date')}</StyledTitle>
              </Col>
              <Col span={8}>{moment(ticket.createAt).format(dateFormat)}</Col>
            </StyledRow>
            <StyledDivider />
            <StyledRow justify="space-between">
              <Col span={12}>
                <StyledTitle>{t('tpl_router.tpl_dashboard.columns.load_date')}</StyledTitle>
              </Col>
              <Col span={8}>
                {ticket.truckDocumentation?.createAt
                  ? moment(ticket.truckDocumentation?.createAt).format(dateFormat)
                  : '-'}
              </Col>
            </StyledRow>
            <StyledDivider />

            <StyledRow justify="space-between">
              <Col span={12}>
                <StyledTitle>{t('tpl_router.tpl_dashboard.columns.invoice_number')}</StyledTitle>
              </Col>
              <Col span={8}>
                {ticket.truckDocumentation ? ticket.truckDocumentation.invoiceNumber : '-'}
              </Col>
            </StyledRow>
            <StyledDivider />

            <StyledRow justify="space-between">
              <Col span={4}>
                <StyledTitle>{t('tpl_router.tpl_dashboard.columns.supplier')}</StyledTitle>
              </Col>
              <Col
                span={8}
                onClick={() => {
                  seeComments(ticket.comments)
                }}
              >
                <StyledLink>(Comments)</StyledLink>
              </Col>
              <Col span={8}>{ticket.supplier.businessName}</Col>
            </StyledRow>
            <StyledDivider />

            <StyledRow justify="space-between">
              <Col span={12}>
                <StyledTitle>{t('tpl_router.tpl_dashboard.columns.destination')}</StyledTitle>
              </Col>
              <Col span={8}>{ticket.destination ? ticket.destination.name : '-'}</Col>
            </StyledRow>
            <StyledDivider />
            {ticket.ticketAssignments[0].status.name === TicketAssignment.PENDING && (
              <StyledRow justify="space-around">
                <Col span={10}>
                  <AcceptButton
                    onClick={() => {
                      setTicketId(ticket.id)
                      setIsAcceptTicketModalOpen(true)
                    }}
                  >
                    <AcceptIcon />
                  </AcceptButton>
                </Col>
              </StyledRow>
            )}
            {ticket.ticketAssignments[0].status.name === TicketAssignment.CONFIRMED &&
              ticket.status.name === StatusTicketEnum.CONFIRMED && (
                <StyledRow justify="space-around">
                  <Col span={10}>
                    <DeclineButton
                      onClick={() => {
                        setTicketId(ticket.id)
                        setIsDeclineTicketModalOpen(true)
                        ticketNotification.setState(ticket.id)
                      }}
                    >
                      <DeclineIcon />
                    </DeclineButton>
                  </Col>
                </StyledRow>
              )}
          </StyledCard>
        )
      })}
    </>
  )
}

export { TicketCard }
