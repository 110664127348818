import { Col, DatePicker, Button, Spin, Input } from 'antd'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useEffect } from 'react'

import { TitleAndDivider } from '@components'
import { StatusTicketEnum } from '@types'
import { StringUtils } from '@utils'
import { useInvoices } from '@hooks'
import { useUser } from '@contexts'

import { Form, validationSchema } from './validations'
import { Wrapper, Container, StyledRow, StyledText, UploadSingleFile } from './components'
import { useCreatePayment } from './hooks'

interface Props {
  ticketStatus: string
  ticketId: number
}

const PaymentVoucher: React.FC<Props> = ({ ticketStatus, ticketId }) => {
  const { t } = useTranslation()
  const { createPayment, isLoading } = useCreatePayment(ticketId)
  const { control, handleSubmit, reset } = useForm<Form>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      paymentDate: undefined,
    },
  })

  const { invoices } = useInvoices(ticketId)
  const { state: user } = useUser()

  const handleForm = (form: Form) => createPayment({ ...form, id: invoices[0].id })

  useEffect(() => {
    reset({
      paymentDate: invoices[0]?.paymentDate ? new Date(invoices[0].paymentDate) : undefined,
      paymentNumber: invoices[0]?.paymentNumber,
    })
  }, [invoices, reset])

  if (
    ticketStatus !== StatusTicketEnum.TA_INVOICED &&
    ticketStatus !== StatusTicketEnum.MX_TRANSIT &&
    ticketStatus !== StatusTicketEnum.DELIVERED
  ) {
    return (
      <Wrapper>
        <Spin spinning={isLoading}>
          <Container>
            <TitleAndDivider title={t('ta_invoice.payment_voucher.title')} />
            <StyledRow>
              <Col span={16}>
                <StyledText>{t('ta_invoice.payment_voucher.default_text')}</StyledText>
              </Col>
            </StyledRow>
          </Container>
        </Spin>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Spin spinning={isLoading}>
        <Container>
          <TitleAndDivider title={t('ta_invoice.payment_voucher.title')} />
          <StyledRow>
            <Col span={8}>
              <StyledText>{t('ta_invoice.payment_voucher.payment_date')}:</StyledText>
            </Col>
            <Col span={8}>
              <Controller
                name="paymentDate"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    allowClear={false}
                    value={field.value ? moment(field.value).utc() : undefined}
                    picker="date"
                    format="ll"
                    disabled={
                      ticketStatus !== StatusTicketEnum.TA_INVOICED &&
                      ticketStatus !== StatusTicketEnum.MX_TRANSIT &&
                      ticketStatus !== StatusTicketEnum.DELIVERED
                    }
                  />
                )}
              />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col span={8}>
              <StyledText>{t('ta_invoice.payment_voucher.number')}:</StyledText>
            </Col>
            <Col span={8}>
              <Controller
                control={control}
                name="paymentNumber"
                render={({ field }) => <Input {...field} />}
              />
            </Col>
          </StyledRow>

          <StyledRow>
            <Col span={8}>
              <StyledText>{t('ta_invoice.payment_voucher.voucher')}:</StyledText>
            </Col>
            <Col span={16}>
              <Controller
                control={control}
                name="paymentFile"
                render={({ field }) => (
                  <UploadSingleFile
                    {...field}
                    defaultFiles={invoices
                      ?.sort(
                        (invoice1, invoice2) =>
                          parseInt(invoice2.paymentNumber!, 10) -
                          parseInt(invoice1.paymentNumber!, 10),
                      )
                      .map(invoice => {
                        if (invoice.paymentFile) {
                          return {
                            key: invoice.paymentFile,
                            name: StringUtils.formatFilename(
                              ticketId,
                              invoice.paymentFile,
                              'paymentW/T',
                              'pdf',
                            ),
                          }
                        }
                        return null
                      })}
                  />
                )}
              />
            </Col>
          </StyledRow>
          {user?.roles[0].name === 'ADMIN' && (
            <Button onClick={handleSubmit(handleForm)}>
              {t('ta_invoice.payment_voucher.upload')}
            </Button>
          )}
        </Container>
      </Spin>
    </Wrapper>
  )
}

export { PaymentVoucher }
