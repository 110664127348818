import { Button } from 'antd'

import { useDownloadExcel } from '@hooks'

type Props = {
  text: string
  rpaId: number
  name: string
}

const DownloadExcel: React.FC<Props> = ({ text, rpaId, name }) => {
  const { downloadFile, isLoading } = useDownloadExcel(name)

  return (
    <>
      <Button onClick={() => downloadFile({ rpaId })} loading={isLoading}>
        {text}
      </Button>
    </>
  )
}

export { DownloadExcel }
