import { notification } from 'antd'
import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'

import { QUERY_KEYS } from '@api'

import { TicketsApi } from './api'
import { useHandleNewTicketModal } from '../../contexts'

const onError = () => {
  notification.error({
    message: 'Error al crear un ticket',
  })
}

const useCreateTicket = () => {
  const { t } = useTranslation()
  const { setIsTicketModalOpen } = useHandleNewTicketModal()

  const queryClient = useQueryClient()

  const onCreateSuccess = () => {
    setIsTicketModalOpen(false)
    queryClient.invalidateQueries(QUERY_KEYS.TICKETS)
  }

  const { mutate, isLoading } = useMutation(TicketsApi.createTicket, {
    onSuccess: data => {
      notification.success({
        message: [
          t('suppliers_router.suppliers_dashboard.new_ticket_notification'),
          `${data.tickets.map(ticket => ` ${ticket.id}`)}`,
        ],
      })
      onCreateSuccess()
    },
    onError,
  })

  return {
    createTicket: mutate,
    isLoading,
  }
}

export { useCreateTicket }
