import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

import { HomeIcon } from '@icons'
import { useFilters } from '@contexts'
import { Filters } from '@types'

import { StyledMenu, StyledMenuItem } from './components'

const CarrierMenu: () => JSX.Element = () => {
  const { pathname } = useLocation()
  const { url } = useRouteMatch()
  const { push } = useHistory()
  const { xs, sm, md } = useBreakpoint()
  const { setFilters } = useFilters()

  const handleCleanFilters = () => {
    setFilters((prevState: Filters) => ({
      ...prevState,
      page: 1,
      carrierId: [],
      carrierMode: [],
      statusName: [],
      supplierId: [],
      destinationId: [],
      borderCrossingId: [],
      orderBy: undefined,
      ticketId: undefined,
      invoiceNumber: undefined,
      taInvoiceNumber: undefined,
    }))
  }

  const onHomeClick = () => {
    push('/dashboard/carrier')
    handleCleanFilters()
  }

  return (
    <StyledMenu selectedKeys={[pathname]}>
      <StyledMenuItem key={`${url}/carrier-dashboard`} onClick={() => onHomeClick()}>
        <HomeIcon width={!((xs || sm) && md) ? 35 : 18} height={!((xs || sm) && md) ? 30 : 15} />
        <span>{xs || sm ? 'Home' : 'Mis Solicitudes'}</span>
      </StyledMenuItem>
    </StyledMenu>
  )
}

export { CarrierMenu }
