import styled from 'styled-components'
import { Row, Select, Typography } from 'antd'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const Container = styled.div`
  flex: 1;
  margin-top: 5%;
  max-width: 95%;
`

const StyledRow = styled(Row)`
  margin: 15px 0 15px;
`

const StyledErrorMessage = styled.div`
  color: #ff3333;
`

const StyledText = styled(Typography)`
  font-weight: bold;
`

const StyledSelect = styled(Select)`
  width: 10rem;

  @media ${props => props.theme.sm} {
    width: 18rem;
  }
`

export { Wrapper, Container, StyledRow, StyledText, StyledSelect, StyledErrorMessage }
export { UploadButton } from './UploadButton'
export { UploadSingleFile } from './UploadSingleFile'
