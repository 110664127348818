import React from 'react'
import { Spin } from 'antd'
import styled from 'styled-components'

import { useTokenLogin } from '@hooks'

const SpinContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LoginWithToken: React.FC = ({ children }) => {
  const { isLoginLoading } = useTokenLogin()

  return isLoginLoading ? (
    <SpinContainer>
      <Spin />
    </SpinContainer>
  ) : (
    <>{children}</>
  )
}

export { LoginWithToken }
