import { api } from '@api'
import { Invoice } from '@types'

import { Form } from '../../validations'

interface InvoiceResponse {
  invoice: Invoice
}

const updateInvoice = async (payload: Form & { invoiceId: number }) => {
  const { invoice, invoiceId, ...restOfInvoiceValues } = payload

  const invoicePayload = new FormData()

  if (invoice) invoicePayload.append('invoiceFile', invoice)
  invoicePayload.append('invoice', JSON.stringify(restOfInvoiceValues))

  const response = await api.put<InvoiceResponse>(`/invoices/${invoiceId}`, invoicePayload)

  return response.data
}

export const InvoiceApi = { updateInvoice }
