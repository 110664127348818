import React from 'react'
import { Modal, InputNumber, Input, Row, Col, Select, Spin } from 'antd'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import { useUser } from '@contexts'

import { Form } from '../../../validations'
import { StyledRow } from './components'

type Props = {
  open: boolean
  onClose: () => void
  onOk: (form: Form, reset: () => void) => void
  title: string
  isLoading: boolean
}

const { TextArea } = Input

const NewTicketModal: React.FC<Props> = ({ open, onClose, title, onOk, isLoading }) => {
  const { state } = useUser()
  const { t } = useTranslation()
  const validationSchema = yup.object().shape({
    count: yup
      .number()
      .min(1, t('suppliers_router.new_ticket_modal.validations.min'))
      .required(t('globals.obligatory_field')),
    originDepotId: yup.number().required(t('globals.obligatory_field')),
    comments: yup.string().nullable().optional(),
  })
  const { control, handleSubmit, reset } = useForm<Form>({
    resolver: yupResolver(validationSchema),
  })

  const handleNewTicket = (form: Form) => {
    onOk(form, reset)
  }
  return (
    <Modal
      visible={open}
      onCancel={onClose}
      title={title}
      closable={false}
      okText={t('suppliers_router.new_ticket_modal.okText')}
      cancelText={t('suppliers_router.new_ticket_modal.cancelText')}
      onOk={handleSubmit(handleNewTicket)}
    >
      <Spin spinning={isLoading}>
        <Row>
          <Col span={8}>{t('suppliers_router.new_ticket_modal.quantity')}</Col>
          <Col span={12}>
            <Controller
              name="count"
              control={control}
              defaultValue={1}
              render={({ field }) => <InputNumber defaultValue={1} min={1} max={10} {...field} />}
            />
          </Col>
        </Row>
        <StyledRow>
          <Col span={8}>{t('suppliers_router.new_ticket_modal.home_depot')}</Col>
          <Col span={12}>
            <Controller
              name="originDepotId"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  placeholder={t('globals.placeholder_select')}
                  options={state?.userDetail.supplier?.depots.map(depot => {
                    return {
                      label: depot.address,
                      value: depot.id,
                    }
                  })}
                />
              )}
            />
          </Col>
        </StyledRow>
        <Row>
          <Col span={8}>{t('suppliers_router.new_ticket_modal.comments')}</Col>
          <Col span={12}>
            <Controller
              name="comments"
              control={control}
              render={({ field }) => <TextArea {...field} />}
            />
          </Col>
        </Row>
      </Spin>
    </Modal>
  )
}

export { NewTicketModal }
