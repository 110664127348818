import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { config } from '@config'

import { LoginTitle, LoginContentContainer, LoginButton } from '../components'

const LoginSSO = () => {
  const { t } = useTranslation()

  return (
    <LoginContentContainer>
      <LoginTitle color="gray">{t('login.login')}</LoginTitle>
      <LoginButton type="primary" size="large">
        <Link to={{ pathname: `${config.baseURL}/auth/login-exiros` }} target="_parent">
          {t('login.iam_supplier')}
        </Link>
      </LoginButton>
      <LoginButton type="primary" size="large">
        <Link to={{ pathname: `${config.baseURL}/auth/login-adsf` }} target="_parent">
          {t('login.iam_employee')}
        </Link>
      </LoginButton>
    </LoginContentContainer>
  )
}

export { LoginSSO }
