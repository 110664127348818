import React from 'react'
// import * as Sentry from '@sentry/react'
// import { Integrations } from '@sentry/tracing'
import ReactDOM from 'react-dom'

import { App } from './features/App'
import 'antd/dist/antd.less'
import './i18n'
import reportWebVitals from './reportWebVitals'
import registerServiceWorker from './registerServiceWorker'

// Sentry.init({
//   dsn: 'https://99422958c4b14302b86b6bd8cfb29987@o982355.ingest.sentry.io/5989026',
//   integrations: [new Integrations.BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// })

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

registerServiceWorker()
