import styled from 'styled-components'
import { Row, Typography } from 'antd'

const Wrapper = styled.div`
  padding: 2%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Container = styled.div`
  flex: 1;
  margin-top: 5%;
  max-width: 95%;
`

const ContainerRow = styled(Row)`
  margin-top: 50px;
`

const StyledRow = styled(Row)`
  margin: 15px 0 15px;
`

const StyledText = styled(Typography)`
  font-weight: bold;
`

export { Wrapper, ContainerRow, StyledRow, StyledText, Container }
