import React from 'react'
import { Row, Col, Modal, Input, Spin } from 'antd'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

// import { Depot, Supplier } from '@types'
import { useBorderCrossings, useSuppliers } from '@hooks'

import { StyledSelect } from './components'

type Props = {
  open: boolean
  options: { label: string; value: number }[] | undefined
  loading: boolean
  onClose: () => void
  onOk: (
    supplierId: number,
    city: string,
    address: string,
    location: { type: string; coordinates: [number, number] },
    description: string,
    borderCrossingId: number,
    resetForm: () => void,
  ) => void
}

type addDepotPayload = {
  supplierId: number
  city: string
  address: string
  latitude: number
  longitude: number
  description: string
  borderCrossingId: number
}

const validationSchema = yup.object().shape({
  supplierId: yup.number().integer().required(),
  city: yup.string().min(3).max(100).required(),
  address: yup.string().min(3).max(100).required(),
  latitude: yup.string().min(2).max(200).required(),
  longitude: yup.string().min(2).max(200).required(),
  description: yup.string().max(200).optional(),
  borderCrossingId: yup.number().integer().required(),
})

const AddSupplierDepotModal: React.FC<Props> = ({ open, onClose, onOk, loading }) => {
  const { t } = useTranslation()

  const { control, handleSubmit, reset } = useForm<addDepotPayload>({
    resolver: yupResolver(validationSchema),
  })
  const { allBordersOptions, allBordersLoading } = useBorderCrossings()
  const { suppliersOptions, isLoading: isSuppliersLoading } = useSuppliers()
  const handleAddDepot = (payload: addDepotPayload) => {
    const { supplierId, city, address, latitude, longitude, description, borderCrossingId } =
      payload
    const location = {
      type: 'Point',
      coordinates: [Number(longitude), Number(latitude)] as [number, number],
    }
    onOk(supplierId, city, address, location, description, borderCrossingId, reset)
  }

  return (
    <Modal
      visible={open}
      onCancel={onClose}
      title={`${t('tpl_router.tpl_dashboard.change_supplier_modal.title')}: `}
      closable={false}
      okText={t('tpl_router.tpl_dashboard.change_supplier_modal.button_ok')}
      cancelText={t('tpl_router.tpl_dashboard.change_supplier_modal.button_cancel')}
      onOk={handleSubmit(handleAddDepot)}
      afterClose={reset}
      destroyOnClose
    >
      <Spin spinning={loading}>
        <Row>
          <Col span={8}>
            <p>{t('admin_router.admin_dashboard.depotsabm.columns.supplier')}:</p>
          </Col>
          <Col span={16}>
            <Controller
              control={control}
              name="supplierId"
              render={({ field }) => (
                <StyledSelect
                  {...field}
                  placeholder={t('globals.placeholder_select')}
                  options={suppliersOptions}
                  loading={isSuppliersLoading}
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <p>{t('admin_router.admin_dashboard.depotsabm.columns.city')}: </p>
          </Col>
          <Col span={16}>
            <Controller
              control={control}
              name="city"
              render={({ field }) => <Input {...field} className="" disabled={false} />}
            />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <p>{t('admin_router.admin_dashboard.depotsabm.columns.address')}:</p>
          </Col>
          <Col span={16}>
            <Controller
              control={control}
              name="address"
              render={({ field }) => <Input {...field} className="" disabled={false} />}
            />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <p>{t('admin_router.admin_dashboard.depotsabm.columns.latitude')}:</p>
          </Col>
          <Col span={16}>
            <Controller
              control={control}
              name="latitude"
              render={({ field }) => <Input {...field} className="" disabled={false} />}
            />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <p>{t('admin_router.admin_dashboard.depotsabm.columns.longitude')}:</p>
          </Col>
          <Col span={16}>
            <Controller
              control={control}
              name="longitude"
              render={({ field }) => <Input {...field} className="" disabled={false} />}
            />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <p>{t('admin_router.admin_dashboard.depotsabm.columns.description')}:</p>
          </Col>
          <Col span={16}>
            <Controller
              control={control}
              name="description"
              render={({ field }) => <Input {...field} className="" disabled={false} />}
            />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <p>{t('admin_router.admin_dashboard.depotsabm.columns.border_crossing')}:</p>
          </Col>
          <Col span={16}>
            <Controller
              control={control}
              name="borderCrossingId"
              render={({ field }) => (
                <StyledSelect
                  {...field}
                  placeholder={t('globals.placeholder_select')}
                  options={allBordersOptions}
                  loading={allBordersLoading}
                  value={field.value}
                />
              )}
            />
          </Col>
        </Row>
      </Spin>
    </Modal>
  )
}

export { AddSupplierDepotModal }
