import { api } from '@api'

import { UpdateForm } from '../../validations'

interface UpdateRailcarDocumentationResponse {
  railcarDocumentation: string
}

const updateRailcarDocumentation = async (payload: UpdateForm) => {
  const {
    salesInvoice,
    weightCertificate,
    BL,
    radioactiveCertificate,
    photos,
    railcarDocumentationId,
    ...restOfRailcarDocumentationValues
  } = payload

  const railcarDocumentationPayload = new FormData()

  if (salesInvoice) {
    railcarDocumentationPayload.append('salesInvoice', salesInvoice as Blob)
  }
  if (weightCertificate) {
    railcarDocumentationPayload.append('weightCertificate', weightCertificate as Blob)
  }
  if (BL) {
    railcarDocumentationPayload.append('BL', BL as Blob)
  }
  if (radioactiveCertificate) {
    railcarDocumentationPayload.append('radioactiveCertificate', radioactiveCertificate as Blob)
  }

  photos?.forEach(photo => {
    railcarDocumentationPayload.append('photos', photo as Blob)
  })

  railcarDocumentationPayload.append(
    'railcarDocumentation',
    JSON.stringify(restOfRailcarDocumentationValues),
  )

  const response = await api.put<UpdateRailcarDocumentationResponse>(
    `/railcar-documentation/${railcarDocumentationId}`,
    railcarDocumentationPayload,
  )

  return response.data
}

export const RailcarDocumentationApi = { updateRailcarDocumentation }
