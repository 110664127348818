import { notification } from 'antd'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'

import { QUERY_KEYS } from '@api'
import { Ticket } from '@types'

import { InvoiceApi } from './api'

const useCreateInvoice = (ticket: Ticket) => {
  const { t } = useTranslation()

  const { push } = useHistory()

  const queryClient = useQueryClient()

  const onSuccess = () => {
    queryClient.invalidateQueries(QUERY_KEYS.INVOICES_BY_TICKET_ID)
    notification.success({
      message: `Trip ID ${ticket.id}: ${t('ta_invoice.new_invoice.success')}`,
    })
    push('/dashboard/admin')
  }

  const onError = (error: AxiosError) => {
    const message = error.response?.data.error.message.includes(
      'duplicate key value violates unique constraint',
    )
      ? t('ta_invoice.add_truck_documentation.errors.duplicate_invoice_number')
      : t('globals.failed_to_create_invoice')
    notification.error({ message })
  }

  const { mutate, isLoading, data } = useMutation(InvoiceApi.createInvoice, {
    onSuccess,
    onError,
  })

  return {
    createInvoice: mutate,
    isLoading,
    createdInvoice: data,
  }
}

export { useCreateInvoice }
