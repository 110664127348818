import styled from 'styled-components'
import { Button, Col, Select } from 'antd'

const ContainerTable = styled.div`
  padding: 2%;
`
const StyledSelect = styled(Select)`
  width: 100%;
`

const AcceptButton = styled(Button)`
  width: 100%;
  background-color: ${({ theme }) => theme.green};
  &:hover {
    background-color: ${({ theme }) => theme.green};
  }
  &:focus {
    background-color: ${({ theme }) => theme.green};
  }
`

const DeclineButton = styled(Button)`
  width: 100%;
  background-color: ${({ theme }) => theme.red};
  &:hover {
    background-color: ${({ theme }) => theme.red};
  }
  &:focus {
    background-color: ${({ theme }) => theme.red};
  }
`

const StyledLink = styled.a`
  height: fit-content;
  line-height: 1;
`

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledErrorMessage = styled.div`
  color: #ff3333;
`

export {
  ContainerTable,
  StyledSelect,
  AcceptButton,
  DeclineButton,
  StyledLink,
  StyledCol,
  StyledErrorMessage,
}
// eslint-disable-next-line import/no-cycle
export { AcceptTicketModal } from './AcceptTicketModal'
export { DeclineTicketModal } from './DeclineTicketModal'
export { TicketCard } from './TicketCard'
export { MobileFilters } from './MobileFilters'
