import { useTranslation } from 'react-i18next'
import { Table, Button, Col, notification } from 'antd'
import { SelectValue } from 'antd/lib/select'
import { useEffect, useState } from 'react'

import { useCarrierFilters, useUser } from '@contexts'
import { EditIcon } from '@icons'
import { useCarriers } from '@hooks'
import { Carrier, CarrierFilters, CarrierModeEnum } from '@types'

import { ChangeCarrierOptionsModal, ContainerTable, StyledRow, StyledSelect } from './components'
import { useCarriersPagination } from './hooks'

const Categories = () => {
  const { t } = useTranslation()
  const { filters, setFilters } = useCarrierFilters()
  const { state: user } = useUser()
  const {
    filteredCarriers,
    isFilteredLoading,
    truckOptions,
    isCarrierOptionsModalOpen,
    setIsCarrierOptionsModalOpen,
    changeOptions,
    changeOptionsLoading,
  } = useCarriers(filters)
  const pagination = useCarriersPagination({ filters, setFilters }, filteredCarriers?.total)

  const [carrierTo, setCarrierTo] = useState<Carrier>()

  useEffect(() => {
    setFilters(prevState => ({ ...prevState, mode: CarrierModeEnum.TRUCK }))
  }, [setFilters])

  const columns = [
    {
      title: t('admin_router.admin_dashboard.categories.columns.carrier'),
      dataIndex: 'businessName',
      key: 'businessName',
    },
    {
      title: t('admin_router.admin_dashboard.categories.columns.category'),
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: t('admin_router.admin_dashboard.categories.columns.algorithm'),
      dataIndex: 'enabledForAlgorithm',
      key: 'enabledForAlgorithm',
      render: (enabledForAlgorithm: boolean) => <>{enabledForAlgorithm ? 'ON' : 'OFF'}</>,
    },
    {
      title: t('admin_router.admin_dashboard.categories.columns.actions'),
      render: (carrier: Carrier) => (
        <Button
          disabled={user?.roles[0].name === 'ADMINRO'}
          onClick={() => {
            setCarrierTo(carrier)
            setIsCarrierOptionsModalOpen(true)
          }}
        >
          <EditIcon width={21} height={21} />
        </Button>
      ),
    },
  ]

  const handleCarrierChange = (value: SelectValue) => {
    setFilters((prevState: CarrierFilters) => ({
      ...prevState,
      page: 1,
      carrierId: [...(value as number[])],
    }))
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFilterOptions = (input: string, option: any) => {
    if (option.label) return option.label.toString().toLowerCase().includes(input.toLowerCase())
    return true
  }

  const handleChangeCarrier = (
    carrierId: number,
    category: number,
    enabledForAlgorithm: boolean,
    resetForm: () => void,
  ) => {
    if (category === carrierTo?.category && enabledForAlgorithm === carrierTo.enabledForAlgorithm) {
      setIsCarrierOptionsModalOpen(false)
      resetForm()
      return notification.info({ message: 'No changes were made to carrier' })
    }
    const payload = {
      carrierId,
      ...(category ? { category } : {}),
      enabledForAlgorithm,
    }
    changeOptions(payload)
    return resetForm()
  }

  return (
    <ContainerTable>
      <StyledRow gutter={[20, 20]}>
        <Col span={6}>
          <StyledSelect
            mode="multiple"
            allowClear
            placeholder={t('admin_router.filter_options.by_carrier.title')}
            options={truckOptions}
            filterOption={handleFilterOptions}
            onChange={handleCarrierChange}
            loading={isFilteredLoading}
            value={filters?.carrierId}
          />
        </Col>
      </StyledRow>
      <Table
        columns={columns}
        dataSource={filteredCarriers?.carriers}
        loading={isFilteredLoading}
        pagination={pagination}
        rowKey="id"
      />
      <ChangeCarrierOptionsModal
        open={isCarrierOptionsModalOpen}
        options={truckOptions}
        loading={isFilteredLoading || changeOptionsLoading}
        assignedCarrier={carrierTo}
        onClose={() => setIsCarrierOptionsModalOpen(false)}
        onOk={handleChangeCarrier}
      />
    </ContainerTable>
  )
}

export { Categories }
