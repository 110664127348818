import { api } from '@api'

interface CancelTicketsResponse {
  tickets: string
}

const cancelTicket = async (id: number) => {
  const response = await api.patch<CancelTicketsResponse>(`/tickets/cancel/${id}`)

  return response.data
}

export const TicketsApi = { cancelTicket }
