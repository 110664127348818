import styled from 'styled-components'
import { Row, Select } from 'antd'

const ContainerTable = styled.div`
  padding: 2%;
`
const StyledSelect = styled(Select)`
  width: 100%;
`

const StyledRow = styled(Row)`
  margin: 15px 0 15px;
`

export { ContainerTable, StyledRow, StyledSelect }
export { ChangeCarrierOptionsModal } from './ChangeCarrierOptionsModal'
