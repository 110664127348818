import { api } from '@api'
import { Invoice, RPA, RPAFilters } from '@types'

interface RPAResponse {
  rpa: RPA[]
  total: number
}

interface GetRPAResponse {
  rpa: RPAResponse
  invoices: {
    invoices: Invoice
    count: number
  }
}

interface GetOneRPAResponse {
  rpa: RPA
}

type Params = {
  filters?: RPAFilters
}

type newRPA = Omit<RPA, 'id'>

const getRPAById = async (id: number) => {
  const response = await api.get<GetRPAResponse>(`/rpa/${id}`)
  return response.data.rpa
}

const getRPA = async (params: Params) => {
  const { filters } = params
  const response = await api.get<GetRPAResponse>('/rpa', {
    params: {
      ...filters,
    },
  })
  return response.data
}

const createRPA = async (payload: newRPA) => {
  const response = await api.post<GetOneRPAResponse>('/rpa', { rpa: payload })

  return response.data.rpa
}

export const RPAApi = { getRPAById, getRPA, createRPA }
