export const QUERY_KEYS = {
  USER: 'user',
  TICKET: 'ticket',
  TICKETS: 'tickets',
  CARRIERS: 'carriers',
  SUPPLIERS: 'suppliers',
  DEPOTS: 'depots',
  CONTRACTS: 'contracts',
  DESTINATIONS: 'destinations',
  TRUCK_DOCUMENTATION: 'truckDocumentation',
  RAILCAR_DOCUMENTATION: 'railcarDocumentation',
  INVOICES_BY_TICKET_ID: 'invoicesByTicketId',
  INVOICES_BY_DATE: 'invoicesByDate',
  TICKETS_COUNT: 'ticketsCount',
  CONFIGURATION_PROPERTIES: 'configurationProperties',
  CARRIER_TICKETS_COUNT: 'carrierTicketsCount',
  ALLBORDERS: 'allBorderCrossings',
  USERBORDERS: 'userBorderCrossings',
  PURCHASES: 'purchaseOrders',
  RPA: 'RPA',
}
