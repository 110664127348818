import * as yup from 'yup'

export const validationSchema = yup.object().shape({
  ticketId: yup.number().optional(),
  destinationId: yup.number().optional(),
  invoiceDate: yup.date().optional(),
  invoiceNumber: yup.string().optional(),
  invoiceAmount: yup
    .number()
    .moreThan(0, 'Tare Weight must be a positive number')
    .required('Invoice amount is a required field'),
  grossWeight: yup
    .number()
    .required('Gross weight is a required field')
    .moreThan(yup.ref('tareWeight'), 'Gross Weight must be greater than Tare Weight'),
  tareWeight: yup
    .number()
    .required('Tare weight is a required field')
    .moreThan(0, 'Tare Weight must be a positive number'),
  materialId: yup.string().optional(),
  materialDescription: yup.string().nullable().optional(),
  purchaseOrderId: yup.string().optional(),
  salesInvoice: yup.mixed().optional(),
  weightCertificate: yup.mixed().optional(),
  BL: yup.mixed().optional(),
  radioactiveCertificate: yup.mixed().optional(),
  photos: yup.mixed().optional(),
})
export interface UpdateForm {
  destinationId: number
  invoiceDate: Date
  invoiceNumber: string
  invoiceAmount: number
  grossWeight: number
  tareWeight: number
  materialId: string
  materialDescription: string
  purchaseOrderId: string
  salesInvoice?: Blob
  weightCertificate?: Blob
  BL?: Blob
  radioactiveCertificate?: Blob
  photos: Blob[]
  truckDocumentationId: number
}
