import styled from 'styled-components'
import { Menu } from 'antd'

export const StyledMenu = styled(Menu)`
  height: 100%;
  @media ${props => props.theme.xs} {
    display: flex;
  }
`
export const StyledMenuItem = styled(Menu.Item)`
  & span {
    margin-left: 5px;
  }
  @media ${props => props.theme.xs} {
    & .ant-menu-title-content {
      line-height: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      flex-grow: 1;
      & span {
        margin-left: 0px;
      }
    }
  }
  @media (max-width: 768px) {
    & .ant-menu-title-content {
      line-height: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      flex-grow: 1;
    }
  }
`
