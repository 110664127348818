import { notification } from 'antd'
import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'

import { QUERY_KEYS } from '@api'

import { TicketsApi } from './api'
import { DeleteTicketNotificationContext, useHandleDeleteTicketModal } from '../../contexts'

const useDeleteTicket = () => {
  const { setIsDeleteTicketModalOpen } = useHandleDeleteTicketModal()
  const { t } = useTranslation()
  const { ticketDeleteId } = useContext(DeleteTicketNotificationContext)

  const onError = () => {
    notification.error({
      message: t('suppliers_router.suppliers_dashboard.use_delete_tickets.error'),
    })
  }

  const onSuccess = () => {
    notification.success({
      message: [
        t('suppliers_router.suppliers_dashboard.use_delete_tickets.success'),
        ` ID ${ticketDeleteId}`,
      ],
    })
  }

  const queryClient = useQueryClient()

  const onDeleteSuccess = () => {
    setIsDeleteTicketModalOpen(false)
    onSuccess()
    queryClient.invalidateQueries(QUERY_KEYS.TICKETS)
  }

  const { mutate, isLoading } = useMutation(TicketsApi.deleteTicket, {
    onSuccess: onDeleteSuccess,
    onError,
  })

  return {
    deleteTicket: mutate,
    isLoading,
  }
}

export { useDeleteTicket }
