import React, { useMemo } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'

import { useUser } from '@contexts'
import { RoleEnum } from '@types'

type PrivateRouteProps = RouteProps & {
  allowedRoles: RoleEnum[]
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  allowedRoles,
  ...routeProps
}: PrivateRouteProps) => {
  const { state: user } = useUser()

  const isRoleAllowed = useMemo(() => {
    const userRoles = user?.roles.map(role => role.name)

    if (!userRoles) return false

    return allowedRoles.some(role => userRoles.includes(role))
  }, [user, allowedRoles])

  return user && isRoleAllowed ? (
    <Route {...routeProps} />
  ) : (
    <Redirect to={{ pathname: '/login' }} />
  )
}

export { PrivateRoute }
