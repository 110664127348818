import styled from 'styled-components'
import { Button, Col, Select } from 'antd'

const Container = styled.div`
  padding: 2%;
`
interface ButtonProps {
  backgroundColor?: string
}
const StyledButton = styled(Button)<ButtonProps>`
  width: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor && backgroundColor};
  color: white;

  & p {
    width: 100%;
  }

  & > span > svg {
    color: white;
  }
`
const StyledDiv = styled.div`
  max-height: calc(100vh - 280px);
  overflow-y: scroll;
`

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledSelect = styled(Select)`
  width: 100%;
`

export { NewTicketModal } from './NewTicketModal'
export { DeleteTicketModal } from './DeleteTicketModal'
export { MobileFilters } from './MobileFilters'
export { TicketCard } from './TicketCard'
export { Container, StyledButton, StyledSelect, StyledCol, StyledDiv }
