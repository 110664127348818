import * as yup from 'yup'

export const validationSchema = yup.object().shape({
  allocation: yup.number().integer().min(0).optional(),
  days: yup.number().integer().min(0).optional(),
})

export interface Form {
  allocation: number
  ticketDays: number
}
