import styled from 'styled-components'
import { Col, Select, Row, Spin } from 'antd'

const Container = styled.div`
  padding: 2%;
`

const StyledSelect = styled(Select)`
  width: 100%;
`
const StyledRow = styled(Row)`
  margin: 0.5rem 0;
`

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledSpin = styled(Spin)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 4rem auto;
`

export { Container, StyledSelect, StyledCol, StyledSpin, StyledRow }
