import styled from 'styled-components'
import { Select } from 'antd'

const Container = styled.div`
  padding: 2%;
`

const StyledSelect = styled(Select)`
  width: 100%;
`

export { Container, StyledSelect }
