import { api } from '@api'
import { Destination, Supplier, TruckDocumentation } from '@types'

interface GetTruckDocumentationResponse {
  truckDocumentation: TruckDocumentation & {
    ticket: {
      id: number
      comments: string
      createAt: string
      withdrawalDate: string
      destination: Destination & {
        id: number
      }
      supplier: Supplier
    }
  }
}

const getTruckDocumentation = async (ticketId: number) => {
  const response = await api.get<GetTruckDocumentationResponse>(
    `/trucks-documentation/${ticketId}/ticket`,
  )

  return response.data.truckDocumentation
}

export const TruckDocumentationApi = { getTruckDocumentation }
