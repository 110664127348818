import { api } from '@api'
import { PurchaseOrder } from '@types'

interface GetPurchasesResponse {
  purchaseOrders: PurchaseOrder[]
}

type PurchasesPayload = { materialId?: string; supplierId?: number }

const getPurchases = async ({ supplierId }: PurchasesPayload) => {
  const response = await api.get<GetPurchasesResponse>(
    '/trucks-documentation/purchase-orders/material',
    {
      params: {
        supplierId,
      },
    },
  )

  return response.data.purchaseOrders
}

const getPurchasesByMaterial = async ({ materialId, supplierId }: PurchasesPayload) => {
  const response = await api.get<GetPurchasesResponse>(
    `/trucks-documentation/purchase-orders/material/${materialId}`,
    {
      params: {
        supplierId,
      },
    },
  )

  return response.data.purchaseOrders
}

export const PurchasesApi = { getPurchases, getPurchasesByMaterial }
