/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useContext, Dispatch, SetStateAction } from 'react'

import { CarrierFilters } from '@types'

type ContextState = {
  filters?: CarrierFilters
  setFilters: Dispatch<SetStateAction<CarrierFilters>>
}

const initialState = {
  filters: { page: 1, limit: 5 },
  setFilters: () => {},
}

const CarrierFiltersContext = React.createContext<ContextState>(initialState)

export function CarrierFiltersProvider({ children }: { children: React.ReactNode }) {
  const [filters, setFilters] = useState<CarrierFilters>({ page: 1, limit: 10 })

  return (
    <CarrierFiltersContext.Provider value={{ filters, setFilters }}>
      {children}
    </CarrierFiltersContext.Provider>
  )
}

export function useCarrierFilters(): ContextState {
  const context = useContext(CarrierFiltersContext)
  if (!context) throw Error('This hook can only be used under the CarrierFiltersProvider component')
  return context
}
