import styled from 'styled-components'
import { Button, Col, Row, Select } from 'antd'

const ContainerTable = styled.div`
  padding: 2%;
`
const StyledSelect = styled(Select)`
  width: 100%;
`

const StyledRow = styled(Row)`
  margin: 0.5rem 0;
`

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ChangeCarrierButton = styled(Button)`
  width: fit-content;
  background-color: transparent;
  border: transparent;
`

const StyledButton = styled(Button)`
  padding: 0;
  margin: 0;
`
const StyledSubButton = styled(Button)`
  padding: 0;
  margin: 0;
  white-space: break-spaces;
`

export {
  ContainerTable,
  StyledSelect,
  ChangeCarrierButton,
  StyledRow,
  StyledCol,
  StyledButton,
  StyledSubButton,
}
export { ChangeCarrierModal } from './ChangeCarrierModal'
export { CountIndicator } from './CountIndicator'
