import * as yup from 'yup'

export const validationSchema = yup.object().shape({
  paymentNumber: yup.string().required('Campo obligatorio'),
  paymentDate: yup.date().required('Campo obligatorio'),
  paymentFile: yup.mixed().required('Campo obligatorio'),
})

export interface Form {
  paymentNumber: string
  paymentDate: Date
  paymentFile: Blob
}
