import * as yup from 'yup'

export const validationSchema = yup.object().shape({
  ticketId: yup.number().optional(),
  destinationId: yup.number().optional(),
  invoiceDate: yup.date().optional(),
  invoiceNumber: yup.string().optional(),
  invoiceAmount: yup
    .number()
    .moreThan(0, 'Invoice Amount must be a positive number')
    .required('Invoice amount is a required field'),
  tareWeight: yup
    .number()
    .required('Tare weight is a required field')
    .moreThan(0, 'Tare Weight must be a positive number'),
  grossWeight: yup
    .number()
    .required('Gross Weight is a required field')
    .moreThan(yup.ref('tareWeight'), 'Gross Weight must be greater than Tare Weight'),
  materialId: yup.string().required('Campo obligatorio'),
  materialDescription: yup.string().nullable().optional(),
  purchaseOrderId: yup.string().nullable().optional(),
  waybill: yup
    .string()
    .required('WayBill is a required field')
    .matches(/^[0-9]{5,}$/, 'WayBill must contain only numeric digits (min 5)')
    .notOneOf([yup.ref('invoiceNumber'), null], 'WayBill and Invoice Number must be different'),
  carEquipment: yup
    .string()
    .required('Car/Equipment is a required field')
    .matches(/^[A-Za-z0-9]{6,}$/, 'Car/Equipment must contain alphanumeric digits (min 6)')
    .test(
      'not-equal-to-waybill', // Test name
      'Car/Equipment and WayBill must be different', // Error message
      function notEqualToWaybill(value) {
        const { waybill } = this.parent
        return waybill !== value
      },
    )
    .test(
      'not-equal-to-invoiceNumber', // Test name
      'Car/Equipment and Invoice Number must be different', // Error message
      function notEqualToInvoiceNumber(value) {
        const { invoiceNumber } = this.parent
        return invoiceNumber !== value
      },
    ),
  salesInvoice: yup.mixed().optional(),
  weightCertificate: yup.mixed().optional(),
  BL: yup.mixed().optional(),
  radioactiveCertificate: yup.mixed().optional(),
  photos: yup.mixed().optional(),
  comments: yup.string().optional(),
})
export interface UpdateForm {
  destinationId: number
  invoiceDate: Date
  invoiceNumber: string
  invoiceAmount: number
  grossWeight: number
  tareWeight: number
  materialId: string
  materialDescription: string
  purchaseOrderId: string
  waybill?: string
  carEquipment?: string
  salesInvoice?: Blob
  weightCertificate?: Blob
  BL?: Blob
  radioactiveCertificate?: Blob
  photos: Blob[]
  railcarDocumentationId: number
}
