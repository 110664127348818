import { notification } from 'antd'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'

import { RailcarDocumentationApi } from './api'

const useCreateRailcarDocumentation = () => {
  const history = useHistory()

  const { t } = useTranslation()

  const onError = (error: AxiosError) => {
    const message = error.response?.data.error.message.includes(
      'duplicate key value violates unique constraint',
    )
      ? t('ta_invoice.add_truck_documentation.errors.duplicate_invoice_number')
      : t('Failed to creat new railcar')
    notification.error({ message })
  }

  const { mutate, isLoading } = useMutation(RailcarDocumentationApi.createRailcarDocumentation, {
    onSuccess: data => {
      notification.success({
        message: `Trip ID ${data.railcarDocumentation.id}: ${t(
          'ta_invoice.add_truck_documentation.title_and_divider.new_railcar.success',
        )}`,
      })
      history.push('/dashboard/suppliers')
    },
    onError,
  })

  return {
    createRailcarDocumentation: mutate,
    isLoading,
  }
}

export { useCreateRailcarDocumentation }
