import * as yup from 'yup'

export const validationSchema = yup.object().shape({
  description: yup.string().required('Campo obligatorio'),
  price: yup.number().required('Campo obligatorio'),
  from: yup.date().required('Campo obligatorio'),
  to: yup.date().required('Campo obligatorio'),
  carrierId: yup.number().required('Campo obligatorio'),
})

export interface Form {
  description: string
  price: number
  from: Date
  to: Date
  carrierId: number
}

export const validationUpdateSchema = yup.object().shape({
  description: yup.string().required('Campo obligatorio'),
  price: yup.number().integer().required('Campo obligatorio'),
  to: yup.date().required('Campo obligatorio'),
  from: yup.date().required('Campo obligatorio'),
  id: yup.number().required('Campo obligatorio'),
  contractId: yup.number().required('Campo obligatorio'),
})

export interface UpdateForm {
  contractId: number
  description: string
  price: number
  from: Date
  to: Date
  id: number
}
