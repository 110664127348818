import { Route, Switch, useRouteMatch } from 'react-router-dom'

import { LogoExiros } from '@icons'

import { ChangePassword } from './ChangePassword'
import { Login, LoginError } from './Login'
import { RecoverPassword } from './RecoverPassword'
import { LoginContainer, StyledCol, StyledRow } from './components'
import { LoginTraditional } from './LoginTraditional'
import { LoginSSO } from './LoginSSO'

const LoginRouter = () => {
  const { url } = useRouteMatch()

  return (
    <StyledRow align="middle" justify="center">
      <LoginContainer>
        <StyledCol span={24}>
          <LogoExiros width={150} />
        </StyledCol>
        <Switch>
          <Route exact path={url} component={Login} />
          <Route path={`${url}/tradicional`} component={LoginTraditional} />
          <Route path={`${url}/sso`} component={LoginSSO} />
          <Route path={`${url}/recover`} component={RecoverPassword} />
          <Route path={`${url}/change-password`} component={ChangePassword} />
          <Route path={`${url}/login-error`} component={LoginError} />
        </Switch>
      </LoginContainer>
    </StyledRow>
  )
}

export { LoginRouter }
