const size = {
  xs: '575px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1600px',
}
const primaryColor = '#4157A1'
const inputBg = '#FFFFFF'
const lightGray = '#F0F2F5'
const gray = '#D9D9D9'
const darkGray = '#CBCBCB'
const lightBlack = '#5F5E5E'
const secondaryDarkGray = '#939393'
const green = '#3DD78D'
const red = '#E1394D'

const theme = {
  xs: `(max-width: ${size.xs})`,
  sm: `(min-width: ${size.sm})`,
  md: `(min-width: ${size.md})`,
  lg: `(min-width: ${size.lg})`,
  xl: `(min-width: ${size.xl})`,
  xxl: `(min-width: ${size.xxl})`,
  '@primary-color': primaryColor,
  '@input-bg': inputBg,
  '@border-radius-base': '2px',
  '@table-header-bg': 'white',
  '@divider-color': 'black',
  primaryColor,
  inputBg,
  lightGray,
  gray,
  darkGray,
  lightBlack,
  secondaryDarkGray,
  red,
  green,
}

module.exports = { theme }
