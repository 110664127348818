import moment from 'moment'

const FILE_NAME_DATE_FORMAT = 'll'
const FILE_FORMAT_ORIGIN = 'YYYYMMDD'

const formatFilename = (ticketId: number, file: string, type: string, extension: string) => {
  const date = file.split('-')

  return `tripID-${ticketId}-${type}-${moment(date[0], FILE_FORMAT_ORIGIN)
    .utc()
    .local()
    .format(FILE_NAME_DATE_FORMAT)}.${extension}`
}

export const StringUtils = {
  formatFilename,
}
