import { api } from '@api'
import { Ticket } from '@types'

interface GetTicketResponse {
  ticket: Ticket
}

const getTicketById = async (id?: number) => {
  const response = await api.get<GetTicketResponse>(`/tickets/${id}`)

  return response.data.ticket
}

export const TicketsApi = { getTicketById }
