import { api } from '@api'
import { BorderCrossing } from '@types'

interface GetBorderCrossingsResponse {
  borderCrossings: BorderCrossing[]
}

const getUserBorderCrossings = async () => {
  const response = await api.get<GetBorderCrossingsResponse>('/border-crossings')

  return response.data.borderCrossings
}

const getAllBorderCrossings = async () => {
  const response = await api.get<GetBorderCrossingsResponse>('/border-crossings/all')

  return response.data.borderCrossings
}

export const BorderCrossingsApi = { getUserBorderCrossings, getAllBorderCrossings }
