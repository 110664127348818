import { useState } from 'react'
import { Form, Row, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Recaptcha from 'react-google-recaptcha'

import { config } from '@config'

import { LoginButton, StyledInput } from '../components'
import { useRecoverPass } from '../hooks'

const RecoverPassword = () => {
  const { t } = useTranslation()

  // Ignora captcha en dev para poder testear la funcionalidad de recupero de contraseña
  const [isCaptchaVisible] = useState(!config.captchaDisabled)
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(isCaptchaVisible)

  const { recoverPass, isLoading } = useRecoverPass({
    onSuccess: () => {
      form.resetFields()
    },
  })

  const [form] = Form.useForm()

  const onChangeCaptcha = () => {
    setIsSubmitDisabled(false)
  }

  const onExpiredCaptcha = () => {
    setIsSubmitDisabled(true)
  }

  return (
    <Spin spinning={isLoading}>
      <Form
        form={form}
        name="recover_pass"
        initialValues={{ remember: true }}
        onFinish={recoverPass}
      >
        <Form.Item
          name="username"
          rules={[{ required: true, message: 'Please enter your username' }]}
        >
          <StyledInput placeholder={t('login.username')} />
        </Form.Item>
        {isCaptchaVisible && (
          <Form.Item>
            <Row justify="center">
              <Recaptcha
                sitekey={config.recaptchaKey!}
                onChange={onChangeCaptcha}
                onExpired={onExpiredCaptcha}
              />
            </Row>
          </Form.Item>
        )}
        <Form.Item>
          <Row justify="center">
            <LoginButton type="primary" htmlType="submit" size="large" disabled={isSubmitDisabled}>
              {t('login.recover_password')}
            </LoginButton>
          </Row>
        </Form.Item>
        <Form.Item>
          <Row justify="center">
            <Link to="/login">{t('login.login')}</Link>
          </Row>
        </Form.Item>
      </Form>
    </Spin>
  )
}

export { RecoverPassword }
