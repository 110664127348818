import React, { useContext } from 'react'
import { Row, Col, Modal, Input, Spin } from 'antd'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

import { TicketNotifContext } from '@contexts'

type Props = {
  open: boolean
  onClose: () => void
  onOk: (reason: { reason: string }, reset: () => void) => void
  loading: boolean
}

const { TextArea } = Input

const validationSchema = yup.object().shape({
  reason: yup.string().required('Campo obligatorio'),
})

const DeclineTicketModal: React.FC<Props> = ({ open, onClose, onOk, loading }) => {
  const { t } = useTranslation()

  const { control, handleSubmit, reset } = useForm<{ reason: string }>({
    resolver: yupResolver(validationSchema),
  })

  const handleDeclineTicket = (reason: { reason: string }) => {
    onOk(reason, reset)
  }

  const Ticket = useContext(TicketNotifContext)

  return (
    <Modal
      visible={open}
      onCancel={onClose}
      title={[t('tpl_router.tpl_dashboard.accept_decline_modal.decline.title'), ` ${Ticket.state}`]}
      closable={false}
      okText={t('tpl_router.tpl_dashboard.accept_decline_modal.decline.button_ok')}
      cancelText={t('tpl_router.tpl_dashboard.accept_decline_modal.decline.button_cancel')}
      onOk={handleSubmit(handleDeclineTicket)}
      confirmLoading={loading}
    >
      <Spin spinning={loading}>
        <Row>
          <Col span={8}>
            <p>{t('tpl_router.tpl_dashboard.accept_decline_modal.decline.reason')}:</p>
          </Col>
          <Col span={16}>
            <Controller
              name="reason"
              control={control}
              render={({ field }) => <TextArea {...field} />}
            />
          </Col>
        </Row>
      </Spin>
    </Modal>
  )
}

export { DeclineTicketModal }
