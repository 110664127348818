import { useMutation } from 'react-query'
import { notification } from 'antd'
import { useHistory } from 'react-router-dom'

import { QUERY_KEYS } from '@api'
import { useUser } from '@contexts'

import { UsersApi } from './api'

const onError = () => {
  notification.error({
    message: 'No pudimos encontrar el usuario',
  })
}

const routes = {
  SUPPLIER: '/dashboard/suppliers',
  CARRIER: '/dashboard/carrier',
  ADMIN: '/dashboard/admin',
  ADMINRO: '/dashboard/admin',
  CUSTOMS: '/dashboard/customs',
}

interface Props {
  onSucces?: () => void
}

const useLoginResponseManager = (props?: Props) => {
  const { setState } = useUser()
  const history = useHistory()

  const { mutate, isLoading } = useMutation([QUERY_KEYS.USER], UsersApi.getUser, {
    onError,
    onSuccess: ({ user }) => {
      setState(user)
      if (props?.onSucces) props.onSucces()
      if (history.location.pathname.includes('/login') || history.location.pathname === '/') {
        history.push(routes[user.roles[0].name])
      }
    },
  })

  return { isLoading, onSucces: mutate }
}

export { useLoginResponseManager }
