import { Route, Switch, useRouteMatch } from 'react-router-dom'

import { DashboardLayout } from '@components'
import { FiltersProvider } from '@contexts'

import { AddTruckDocumentation } from './AddTruckDocumentation'
import { SuppliersDashboard } from './SuppliersDashboard'
import { SupplierMenu } from './SupplierMenu'
import {
  HandleNewTicketModalProvider,
  HandleDeleteTicketModalProvider,
  DeleteTicketNotificationProvider,
} from './SuppliersDashboard/contexts'
import { AddRailcarDocumentation } from './AddRailcarDocumentation'

const selectOptions = [
  { value: 'ticketId', label: 'Trip ID' },
  { value: 'invoiceNumber', label: 'Invoice #' },
]

const SuppliersRouter = () => {
  const { url } = useRouteMatch()

  return (
    <FiltersProvider>
      <Switch>
        <DeleteTicketNotificationProvider>
          <HandleNewTicketModalProvider>
            <HandleDeleteTicketModalProvider>
              <DashboardLayout menu={SupplierMenu} searchSelectOptions={selectOptions}>
                <Route exact path={url} component={SuppliersDashboard} />
                <Route path={`${url}/truck-documentation`} component={AddTruckDocumentation} />
                <Route path={`${url}/railcar-documentation`} component={AddRailcarDocumentation} />
              </DashboardLayout>
            </HandleDeleteTicketModalProvider>
          </HandleNewTicketModalProvider>
        </DeleteTicketNotificationProvider>
      </Switch>
    </FiltersProvider>
  )
}

export { SuppliersRouter }
