import styled from 'styled-components'
import { Col, Button, Row, Input } from 'antd'

interface LoginTitleProps {
  color?: string
}

const LoginTitle = styled.p<LoginTitleProps>`
  color: ${props => props.color ?? props.theme.primaryColor};
  text-align: center;
  margin-bottom: 3rem;
  font-size: 1.3rem;
`

const LoginButton = styled(Button)`
  border-radius: 5px;
  text-align: center;
  &.ant-btn-primary {
    background: ${props => props.theme.primaryColor};
  }
  margin-bottom: 1rem;
  width: 180px;
`

const LoginContainer = styled.div`
  background-color: white;
  border: 1px solid #a6c1a4;
  padding: 4rem 2rem;
  width: 350px;
  min-height: 600px;
`

const LoginContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
`
const StyledInput = styled(Input)`
  width: 100%;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  padding-left: 0;
  border-color: ${props => props.theme.primaryColor};
  &:focus {
    -webkit-box-shadow: 0px 2px 0px 0 rgba(87, 168, 233, 0.2);
    box-shadow: 0px 2px 0px 0 rgba(87, 168, 233, 0.2);
  }
  &.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus {
    border-color: #ff7875;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px black !important;
  }
`

const StyledWrapper = styled(Col)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`

const IconWrapper = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
`
const FormWrapper = styled(Col)`
  & Form {
    width: 70%;
    display: flex;
    flex-direction: column;
    margin-left: 15%;
  }
`
const StyledButton = styled(Button)`
  width: 100%;
`

const StyledRow = styled(Row)`
  flex: 1;
`

export {
  StyledWrapper,
  IconWrapper,
  FormWrapper,
  StyledButton,
  StyledRow,
  LoginTitle,
  LoginButton,
  LoginContainer,
  LoginContentContainer,
  StyledCol,
  StyledInput,
}
