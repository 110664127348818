import { notification } from 'antd'
import { useQuery } from 'react-query'

import { QUERY_KEYS } from '@api'

import { TicketsApi } from './api'

const useTicket = (ticketId: number) => {
  const onError = () => {
    notification.error({
      message: 'Error retrieving ticket',
    })
  }

  const { data, isLoading } = useQuery(
    [QUERY_KEYS.TICKETS],
    () => TicketsApi.getTicketById(ticketId),
    {
      onError,
    },
  )

  return {
    ticket: data,
    isLoading,
  }
}

export { useTicket }
