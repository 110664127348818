import styled from 'styled-components'
import { InputNumber, Row, Typography } from 'antd'

const Container = styled.div`
  padding: 2%;
`
const StyledRow = styled(Row)`
  margin: 0.5rem 0;
`
const StyledText = styled(Typography)`
  font-size: 0.9rem;
  line-height: 2rem;
`

const StyledInputNumber = styled(InputNumber)`
  width: 95%;
  .ant-input-number-handler-wrap {
    display: none;
  }
  input {
    text-align: center;
  }
`

export { Container, StyledRow, StyledText, StyledInputNumber }
