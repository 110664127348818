import { Divider, Typography } from 'antd'
import styled from 'styled-components'

const StyledText = styled(Typography)`
  font-weight: bold;
`
type Props = {
  title: string
}

const TitleAndDivider: React.FC<Props> = ({ title }) => {
  return (
    <>
      <StyledText>{title}</StyledText>
      <Divider />
    </>
  )
}

export { TitleAndDivider }
