import styled from 'styled-components'
import { Row, Select, Typography } from 'antd'

const Wrapper = styled.div`
  margin-top: 20px;
  max-width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const Container = styled.div`
  flex: 1;
  margin-top: 5%;
`

const StyledRow = styled(Row)`
  margin: 10px 0;
`

const StyledErrorMessage = styled.div`
  color: #ff3333;
`

const StyledRequired = styled.span`
  color: #ff3333;
`

const StyledSelect = styled(Select)`
  width: 20rem;

  @media ${props => props.theme.sm} {
    width: 20rem;
  }
`

const StyledText = styled(Typography)`
  font-weight: bold;
`

export {
  Wrapper,
  Container,
  StyledRow,
  StyledSelect,
  StyledText,
  StyledErrorMessage,
  StyledRequired,
}
export { UploadButton } from './UploadButton'
export { UploadSingleFile } from './UploadSingleFile'
