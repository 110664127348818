import { api } from '@api'
import { Invoice } from '@types'

import { Form } from '../../validations'

interface InvoiceResponse {
  invoice: Invoice
}

const createInvoice = async (payload: Form & { ticketId: number }) => {
  const { invoice, ...restOfInvoiceValues } = payload

  const invoicePayload = new FormData()

  invoicePayload.append('invoiceFile', invoice)
  invoicePayload.append('invoice', JSON.stringify(restOfInvoiceValues))

  const response = await api.post<InvoiceResponse>('/invoices', invoicePayload)

  return response.data
}

export const InvoiceApi = { createInvoice }
