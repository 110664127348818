import styled from 'styled-components'
import { Typography, Row, Divider, Card } from 'antd'

const StyledCard = styled(Card)`
  margin-bottom: 30px;
`
const StyledText = styled(Typography)`
  font-weight: bold;
`
const StyledTitle = styled(Typography)`
  font-weight: bold;
  color: ${({ theme }) => theme.secondaryDarkGray};
`

const StyledRow = styled(Row)`
  margin: 2% 0 1%;
`

const StyledDivider = styled(Divider)`
  margin: 5px 0 5px -24px;
  width: calc(100% + 48px);

  border: ${({ theme }) => `1px solid ${theme.gray}`};
`

export { StyledText, StyledRow, StyledTitle, StyledDivider, StyledCard }
