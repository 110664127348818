import { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Col, Button, Spin } from 'antd'
// eslint-disable-next-line import/no-extraneous-dependencies
import { ValueType } from 'rc-input-number/lib/utils/MiniDecimal'

import { TitleAndDivider } from '@components'
import { useConfigurationProperties } from '@hooks'
import { useUser } from '@contexts'

import { StyledRow, Container, StyledText, StyledInputNumber } from './components'
import { validationSchema, Form } from './validations'

const Settings = () => {
  const { t } = useTranslation()
  const {
    allocationMinutes,
    allocationId,
    isLoading,
    deleteTicketsDays,
    deleteTicketsId,
    updateConfigProps,
    isConfigLoading,
  } = useConfigurationProperties()
  const { control, handleSubmit, setValue } = useForm<Form>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      allocation: 0,
      ticketDays: 0,
    },
  })
  const [newAllocationTime, setNewAllocationTime] = useState<number>()
  const [newDeleteTicketDays, setNewDeleteTicketDays] = useState<number>()
  const { state: user } = useUser()

  const handleAllocationUpdate = (form: Form) => {
    if (allocationId && allocationId !== newAllocationTime)
      updateConfigProps({ id: allocationId, value: form.allocation })
  }

  const handleDeleteTicketsUpdate = (form: Form) => {
    if (deleteTicketsId && deleteTicketsId !== newDeleteTicketDays)
      updateConfigProps({ id: deleteTicketsId, value: form.ticketDays })
  }

  useEffect(() => {
    if (allocationMinutes) setValue('allocation', allocationMinutes)
    setNewAllocationTime(allocationMinutes)
    if (deleteTicketsDays) setValue('ticketDays', deleteTicketsDays)
    setNewDeleteTicketDays(deleteTicketsDays)
  }, [allocationMinutes, deleteTicketsDays, setValue])

  const handleAllocationChange = (value: ValueType) => {
    setValue('allocation', value as number)
    setNewAllocationTime(value as number)
  }

  const handleDeleteTicketsChange = (value: ValueType) => {
    setValue('ticketDays', value as number)
    setNewDeleteTicketDays(value as number)
  }

  return (
    <Container>
      <TitleAndDivider title={t('admin_router.admin_dashboard.settings.title')} />

      <Spin spinning={isLoading || isConfigLoading}>
        <StyledRow>
          <Col span={8}>
            <StyledText>{t('admin_router.admin_dashboard.settings.assignation')}:</StyledText>
          </Col>
          <Col span={2}>
            <Controller
              name="allocation"
              control={control}
              defaultValue={allocationMinutes}
              render={({ field }) => (
                <StyledInputNumber
                  disabled={user?.roles[0].name === 'ADMINRO'}
                  min={0}
                  value={field.value}
                  onChange={handleAllocationChange}
                />
              )}
            />
          </Col>
          <Col span={3}>
            <Button
              onClick={handleSubmit(handleAllocationUpdate)}
              disabled={allocationMinutes === newAllocationTime}
            >
              {t('ta_invoice.new_invoice.update')}
            </Button>
          </Col>
        </StyledRow>
        <StyledRow>
          <Col span={8}>
            <StyledText>{t('admin_router.admin_dashboard.settings.ticket_days')}:</StyledText>
          </Col>
          <Col span={2}>
            <Controller
              name="ticketDays"
              control={control}
              defaultValue={deleteTicketsDays}
              render={({ field }) => (
                <StyledInputNumber
                  disabled={user?.roles[0].name === 'ADMINRO'}
                  min={1}
                  value={field.value}
                  onChange={handleDeleteTicketsChange}
                />
              )}
            />
          </Col>
          <Col span={3}>
            <Button
              onClick={handleSubmit(handleDeleteTicketsUpdate)}
              disabled={deleteTicketsDays === newDeleteTicketDays}
            >
              {t('ta_invoice.new_invoice.update')}
            </Button>
          </Col>
        </StyledRow>
      </Spin>
    </Container>
  )
}

export { Settings }
