import { notification } from 'antd'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'

import { QUERY_KEYS } from '@api'
import { ConfigurationProperties, ConfigurationPropertiesEnum } from '@types'

import { ConfigurationPropertiesApi } from './api'

const onError = () => {
  notification.error({
    message: 'Error retrieving configuration properties',
  })
}

const useConfigurationProperties = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const { data, isLoading } = useQuery(
    [QUERY_KEYS.CONFIGURATION_PROPERTIES],
    ConfigurationPropertiesApi.getConfigurationProperties,
    {
      onError,
    },
  )

  const allocationData = data?.find(
    (setting: ConfigurationProperties) =>
      setting.key === ConfigurationPropertiesEnum.ALLOCATION_FREQUENCY,
  )
  const allocationMinutes = allocationData ? parseInt(allocationData.value, 10) : undefined
  const allocationId = allocationData?.id
  const deleteTicketsData = data?.find(
    (setting: ConfigurationProperties) =>
      setting.key === ConfigurationPropertiesEnum.DELETE_TICKETS_FREQUENCY,
  )
  const deleteTicketsDays = deleteTicketsData ? parseInt(deleteTicketsData.value, 10) : undefined
  const deleteTicketsId = deleteTicketsData?.id

  const onMutationSuccess = () => {
    notification.success({
      message: t('admin_router.admin_dashboard.settings.success'),
    })

    queryClient.invalidateQueries(QUERY_KEYS.CONFIGURATION_PROPERTIES)
  }
  const onMutationError = () => {
    notification.success({
      message: t('admin_router.admin_dashboard.settings.error'),
    })
    queryClient.invalidateQueries(QUERY_KEYS.CONFIGURATION_PROPERTIES)
  }

  const { mutate: updateConfigProps, isLoading: isConfigLoading } = useMutation(
    ConfigurationPropertiesApi.updateConfigurationProperties,
    {
      onSuccess: onMutationSuccess,
      onError: onMutationError,
    },
  )

  return {
    settings: data,
    isLoading,
    allocationMinutes,
    allocationId,
    deleteTicketsDays,
    deleteTicketsId,
    updateConfigProps,
    isConfigLoading,
  }
}

export { useConfigurationProperties }
