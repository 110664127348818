import { api } from '@api'
import { Destination, RailcarDocumentation } from '@types'

interface GetRailcarDocumentationResponse {
  railcarDocumentation: RailcarDocumentation & {
    ticket: {
      id: number
      comments: string
      createAt: string
      withdrawalDate: string
      status: { name: string }
      destination: Destination & {
        id: number
      }
    }
  }
}

const getRailcarDocumentation = async (ticketId: number) => {
  const response = await api.get<GetRailcarDocumentationResponse>(
    `/railcar-documentation/${ticketId}/ticket`,
  )

  return response.data.railcarDocumentation
}

export const RailcarDocumentationApi = { getRailcarDocumentation }
