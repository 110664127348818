import { notification } from 'antd'
import { useQuery } from 'react-query'

import { QUERY_KEYS } from '@api'

import { DestinationsApi } from './api'

const onError = () => {
  notification.error({
    message: 'Error al obtener los destinos',
  })
}

const useDestinations = () => {
  const { data, isLoading } = useQuery(QUERY_KEYS.DESTINATIONS, DestinationsApi.getDestinations, {
    onError,
  })

  const destinationsOptions = data?.map(carrier => {
    return {
      label: carrier.name,
      value: carrier.id,
    }
  })

  return {
    destinations: data,
    isLoading,
    destinationsOptions,
  }
}

export { useDestinations }
