import { notification } from 'antd'
import { useMutation } from 'react-query'

import { DownloadPDFApi } from './api'

const onError = () => {
  notification.error({
    message: 'Error al descargar el archivo',
  })
}

interface DownloadResponse {
  invoiceZip: {
    AcceptRanges: string
    LastModified: string
    ContentLength: number
    ETag: string
    ContentType: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Metadata: any
    StorageClass: string
    Body: {
      type: string
      data: number[]
    }
  }
}

const useDownloadPDF = (name: string) => {
  const onSuccess = (data: DownloadResponse) => {
    const byteArray = new Uint8Array(data.invoiceZip.Body.data)
    const a = window.document.createElement('a')

    a.href = window.URL.createObjectURL(new Blob([byteArray], { type: 'application/zip' }))
    a.download = name

    document.body.appendChild(a)
    a.click()

    document.body.removeChild(a)
  }

  const { mutate: downloadZip, isLoading } = useMutation(DownloadPDFApi.donwloadZipFolder, {
    onSuccess: data => onSuccess(data),
    onError,
  })

  return {
    downloadZip,
    isLoading,
  }
}

export { useDownloadPDF }
