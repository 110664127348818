import React from 'react'
import { Row, Col, Modal, InputNumber, Switch, Spin } from 'antd'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

import { Carrier } from '@types'

type Props = {
  open: boolean
  options: { label: string; value: number }[] | undefined
  loading: boolean
  assignedCarrier: Carrier | undefined
  onClose: () => void
  onOk: (
    carrierId: number,
    category: number,
    enabledForAlgorithm: boolean,
    resetForm: () => void,
  ) => void
}

type ChangeCarrierPayload = {
  carrierId: number
  category: number
  enabledForAlgorithm: boolean
}

const validationSchema = yup.object().shape({
  carrierId: yup.number().integer().optional(),
  category: yup.number().integer().optional(),
  enabledForAlgorithm: yup.boolean().optional(),
})

const ChangeCarrierOptionsModal: React.FC<Props> = ({
  open,
  assignedCarrier,
  onClose,
  onOk,
  loading,
}) => {
  const { t } = useTranslation()

  const { control, handleSubmit, reset } = useForm<ChangeCarrierPayload>({
    resolver: yupResolver(validationSchema),
  })

  const handleChangeCarrier = (payload: ChangeCarrierPayload) => {
    const { carrierId, category, enabledForAlgorithm } = payload
    const booleanEnabled = Boolean(enabledForAlgorithm)
    onOk(carrierId, category, booleanEnabled, reset)
  }

  return (
    <Modal
      visible={open}
      onCancel={onClose}
      title={`${t('tpl_router.tpl_dashboard.change_carrier_modal.title')}: ${
        assignedCarrier?.businessName
      }`}
      closable={false}
      okText={t('tpl_router.tpl_dashboard.change_carrier_modal.button_ok')}
      cancelText={t('tpl_router.tpl_dashboard.change_carrier_modal.button_cancel')}
      onOk={handleSubmit(handleChangeCarrier)}
      afterClose={reset}
      destroyOnClose
    >
      <Spin spinning={loading}>
        <Row>
          <Col span={8}>
            <p>{t('tpl_router.tpl_dashboard.change_carrier_modal.id')}: </p>
          </Col>
          <Col span={16}>
            <Controller
              name="carrierId"
              control={control}
              defaultValue={assignedCarrier?.id}
              render={({ field }) => (
                <InputNumber {...field} min={1} value={field.value} disabled />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <p>{t('tpl_router.tpl_dashboard.change_carrier_modal.category')}:</p>
          </Col>
          <Col span={16}>
            <Controller
              name="category"
              control={control}
              defaultValue={assignedCarrier?.category}
              render={({ field }) => <InputNumber {...field} value={field.value} min={1} />}
            />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <p>{t('tpl_router.tpl_dashboard.change_carrier_modal.algorithm')}:</p>
          </Col>
          <Col span={16}>
            <Controller
              name="enabledForAlgorithm"
              control={control}
              defaultValue={assignedCarrier?.enabledForAlgorithm}
              render={({ field }) => <Switch {...field} checked={!!field.value} />}
            />
          </Col>
        </Row>
      </Spin>
    </Modal>
  )
}

export { ChangeCarrierOptionsModal }
