import { useMutation } from 'react-query'
import { notification } from 'antd'
import { useTranslation } from 'react-i18next'

import { AuthApi } from '@api'

const useRecoverPass = ({ onSuccess }: { onSuccess: () => void }) => {
  const { t } = useTranslation()

  const { mutate: recoverPass, isLoading } = useMutation(AuthApi.recoverPass, {
    onSuccess: () => {
      notification.success({
        message: t('login.recover_pass_email_sent'),
      })
      onSuccess()
    },
    onError: () => {
      notification.error({
        message: t('login.recover_pass_user_not_found'),
      })
    },
  })

  return {
    recoverPass,
    isLoading,
  }
}

export { useRecoverPass }
