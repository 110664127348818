import { notification } from 'antd'
import { useQuery } from 'react-query'

import { QUERY_KEYS } from '@api'

import { TicketsCountApi } from './api'

const onError = () => {
  notification.error({
    message: 'Error al obtener la cantidad de tickets por estado',
  })
}

const useTicketsCountByState = () => {
  const { data, isLoading } = useQuery([QUERY_KEYS.TICKETS_COUNT], TicketsCountApi.getCount, {
    onError,
  })

  return {
    count: data,
    isLoading,
  }
}

export { useTicketsCountByState }
