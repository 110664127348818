import React, { useState } from 'react'
import { Upload, Typography, Row, Col, Spin, notification } from 'antd'
import styled from 'styled-components'
import { RcFile, UploadChangeParam } from 'antd/lib/upload'
import { UploadFile } from 'antd/lib/upload/interface'
import { useTranslation } from 'react-i18next'

import { useDownload } from '@hooks'

const StyledUploadButton = styled(Typography)`
  color: ${({ theme }) => theme.primaryColor};
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
  cursor: pointer;
`

const UploadedFile = styled(Typography)`
  color: ${({ theme }) => theme.primaryColor};
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
`
const StyledCol = styled(Col)`
  margin: 10px 0 10px;
`

const StyledRow = styled(Row)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`

type Props = {
  accept?: string
  onChange: (file: File[] | null) => void
  fileData?: { key?: string; name: string }[]
}

const UploadButton: React.FC<Props> = ({ accept = '.pdf', onChange, fileData }) => {
  const [filesList, setFile] = useState<UploadFile<File>[]>([])
  const { t } = useTranslation()
  const { downloadFile, isLoading } = useDownload()

  const uploadButton = <StyledUploadButton>{t('globals.add_file')}...</StyledUploadButton>

  const handleChange = ({ fileList: newFileList }: UploadChangeParam) => {
    if (newFileList.length) {
      const newList = newFileList.map(file => file.originFileObj!)
      onChange([...newList])
      return setFile([...newList])
    }
    return setFile([])
  }
  // TODO: create logic for handleRemove
  //   const handleRemove = (file: UploadFile<any>) => {
  //     console.log(file)
  //   }

  return (
    <Spin spinning={isLoading}>
      <Upload
        beforeUpload={(file: RcFile) => {
          console.log(file)
          if (file.size > 2097153) {
            notification.error({
              message: t('globals.exceeded_size'),
            })
          }
          return file.size < 2097153 ? false : Upload.LIST_IGNORE
        }}
        onChange={handleChange}
        multiple
        showUploadList={false}
        accept={accept}
        maxCount={fileData?.length === 4 ? -1 : 4 - (fileData ? fileData.length : 0)}
      >
        {uploadButton}
      </Upload>
      <StyledRow>
        {filesList.map(file => {
          return (
            <StyledCol key={file.name}>
              <UploadedFile>{file.name}</UploadedFile>
            </StyledCol>
          )
        })}
        {fileData?.map(file => {
          return (
            <StyledCol
              key={file.key}
              onClick={() => {
                downloadFile({ key: file.key!, name: file.name })
              }}
            >
              <UploadedFile>{`${file.name}`}</UploadedFile>
            </StyledCol>
          )
        })}
      </StyledRow>
    </Spin>
  )
}
export { UploadButton }
