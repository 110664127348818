/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useContext, Dispatch, SetStateAction } from 'react'

import { RPAFilters } from '@types'

type ContextState = {
  filters?: RPAFilters
  setFilters: Dispatch<SetStateAction<RPAFilters>>
}

const initialState = {
  filters: { page: 1, limit: 5 },
  setFilters: () => {},
}

const RPAFiltersContext = React.createContext<ContextState>(initialState)

export function RPAFiltersProvider({ children }: { children: React.ReactNode }) {
  const [filters, setFilters] = useState<RPAFilters>({ page: 1 })

  return (
    <RPAFiltersContext.Provider value={{ filters, setFilters }}>
      {children}
    </RPAFiltersContext.Provider>
  )
}

export function useRPAFilters(): ContextState {
  const context = useContext(RPAFiltersContext)
  if (!context) throw Error('This hook can only be used under the RPAFiltersProvider component')
  return context
}
