import { api } from '@api'

interface DeleteTicketsResponse {
  tickets: string
}

const deleteTicket = async (id: number) => {
  const response = await api.delete<DeleteTicketsResponse>(`/tickets/${id}`)

  return response.data
}

export const TicketsApi = { deleteTicket }
