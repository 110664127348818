import { Button, Col, Table } from 'antd'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { SelectValue } from 'antd/lib/select'
import { useEffect } from 'react'

import { usePagination, useSuppliers, useTickets, useDestinations, useCarriers } from '@hooks'
import {
  Filters,
  StatusTicketEnum,
  Ticket,
  Invoice,
  Destination,
  CarrierModeEnum,
  InvoiceCheckEnum,
} from '@types'
import { useFilters } from '@contexts'
import { EyeIcon, DollarIcon, RailcarIcon, TruckIcon } from '@icons'

import {
  ContainerTable,
  StyledRow,
  StyledSelect,
  CountIndicator,
  StyledCol,
  StyledSubButton,
} from '../../AdminDashboard/components'
import { StyledText } from './components'

const Ongoing = () => {
  const { t } = useTranslation()

  const { filters, setFilters } = useFilters()

  useEffect(() => {
    setFilters({
      page: 1,
      limit: 20,
      statusName: [
        StatusTicketEnum.PENDING,
        StatusTicketEnum.CONFIRMED,
        StatusTicketEnum.INVOICED,
        StatusTicketEnum.TRANSIT,
        StatusTicketEnum.AWAITING,
        StatusTicketEnum.RELEASED,
        StatusTicketEnum.SUPPLIER_INVOICED,
        StatusTicketEnum.TA_INVOICED,
        StatusTicketEnum.MX_TRANSIT,
      ],
    })
  }, [setFilters])

  const { isLoading, ticketsOngoing } = useTickets(filters)
  const { suppliersOptions, isLoading: isSuppliersLoading } = useSuppliers()
  const { destinationsOptions, isLoading: isDestinationsLoading } = useDestinations()
  const pagination = usePagination({ filters, setFilters }, ticketsOngoing?.total)
  const { carriersOptions, isLoading: carriersLoading } = useCarriers()

  const dateFormat = 'MMM DD, hh:mm a'
  const dateHourFormat = 'MMM DD, hh:mm a'

  const isTicketPendingOrConfirmed = (ticket: Ticket) =>
    ticket.status.name === StatusTicketEnum.PENDING ||
    ticket.status.name === StatusTicketEnum.CONFIRMED

  const columns = [
    {
      title: t('admin_router.admin_dashboard.columns.trip_id'),
      render: (ticket: Ticket) => (
        <>
          {ticket.carrier && ticket.carrier.mode === CarrierModeEnum.RAILCAR ? (
            <RailcarIcon width={18} height={15} />
          ) : null}
          {ticket.ticketAssignments.length &&
          ticket.ticketAssignments[0].carrier.mode === CarrierModeEnum.TRUCK ? (
            <TruckIcon width={18} height={15} />
          ) : null}{' '}
          {ticket.id}
        </>
      ),
    },
    {
      title: t('admin_router.admin_dashboard.columns.date'),
      dataIndex: 'createAt',
      key: 'id',
      render: (date: Date) => moment(date).format(dateFormat),
    },
    {
      title: t('admin_router.admin_dashboard.columns.supplier'),
      dataIndex: 'supplier',
      key: 'id',
      render: (carrier?: { id: number; businessName: string }) =>
        carrier && <>{carrier.businessName}</>,
    },
    {
      title: t('admin_router.admin_dashboard.columns.invoice_number'),
      render: (ticket: Ticket) =>
        ticket.truckDocumentation ? (
          <>{ticket.truckDocumentation.invoiceNumber}</>
        ) : (
          ticket.railcarDocumentation && <>{ticket.railcarDocumentation.invoiceNumber}</>
        ),
    },
    {
      title: t('admin_router.admin_dashboard.columns.ta_inv'),
      dataIndex: 'invoice',
      key: 'name',
      render: (invoice?: Invoice) =>
        invoice && (
          <>
            {invoice.sapValidationStatus !== InvoiceCheckEnum.REJECTED ? (
              invoice.number
            ) : (
              <StyledText>{invoice.number}</StyledText>
            )}
          </>
        ),
    },
    {
      title: t('admin_router.admin_dashboard.columns.carrier'),
      dataIndex: 'carrier',
      key: 'id',
      render: (carrier?: { id: number; businessName: string }) =>
        carrier && <>{carrier.businessName}</>,
    },
    {
      title: t('admin_router.admin_dashboard.columns.origin'),
      dataIndex: 'origin',
      key: 'id',
      render: (depot?: {
        id: number
        city: string
        address: string
        location: { type: string; coordinates: [number, number] }
      }) => {
        const handleLinkClick = async () => {
          if (depot) {
            const mapUrl = `https://www.google.com/maps/search/?api=1&query=${depot.location.coordinates[0]},${depot.location.coordinates[1]}`
            window.open(mapUrl, '_blank')
          }
        }
        return (
          <>
            {depot && (
              <StyledSubButton type="link" onClick={handleLinkClick}>
                {depot.city}
              </StyledSubButton>
            )}
          </>
        )
      },
    },
    {
      title: t('admin_router.admin_dashboard.columns.destination'),
      dataIndex: 'destination',
      key: 'destination',
      render: (destination?: Destination) => destination?.name,
    },
    {
      title: t('tpl_router.tpl_dashboard.columns.withdrawal_date'),
      dataIndex: 'withdrawalDate',
      key: 'withdrawalDate',
      render: (withdrawalDate: Date) =>
        withdrawalDate && <>{moment(withdrawalDate).format(dateHourFormat)}</>,
    },
    {
      title: t('admin_router.admin_dashboard.columns.status'),
      dataIndex: 'status',
      key: 'id',
      render: ({ name }: { name: string }) => {
        const status = name.toLowerCase()

        const style =
          status === StatusTicketEnum.CANCELED.toLowerCase() ? { color: 'red' } : undefined

        return (
          <>
            <span style={style}>{name}</span>
          </>
        )
      },
    },
    {
      title: t('admin_router.admin_dashboard.columns.actions'),
      render: (ticket: Ticket) => {
        return (
          <>
            {!isTicketPendingOrConfirmed(ticket) && (
              <Link
                to={{
                  pathname: `/dashboard/admin/ta-invoice/${ticket.id}`,
                  state: { ticket },
                }}
              >
                <EyeIcon />
              </Link>
            )}
            {ticket.invoice?.paymentDate && <DollarIcon height={21} fill="green" />}
          </>
        )
      },
    },
  ]

  const filterOptions = [
    {
      label: `${t('admin_router.filter_options.status.pending')}`,
      value: StatusTicketEnum.PENDING,
    },
    {
      label: `${t('admin_router.filter_options.status.confirmed')}`,
      value: StatusTicketEnum.CONFIRMED,
    },
    {
      label: `${t('admin_router.filter_options.status.supplier_invoiced')}`,
      value: StatusTicketEnum.SUPPLIER_INVOICED,
    },
    {
      label: `${t('admin_router.filter_options.status.ta_invoiced')}`,
      value: StatusTicketEnum.TA_INVOICED,
    },
    {
      label: `${t('admin_router.filter_options.status.mx_transit')}`,
      value: StatusTicketEnum.MX_TRANSIT,
    },

    // NUEVOS

    {
      label: `${t('suppliers_router.filter_options.status.transit')}`,
      value: StatusTicketEnum.TRANSIT,
    },
    {
      label: `${t('suppliers_router.filter_options.status.delivered')}`,
      value: StatusTicketEnum.DELIVERED,
    },
    {
      label: `${t('suppliers_router.filter_options.status.awaiting')}`,
      value: StatusTicketEnum.AWAITING,
    },
    {
      label: `${t('suppliers_router.filter_options.status.released')}`,
      value: StatusTicketEnum.RELEASED,
    },

    {
      label: `${t('suppliers_router.filter_options.status.canceled')}`,
      value: StatusTicketEnum.CANCELED,
    },
  ]

  const orderByOptions = [
    {
      label: `${t('admin_router.filter_options.order_by.ascending')}`,
      value: 'ASC',
    },
    {
      label: `${t('admin_router.filter_options.order_by.descending')}`,
      value: 'DESC',
    },
  ]

  const carrierModeOptions = [
    {
      label: (
        <>
          <RailcarIcon height={15} width={15} />{' '}
          {t('suppliers_router.filter_options.carrier.railcar')}
        </>
      ),
      value: CarrierModeEnum.RAILCAR,
    },
    {
      label: (
        <>
          <TruckIcon height={15} width={15} /> {t('suppliers_router.filter_options.carrier.truck')}
        </>
      ),
      value: CarrierModeEnum.TRUCK,
    },
  ]

  const handleStatusFilterChange = (value: SelectValue) => {
    setFilters((prevState: Filters) => ({
      ...prevState,
      page: 1,
      statusName: [...(value as string[])],
      ticketId: undefined,
      invoiceNumber: undefined,
      taInvoiceNumber: undefined,
    }))
  }

  const handleSupplierFilterChange = (value: SelectValue) => {
    setFilters((prevState: Filters) => ({
      ...prevState,
      page: 1,
      supplierId: [...(value as number[])],
      ticketId: undefined,
      invoiceNumber: undefined,
      taInvoiceNumber: undefined,
    }))
  }

  const handleDestinationFilterChange = (value: SelectValue) => {
    setFilters((prevState: Filters) => ({
      ...prevState,
      page: 1,
      destinationId: [...(value as number[])],
      ticketId: undefined,
      invoiceNumber: undefined,
      taInvoiceNumber: undefined,
    }))
  }

  const handleOrderByFilterChange = (value: SelectValue) => {
    setFilters((prevState: Filters) => ({
      ...prevState,
      page: 1,
      orderBy: value as string,
      ticketId: undefined,
      invoiceNumber: undefined,
      taInvoiceNumber: undefined,
    }))
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFilterOptions = (input: string, option: any) => {
    if (option.label) return option.label.toString().toLowerCase().includes(input.toLowerCase())
    return true
  }

  const handleCarrierModeFilterChange = (value: SelectValue) => {
    setFilters((prevState: Filters) => ({
      ...prevState,
      page: 1,
      carrierMode: [...(value as CarrierModeEnum[])],
      ticketId: undefined,
      invoiceNumber: undefined,
      taInvoiceNumber: undefined,
    }))
  }

  const handleCarrierFilterChange = (value: SelectValue) => {
    setFilters((prevState: Filters) => ({
      ...prevState,
      page: 1,
      carrierId: [...(value as number[])],
      ticketId: undefined,
      invoiceNumber: undefined,
      taInvoiceNumber: undefined,
    }))
  }

  const handleCleanFilters = () => {
    setFilters((prevState: Filters) => ({
      ...prevState,
      page: 1,
      limit: 10,
      carrierId: [],
      carrierMode: [],
      statusName: [
        StatusTicketEnum.PENDING,
        StatusTicketEnum.CONFIRMED,
        StatusTicketEnum.INVOICED,
        StatusTicketEnum.TRANSIT,
        StatusTicketEnum.DELIVERED,
        StatusTicketEnum.AWAITING,
        StatusTicketEnum.RELEASED,
        StatusTicketEnum.SUPPLIER_INVOICED,
        StatusTicketEnum.TA_INVOICED,
        StatusTicketEnum.MX_TRANSIT,
      ],
      supplierId: [],
      destinationId: [],
      borderCrossingId: [],
      orderBy: undefined,
      ticketId: undefined,
      invoiceNumber: undefined,
      taInvoiceNumber: undefined,
    }))
  }

  return (
    <ContainerTable>
      <StyledRow gutter={[20, 20]}>
        <Col>
          <CountIndicator />
        </Col>
        <StyledCol>
          <Button onClick={handleCleanFilters}>Clean filters</Button>
        </StyledCol>
      </StyledRow>
      <StyledRow gutter={[10, 10]}>
        <Col span={3}>
          <StyledSelect
            mode="multiple"
            allowClear
            placeholder={t('suppliers_router.filter_options.carrier.mode')}
            options={carrierModeOptions}
            onChange={handleCarrierModeFilterChange}
            value={filters?.carrierMode}
            maxTagCount="responsive"
          />
        </Col>
        <Col span={6}>
          <StyledSelect
            mode="multiple"
            allowClear
            placeholder={t('admin_router.filter_options.status.title')}
            options={filterOptions}
            onChange={handleStatusFilterChange}
            value={filters?.statusName}
            maxTagCount="responsive"
          />
        </Col>
        <Col span={4}>
          <StyledSelect
            mode="multiple"
            allowClear
            placeholder={t('admin_router.filter_options.supplier')}
            options={suppliersOptions}
            filterOption={handleFilterOptions}
            onChange={handleSupplierFilterChange}
            loading={isSuppliersLoading}
            value={filters?.supplierId}
            maxTagCount="responsive"
          />
        </Col>
        <Col span={4}>
          <StyledSelect
            mode="multiple"
            allowClear
            placeholder={t('suppliers_router.filter_options.carrier.name')}
            options={carriersOptions}
            loading={carriersLoading}
            filterOption={handleFilterOptions}
            onChange={handleCarrierFilterChange}
            value={filters?.carrierId}
            maxTagCount="responsive"
          />
        </Col>

        <Col span={4}>
          <StyledSelect
            mode="multiple"
            allowClear
            placeholder={t('admin_router.filter_options.destination')}
            options={destinationsOptions}
            filterOption={handleFilterOptions}
            onChange={handleDestinationFilterChange}
            loading={isDestinationsLoading}
            value={filters?.destinationId}
            maxTagCount="responsive"
          />
        </Col>
        <Col span={3}>
          <StyledSelect
            allowClear
            placeholder={t('admin_router.filter_options.order_by.title')}
            options={orderByOptions}
            onChange={handleOrderByFilterChange}
            value={filters?.orderBy}
            maxTagCount="responsive"
          />
        </Col>
      </StyledRow>
      <Table
        columns={columns}
        dataSource={ticketsOngoing?.tickets}
        pagination={pagination}
        loading={isLoading}
        rowKey="id"
        scroll={{ y: 'calc(100vh - 410px)' }}
      />
    </ContainerTable>
  )
}

export { Ongoing }
