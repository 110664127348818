import { api } from '@api'

type Destinations = {
  id: number
  name: string
  address: string
}

interface GetDestinationsResponse {
  destinations: Destinations[]
}

const getDestinations = async () => {
  const response = await api.get<GetDestinationsResponse>('/destinations')

  return response.data.destinations
}

export const DestinationsApi = { getDestinations }
