import { Col, DatePicker, Input, Button, Spin, InputNumber, Tag, Divider } from 'antd'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { SelectValue } from 'antd/lib/select'
import moment from 'moment'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { ErrorMessage } from '@hookform/error-message'

import { ArrayUtils, StringUtils } from '@utils'
import { useCancelTicket, usePurchases } from '@hooks'
import { StatusTicketEnum } from '@types'
import { useUser } from '@contexts'

import { UpdateForm, validationSchema } from './validations'
import {
  Wrapper,
  UploadButton,
  Container,
  StyledRow,
  StyledSelect,
  StyledText,
  UploadSingleFile,
  StyledErrorMessage,
} from './components'
import { useUpdateTruckDocumentation, useTruckDocumentation, useDestinations } from './hooks'
import { useTicket } from '../../hooks'

interface Props {
  ticketId: number
  supplierId: number
  ticketRPA: number | '-' | undefined
}

const SupplierDocumentation: React.FC<Props> = ({ ticketId, supplierId, ticketRPA }) => {
  const ticket = useTicket(ticketId)
  const history = useHistory()
  const { state: user } = useUser()

  const { t } = useTranslation()
  const { updateTruckDocumentation, isLoading } = useUpdateTruckDocumentation()
  const { truckDocumentation } = useTruckDocumentation(ticketId)
  const { destinationsOptions, isLoading: isDestinationsLoading } = useDestinations()
  const {
    materialOptions,
    materialsLoading,
    getPurchasesByMaterial,
    documentNumberIdsLoading,
    documentIdsOptions,
  } = usePurchases(supplierId)

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<UpdateForm>({
    resolver: yupResolver(validationSchema),
  })

  useEffect(() => {
    reset({
      destinationId: truckDocumentation?.ticket.destination?.id,
      invoiceDate: truckDocumentation?.invoiceDate,
      invoiceNumber: truckDocumentation?.invoiceNumber,
      invoiceAmount: truckDocumentation?.invoiceAmount,
      grossWeight: truckDocumentation?.grossWeight,
      tareWeight: truckDocumentation?.tareWeight,
      materialId: truckDocumentation?.materialId,
      materialDescription: truckDocumentation?.materialDescription,
      purchaseOrderId: truckDocumentation?.purchaseOrderId,
    })
  }, [truckDocumentation, reset])

  const handleForm = async (form: UpdateForm) => {
    if (truckDocumentation) {
      updateTruckDocumentation({
        ...form,
        photos: form.photos ?? [],
        truckDocumentationId: truckDocumentation.id,
      })
    }
  }

  const { cancelTicket } = useCancelTicket(ticketId)

  const handleMaterialChange = (value: SelectValue) => {
    setValue('purchaseOrderId', '')
    const material = materialOptions!.find(
      (option: { value: SelectValue }) => option.value === value,
    )
    setValue('materialDescription', material!.label)
    getPurchasesByMaterial({
      materialId: value as string,
      supplierId,
    })
  }

  useEffect(() => {
    if (truckDocumentation?.materialId)
      getPurchasesByMaterial({
        materialId: truckDocumentation?.materialId,
        supplierId,
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [truckDocumentation])

  const handleCancelTicket = (id: number) => {
    cancelTicket(id)

    history.push('/dashboard/admin')
  }
  return (
    <Wrapper>
      <Spin spinning={isLoading}>
        <Container>
          <StyledRow>
            <Col span={8}>
              <StyledText>
                {t(
                  'ta_invoice.add_truck_documentation.title_and_divider.supplier_documentation.title',
                )}
              </StyledText>
            </Col>
            <Col>
              {ticketRPA !== undefined ? (
                <Tag color="green">{`RPA #${ticketRPA}`}</Tag>
              ) : (
                <Tag color="default">RPA</Tag>
              )}
            </Col>
            <Divider />
          </StyledRow>
          <StyledRow>
            <Col sm={{ span: 16 }} md={{ span: 8 }}>
              <StyledText>
                {t('ta_invoice.add_truck_documentation.title_and_divider.new_truck.depot')}:
              </StyledText>
            </Col>
            <Col span={8}>
              <Controller
                control={control}
                name="destinationId"
                render={({ field }) => (
                  <StyledSelect
                    {...field}
                    placeholder={t('globals.placeholder_select')}
                    options={destinationsOptions}
                    loading={isDestinationsLoading}
                  />
                )}
              />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col span={8}>
              <StyledText>
                {t(
                  'ta_invoice.add_truck_documentation.title_and_divider.supplier_documentation.invoice_date',
                )}
                :
              </StyledText>
            </Col>
            <Col span={8}>
              <Controller
                name="invoiceDate"
                control={control}
                render={({ field: { value } }) => (
                  <DatePicker
                    value={moment(value)}
                    onChange={date => {
                      if (date) {
                        setValue('invoiceDate', date.toDate())
                      }
                    }}
                  />
                )}
              />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col span={8}>
              <StyledText>
                {t(
                  'ta_invoice.add_truck_documentation.title_and_divider.supplier_documentation.invoice_number',
                )}
                :
              </StyledText>
            </Col>
            <Col span={8}>
              <Controller
                control={control}
                name="invoiceNumber"
                render={({ field }) => <Input {...field} />}
              />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col span={8}>
              <StyledText>
                {t(
                  'ta_invoice.add_truck_documentation.title_and_divider.supplier_documentation.invoice_amount',
                )}
                :
              </StyledText>
            </Col>
            <Col span={8}>
              <Controller
                control={control}
                name="invoiceAmount"
                defaultValue={truckDocumentation?.invoiceAmount}
                render={({ field }) => (
                  <InputNumber
                    style={{ width: '150px' }}
                    {...field}
                    value={field.value ?? undefined}
                    step="0.01"
                    precision={2}
                    formatter={value => {
                      if (!value) return '$ '
                      const decimals =
                        value?.toString().search(/\./) >= 0
                          ? value?.toString().split('.').reverse()[0].slice(0, 2)
                          : null
                      const number = `$ ${Math.floor(value)}`.replace(/\B(?=(\d{3})+(?!.))/g, ',')
                      if (decimals) return `${number}.${decimals}`
                      return `${number}`
                    }}
                  />
                )}
              />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col span={8}>
              <StyledText>
                {t(
                  'ta_invoice.add_truck_documentation.title_and_divider.supplier_documentation.gross_weight',
                )}
                :
              </StyledText>
            </Col>
            <Col span={8}>
              <Controller
                control={control}
                name="grossWeight"
                defaultValue={truckDocumentation && truckDocumentation.grossWeight}
                render={({ field }) => (
                  <InputNumber
                    style={{ width: '150px' }}
                    {...field}
                    value={field.value ?? undefined}
                    step="0.01"
                    precision={2}
                    formatter={value => {
                      if (!value) return ' '
                      const decimals =
                        value?.toString().search(/\./) >= 0
                          ? value?.toString().split('.').reverse()[0].slice(0, 2)
                          : null
                      const number = ` ${Math.floor(value)}`.replace(/\B(?=(\d{3})+(?!.))/g, ',')
                      if (decimals) return `${number}.${decimals}`
                      return `${number}`
                    }}
                  />
                )}
              />
              <Col>
                <StyledErrorMessage>
                  <ErrorMessage errors={errors} name="grossWeight" />
                </StyledErrorMessage>
              </Col>
            </Col>
          </StyledRow>
          <StyledRow>
            <Col span={8}>
              <StyledText>
                {t(
                  'ta_invoice.add_truck_documentation.title_and_divider.supplier_documentation.tare_weight',
                )}
                :
              </StyledText>
            </Col>
            <Col span={8}>
              <Controller
                control={control}
                name="tareWeight"
                defaultValue={truckDocumentation && truckDocumentation.tareWeight}
                render={({ field }) => (
                  <InputNumber
                    style={{ width: '150px' }}
                    {...field}
                    value={field.value ?? undefined}
                    step="0.01"
                    precision={2}
                    formatter={value => {
                      if (!value) return ' '
                      const decimals =
                        value?.toString().search(/\./) >= 0
                          ? value?.toString().split('.').reverse()[0].slice(0, 2)
                          : null
                      const number = ` ${Math.floor(value)}`.replace(/\B(?=(\d{3})+(?!.))/g, ',')
                      if (decimals) return `${number}.${decimals}`
                      return `${number}`
                    }}
                  />
                )}
              />
            </Col>
          </StyledRow>

          <StyledRow>
            <Col span={8}>
              <StyledText>
                {t(
                  'ta_invoice.add_truck_documentation.title_and_divider.supplier_documentation.material',
                )}
                :
              </StyledText>
            </Col>
            <Col sm={{ span: 8 }} md={{ span: 16 }}>
              <Controller
                control={control}
                name="materialId"
                defaultValue={truckDocumentation?.materialId}
                render={({ field }) => (
                  <StyledSelect
                    placeholder={t('globals.placeholder_select_material')}
                    options={materialOptions}
                    loading={materialsLoading}
                    onSelect={handleMaterialChange}
                    {...field}
                    value={field.value}
                  />
                )}
              />
              <Controller
                control={control}
                name="materialDescription"
                defaultValue={truckDocumentation?.materialDescription}
                render={({ field }) => <Input {...field} value={field.value ?? undefined} hidden />}
              />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col span={8}>
              <StyledText>
                {t(
                  'ta_invoice.add_truck_documentation.title_and_divider.supplier_documentation.PO',
                )}
                :
              </StyledText>
            </Col>
            <Col sm={{ span: 8 }} md={{ span: 16 }}>
              <Controller
                control={control}
                name="purchaseOrderId"
                defaultValue={truckDocumentation?.purchaseOrderId}
                render={({ field }) => (
                  <StyledSelect
                    placeholder={t('globals.placeholder_select_po')}
                    options={documentIdsOptions}
                    loading={documentNumberIdsLoading}
                    {...field}
                    value={field.value}
                  />
                )}
              />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col span={8}>
              <StyledText>
                {t(
                  'ta_invoice.add_truck_documentation.title_and_divider.supplier_documentation.invoice_sell',
                )}
                :
              </StyledText>
            </Col>
            <Col span={16}>
              <Controller
                control={control}
                name="salesInvoice"
                render={({ field }) => (
                  <UploadSingleFile
                    {...field}
                    fileData={ArrayUtils.reverse(truckDocumentation?.salesInvoice)?.map(
                      salesInvoice => ({
                        key: salesInvoice,
                        name: StringUtils.formatFilename(
                          ticketId,
                          salesInvoice,
                          'InvoiceSell',
                          'pdf',
                        ),
                      }),
                    )}
                  />
                )}
              />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col span={8}>
              <StyledText>
                {t(
                  'ta_invoice.add_truck_documentation.title_and_divider.supplier_documentation.weight_certificate',
                )}
                :
              </StyledText>
            </Col>
            <Col span={16}>
              <Controller
                control={control}
                name="weightCertificate"
                render={({ field }) => (
                  <UploadSingleFile
                    {...field}
                    fileData={ArrayUtils.reverse(truckDocumentation?.weightCertificate)?.map(
                      weightCertificate => ({
                        key: weightCertificate,
                        name: StringUtils.formatFilename(
                          ticketId,
                          weightCertificate,
                          'WeightCertificate',
                          'pdf',
                        ),
                      }),
                    )}
                  />
                )}
              />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col span={8}>
              <StyledText> B/L:</StyledText>
            </Col>
            <Col span={16}>
              <Controller
                control={control}
                name="BL"
                render={({ field }) => (
                  <UploadSingleFile
                    {...field}
                    fileData={ArrayUtils.reverse(truckDocumentation?.bl)?.map(document => ({
                      key: document,
                      name: StringUtils.formatFilename(ticketId, document, 'BL', 'pdf'),
                    }))}
                  />
                )}
              />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col span={8}>
              <StyledText>No Radioact. Cert.</StyledText>
            </Col>
            <Col span={16}>
              <Controller
                control={control}
                name="radioactiveCertificate"
                render={({ field }) => (
                  <UploadSingleFile
                    {...field}
                    fileData={ArrayUtils.reverse(truckDocumentation?.radioactiveCertificate)?.map(
                      radioactiveCertificate => ({
                        key: radioactiveCertificate,
                        name: StringUtils.formatFilename(
                          ticketId,
                          radioactiveCertificate,
                          'RadioactiveCertificate',
                          'pdf',
                        ),
                      }),
                    )}
                  />
                )}
              />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col span={8}>
              <StyledText>
                {t(
                  'ta_invoice.add_truck_documentation.title_and_divider.supplier_documentation.photos',
                )}
                :
              </StyledText>
            </Col>
            <Col span={16}>
              <Controller
                control={control}
                name="photos"
                render={({ field }) => (
                  <UploadButton
                    accept="image/*"
                    {...field}
                    fileData={ArrayUtils.reverse(truckDocumentation?.photos)?.map(photo => ({
                      key: `${photo}`,
                      name: StringUtils.formatFilename(ticketId, photo, 'photo', 'png'),
                    }))}
                  />
                )}
              />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col span={8}>
              <StyledText>
                {t(
                  'ta_invoice.add_truck_documentation.title_and_divider.supplier_documentation.comments',
                )}
                :
              </StyledText>
            </Col>
            <Col span={16}>
              <>
                {truckDocumentation?.ticket.comments !== ''
                  ? '-'
                  : truckDocumentation?.ticket.comments}
              </>
            </Col>
          </StyledRow>
          {user?.roles[0].name === 'ADMIN' && (
            <>
              <Button
                style={{ marginRight: '40px' }}
                onClick={handleSubmit(handleForm)}
                disabled={ticket.ticket?.status.name === StatusTicketEnum.CANCELED}
              >
                {t(
                  'ta_invoice.add_truck_documentation.title_and_divider.supplier_documentation.button',
                )}
              </Button>

              <Button
                style={{ marginRight: '40px' }}
                onClick={() => handleCancelTicket(ticketId)}
                disabled={ticket.ticket?.status.name !== StatusTicketEnum.SUPPLIER_INVOICED}
              >
                {t('ta_invoice.add_truck_documentation.buttons.cancel')}
              </Button>
            </>
          )}
        </Container>
      </Spin>
    </Wrapper>
  )
}

export { SupplierDocumentation }
