import { api } from '@api'
import { Contract, ContractFilters } from '@types'

import { Form, UpdateForm } from '../../validations'

interface GetContractsResponse {
  contracts: {
    contracts: Contract[]
    total: number
  }
}

interface ContractResponse {
  contract: string
}

type Params = {
  filters?: ContractFilters
}

const getContracts = async (params: Params) => {
  const { filters } = params

  const response = await api.get<GetContractsResponse>('/contracts', {
    params: {
      ...filters,
    },
  })

  return response.data.contracts
}

const createContract = async (payload: Form) => {
  const { carrierId, ...restOfValues } = payload
  const response = await api.post<ContractResponse>(`/carriers/${carrierId}/contracts`, {
    contract: restOfValues,
  })

  return response.data
}

const updateContract = async (payload: UpdateForm) => {
  const { id: carrierId, contractId, ...restOfValues } = payload
  const response = await api.put<ContractResponse>(
    `/carriers/${carrierId}/contracts/${contractId}`,
    {
      contract: restOfValues,
    },
  )

  return response.data
}

export const ContractsApi = { getContracts, createContract, updateContract }
