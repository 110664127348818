import React, { useState, Dispatch, SetStateAction } from 'react'

type ContextState = {
  state?: number
  setState: Dispatch<SetStateAction<number | undefined>>
}

const initialState = {
  state: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setState: () => {},
}

export const TicketNotifContext = React.createContext<ContextState>(initialState)

export function TicketNotifProvider({ children }: { children: React.ReactNode }) {
  const [state, setState] = useState<number | undefined>()

  return (
    <TicketNotifContext.Provider value={{ state, setState }}>
      {children}
    </TicketNotifContext.Provider>
  )
}
