import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { LoginButton, LoginTitle, LoginContentContainer } from '../components'

const Login = () => {
  const { t } = useTranslation()

  return (
    <LoginContentContainer>
      <LoginTitle color="gray">{t('login.login')}</LoginTitle>
      <LoginButton type="primary" size="large">
        <Link to="/login/sso">Exiros - ADFS</Link>
      </LoginButton>
      <LoginButton type="primary" size="large">
        <Link to="/login/tradicional">Usuario - Contraseña</Link>
      </LoginButton>
    </LoginContentContainer>
  )
}

const LoginError = () => (
  <LoginContentContainer>
    <LoginTitle color="gray">Login Error.</LoginTitle>
    <LoginTitle color="gray">Hold on and try again.</LoginTitle>
    <LoginButton type="primary" size="large">
      <Link to="/login">Aceptar</Link>
    </LoginButton>
  </LoginContentContainer>
)

export { Login, LoginError }
