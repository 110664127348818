import styled from 'styled-components'
import { Row, Select } from 'antd'

export const StyledRow = styled(Row)`
  padding: 20px 0 20px;
`

export const StyledSelect = styled(Select)`
  width: 100%;
`
