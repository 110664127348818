/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useContext, Dispatch, SetStateAction } from 'react'

import { DepotFilters } from '@types'

type ContextState = {
  filters?: DepotFilters
  setFilters: Dispatch<SetStateAction<DepotFilters>>
}

const initialState = {
  filters: { page: 1, limit: 10 },
  setFilters: () => {},
}

const DepotFiltersContext = React.createContext<ContextState>(initialState)

export function DepotFiltersProvider({ children }: { children: React.ReactNode }) {
  const [filters, setFilters] = useState<DepotFilters>({ page: 1, limit: 10 })

  return (
    <DepotFiltersContext.Provider value={{ filters, setFilters }}>
      {children}
    </DepotFiltersContext.Provider>
  )
}

export function useDepotFilters(): ContextState {
  const context = useContext(DepotFiltersContext)
  if (!context) throw Error('This hook can only be used under the DepotFiltersProvider component')
  return context
}
