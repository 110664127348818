import { api } from '@api'
import { CustomsReadTicket, DeclineTicket, Filters, Ticket } from '@types'

interface TicketResponse {
  tickets: Ticket[]
  total: number
}

interface GetTicketsResponse {
  tickets?: TicketResponse
  ticket?: Ticket
}

type Params = {
  filters?: Filters
}

type PayloadDecline = { id: number; payload: DeclineTicket }

type PayloadChange = { id: number; payload: CustomsReadTicket }

const getTickets = async (params: Params) => {
  const { filters } = params
  const response = await api.get<GetTicketsResponse>('/tickets', {
    params: {
      ...filters,
    },
  })

  return response.data.tickets
}

const getTicketById = async (id?: number) => {
  const response = await api.get<GetTicketsResponse>(`/tickets/${id}`)

  return response.data
}

const acceptTicket = async ({
  id,
  withdrawalDate,
}: {
  id: number
  withdrawalDate: { withdrawalDate: Date }
}) => {
  const response = await api.put<GetTicketsResponse>(`/tickets/${id}/confirm`, {
    ticket: { ...withdrawalDate, timezone: withdrawalDate.withdrawalDate.getTimezoneOffset() },
  })

  return response.data.tickets
}

const declineTicket = async ({ id, payload }: PayloadDecline) => {
  const response = await api.put<GetTicketsResponse>(`/tickets/${id}/decline`, {
    ticket: { ...payload },
  })

  return response.data.tickets
}

const changeTicket = async ({ id, payload }: PayloadChange) => {
  const response = await api.put<GetTicketsResponse>(`/tickets/${id}`, {
    ticket: { ...payload },
  })

  return response.data.ticket
}

const manualAssign = async ({
  id,
  carrierId,
  reason,
}: {
  id: number
  carrierId: number
  reason: string
}) => {
  const response = await api.put<GetTicketsResponse>(`/tickets/${id}/manual`, {
    ticket: { carrierId, reason },
  })

  return response.data.tickets
}

export const TicketsApi = {
  getTickets,
  getTicketById,
  acceptTicket,
  declineTicket,
  changeTicket,
  manualAssign,
}
