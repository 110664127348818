import { api } from '@api'
import { ConfigurationProperties } from '@types'

interface GetConfigResponse {
  configurationProperties: ConfigurationProperties[]
}

const getConfigurationProperties = async () => {
  const response = await api.get<GetConfigResponse>('/configurations')

  return response.data.configurationProperties
}

const updateConfigurationProperties = async ({ id, value }: { id: number; value: number }) => {
  const response = await api.put<GetConfigResponse>(`/configurations/${id}`, {
    settings: { value },
  })

  return response.data.configurationProperties
}

export const ConfigurationPropertiesApi = {
  getConfigurationProperties,
  updateConfigurationProperties,
}
