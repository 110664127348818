import { api } from '@api'
import { Depot, DepotFilters } from '@types'

interface GetDepotsResponse {
  depots: Depot[]
}
interface GetAllDepotsResponse {
  depots: Depot[]
}

interface FilteredDepotsResponse {
  depots: Depot[]
  total: number
}

interface GetFilteredDepotsResponse {
  depots: FilteredDepotsResponse
}

interface createDepotResponse {
  depot: Depot
}

type Params = {
  filters?: DepotFilters
}

export interface Form {
  supplierId: number
  city: string
  address: string
  location: { type: string; coordinates: [number, number] }
  description: string
  borderCrossingId: number
}

const getDepots = async () => {
  const response = await api.get<GetDepotsResponse>('/depots')

  return response.data.depots
}

const getAllDepots = async () => {
  const response = await api.get<GetAllDepotsResponse>('/depots/all')

  return response.data.depots
}

const getDepotsWithFilters = async (params: Params) => {
  const { filters } = params
  const response = await api.get<GetFilteredDepotsResponse>('/depots/filters', {
    params: {
      ...filters,
    },
  })

  return response.data.depots
}

const createDepot = async (payload: Form) => {
  const response = await api.post<createDepotResponse>('/depots', { depot: payload })

  return response.data.depot
}

export const DepotsApi = { getDepots, getAllDepots, getDepotsWithFilters, createDepot }
