import { User } from '@types'
import { AuthUtils } from '@utils'

import { api, setAuthHeader } from './axios'

interface Payload {
  userName: string
  password: string
}

interface Response {
  user: User
  token: string
}
interface RecoverPassResponse {
  username: User
}

interface RecoverPasswordPayload {
  username: string
}
interface ChangePasswordPayload {
  password: string
  token: string
}

interface GetUserResponse {
  user: User
}

const login = async (payload: Payload) => {
  const body = { ...payload, expiresIn: AuthUtils.getExpiresInMiliseconds() }
  const response = await api.post<Response>('/auth/login', body)

  return response.data
}

const loginWithToken = async () => {
  const body = { expiresIn: AuthUtils.getExpiresInMiliseconds() }
  const response = await api.post<Response>('/auth/login/token', body)

  return response.data
}

const recoverPass = async (payload: RecoverPasswordPayload) => {
  const response = await api.post<RecoverPassResponse>('/auth/password', payload)

  return response.data
}

const changePassword = ({ password, token }: ChangePasswordPayload) => {
  setAuthHeader(token)
  return api.put('/auth/password', { password })
}

const saveFirebaseToken = async (id: number, token: string) => {
  const response = await api.put<GetUserResponse>(`/users/${id}/firebaseToken`, { token })

  return response.data
}

const removeFirebaseToken = async (id: number) => {
  const response = await api.put<GetUserResponse>(`/users/${id}/firebaseToken/remove`)

  return response
}

export const AuthApi = {
  login,
  loginWithToken,
  recoverPass,
  changePassword,
  saveFirebaseToken,
  removeFirebaseToken,
}
