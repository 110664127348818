/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable no-param-reassign */
import { Col, DatePicker, Input, Button, Spin, InputNumber, Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { SelectValue } from 'antd/lib/select'
import moment from 'moment'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { ErrorMessage } from '@hookform/error-message'

import { TitleAndDivider } from '@components'
import { usePurchases, useTickets, useCancelTicket } from '@hooks'
import { Invoice, StatusTicketEnum, Ticket } from '@types'
import { ArrayUtils, StringUtils } from '@utils'

import { Form, UpdateForm, validationSchema, validationUpdateSchema } from './validations'
import {
  Wrapper,
  UploadButton,
  Container,
  StyledRow,
  StyledSelect,
  StyledText,
  UploadSingleFile,
  StyledErrorMessage,
  StyledRequired,
} from './components'
import { useCreateTruckDocumentation, useDestinations, useUpdateTruckDocumentation } from './hooks'

interface Props {
  location: { state?: { ticket: Ticket } }
}

const AddTruckDocumentation: React.FC<Props> = ({ location }) => {
  const { t } = useTranslation()
  const { createTruckDocumentation, isLoading } = useCreateTruckDocumentation()
  const { updateTruckDocumentation } = useUpdateTruckDocumentation()
  const { destinationsOptions, isLoading: isDestinationsLoading } = useDestinations()
  const dateFormat = 'MMM DD, hh:mm a'
  const { tickets, isLoading: isTicketsLoading } = useTickets({
    limit: 30000,
    statusName: [StatusTicketEnum.CONFIRMED],
  })

  const history = useHistory()

  const {
    materialOptions,
    materialsLoading,
    getPurchasesByMaterial,
    documentNumberIdsLoading,
    documentIdsOptions,
  } = usePurchases()

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<Form>({
    resolver: yupResolver(location.state ? validationUpdateSchema : validationSchema),
  })

  const { cancelTicket } = useCancelTicket(location.state?.ticket.id ?? 0)

  useEffect(() => {
    if (!location.state?.ticket) {
      reset({
        invoiceDate: undefined,
        invoiceNumber: undefined,
        destinationId: undefined,
        invoiceAmount: undefined,
        grossWeight: undefined,
        tareWeight: undefined,
        materialId: undefined,
        materialDescription: undefined,
        purchaseOrderId: undefined,
      })
    }

    window.addEventListener('popstate', () => {
      if (history.location.pathname === '/dashboard/suppliers/truck-documentation')
        history.push('/dashboard/suppliers')
    })
  }, [history, location.state, reset])

  const handleForm = (form: Form) => {
    createTruckDocumentation(form)
  }
  const handleUpdateForm = (form: UpdateForm) => {
    updateTruckDocumentation({
      ...form,
      photos: form.photos ?? [],
      truckDocumentationId: location.state?.ticket.truckDocumentation?.id!,
    })
  }

  const isDisabled = (ticket: Ticket | undefined) => {
    if (ticket) {
      return (
        ticket.status.name === StatusTicketEnum.TA_INVOICED ||
        ticket.status.name === StatusTicketEnum.MX_TRANSIT ||
        ticket.status.name === StatusTicketEnum.DELIVERED ||
        ticket.status.name === StatusTicketEnum.CANCELED
      )
    }
    return false
  }

  const confirmedTickets = tickets?.tickets
    .reduce(
      (
        result: {
          value: number
          label: string
          withdrawalDate: string
        }[],
        ticket,
      ) => {
        if (ticket.status.name === StatusTicketEnum.CONFIRMED)
          result = [
            ...result,
            {
              value: ticket.id,
              label: `${ticket.id} - ${ticket.carrier.businessName} - ${moment(
                ticket.withdrawalDate,
              ).format('ll hh:mm a')}`,
              withdrawalDate: ticket.withdrawalDate,
            },
          ]

        return result
      },
      [],
    )
    .sort((a, b) => {
      return new Date(a.withdrawalDate).getTime() - new Date(b.withdrawalDate).getTime()
    })

  const handleMaterialChange = (value: SelectValue) => {
    setValue('purchaseOrderId', '')
    const material = materialOptions!.find(option => option.value === value)
    setValue('materialDescription', material!.label)
    getPurchasesByMaterial({ materialId: value as string })
  }

  useEffect(() => {
    if (location.state?.ticket.truckDocumentation?.materialId)
      getPurchasesByMaterial({
        materialId: location.state?.ticket.truckDocumentation?.materialId,
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCancelTicket = (id: number) => {
    cancelTicket(id)

    history.push('/dashboard/suppliers')
  }

  const columns = [
    {
      title: t('admin_router.admin_dashboard.columns.trip_id'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('admin_router.admin_dashboard.columns.date'),
      dataIndex: 'createAt',
      key: 'id',
      render: (date: Date) => moment(date).format(dateFormat),
    },
    {
      title: t('admin_router.admin_dashboard.columns.supplier'),
      dataIndex: 'supplier',
      key: 'businessName',
      render: (supplier?: { id: number; businessName: string }) =>
        supplier && <>{supplier.businessName}</>,
    },
    {
      title: t('admin_router.admin_dashboard.columns.invoice_number'),
      render: (showTicket: Ticket) => {
        if (showTicket && showTicket.truckDocumentation)
          return <>{showTicket.truckDocumentation.invoiceNumber}</>

        if (showTicket && showTicket.railcarDocumentation)
          return <>{showTicket.railcarDocumentation.invoiceNumber}</>

        return <>No data</>
      },
    },
    {
      title: t('admin_router.admin_dashboard.columns.ta_inv'),
      dataIndex: 'invoice',
      key: 'name',
      render: (invoice?: Invoice) => invoice && <>{invoice.number}</>,
    },
    {
      title: t('admin_router.admin_dashboard.columns.carrier'),
      dataIndex: 'carrier',
      key: 'id',
      render: (carrier?: { id: number; businessName: string }) =>
        carrier && <>{carrier.businessName}</>,
    },
    {
      title: t('admin_router.admin_dashboard.columns.status'),
      dataIndex: 'status',
      key: 'id',
      render: (status?: { name: string }) => <>{status?.name}</>,
    },
  ]

  return (
    <Container style={{ marginTop: 'unset', padding: '2%' }}>
      <Table columns={columns} dataSource={[location.state?.ticket!]} pagination={false} />
      <Wrapper>
        <Spin spinning={isLoading || isTicketsLoading}>
          <div>
            <TitleAndDivider
              title={t('ta_invoice.add_truck_documentation.title_and_divider.new_truck.title')}
            />
            <StyledRow>
              <Col sm={{ span: 16 }} md={{ span: 8 }}>
                <StyledText>
                  {t('ta_invoice.add_truck_documentation.title_and_divider.new_truck.carrier')}:
                  <StyledRequired> *</StyledRequired>
                </StyledText>
              </Col>
              <Col sm={{ span: 8 }} md={{ span: 16 }}>
                <Controller
                  control={control}
                  name="ticketId"
                  render={({ field }) => (
                    <StyledSelect
                      placeholder={t('globals.placeholder_select')}
                      options={confirmedTickets}
                      {...field}
                      disabled={!!location.state}
                      value={location.state && location.state.ticket.carrier.businessName}
                    />
                  )}
                />
                <Col>
                  <StyledErrorMessage>
                    <ErrorMessage errors={errors} name="ticketId" />
                  </StyledErrorMessage>
                </Col>
              </Col>
            </StyledRow>
            <StyledRow>
              <Col sm={{ span: 16 }} md={{ span: 8 }}>
                <StyledText>
                  {t('ta_invoice.add_truck_documentation.title_and_divider.new_truck.depot')}:
                  <StyledRequired> *</StyledRequired>
                </StyledText>
              </Col>
              <Col sm={{ span: 8 }} md={{ span: 16 }}>
                <Controller
                  control={control}
                  name="destinationId"
                  defaultValue={location.state?.ticket.destination.id}
                  render={({ field }) => (
                    <StyledSelect
                      placeholder={t('globals.placeholder_select')}
                      options={destinationsOptions}
                      loading={isDestinationsLoading}
                      disabled={!!location.state}
                      {...field}
                      value={field.value}
                    />
                  )}
                />
                <Col>
                  <StyledErrorMessage>
                    <ErrorMessage errors={errors} name="destinationId" />
                  </StyledErrorMessage>
                </Col>
              </Col>
            </StyledRow>
          </div>
          <Container>
            <TitleAndDivider
              title={t(
                'ta_invoice.add_truck_documentation.title_and_divider.supplier_documentation.title',
              )}
            />
            <StyledRow>
              <Col span={8}>
                <StyledText>
                  {t(
                    'ta_invoice.add_truck_documentation.title_and_divider.supplier_documentation.invoice_date',
                  )}
                  :<StyledRequired> *</StyledRequired>
                </StyledText>
              </Col>
              <Col span={16}>
                <Controller
                  name="invoiceDate"
                  control={control}
                  defaultValue={moment(
                    location.state?.ticket.truckDocumentation?.invoiceDate,
                  ).toDate()}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      allowClear={false}
                      value={field.value ? moment(field.value).utc() : undefined}
                      disabled={isDisabled(location.state ? location.state.ticket : undefined)}
                      picker="date"
                      format="DD/MM/YYYY"
                    />
                  )}
                />
                <Col>
                  <StyledErrorMessage>
                    <ErrorMessage errors={errors} name="invoiceDate" />
                  </StyledErrorMessage>
                </Col>
              </Col>
            </StyledRow>
            <StyledRow>
              <Col span={8}>
                <StyledText>
                  {t(
                    'ta_invoice.add_truck_documentation.title_and_divider.supplier_documentation.invoice_number',
                  )}
                  :<StyledRequired> *</StyledRequired>
                </StyledText>
              </Col>
              <Col span={16}>
                <Controller
                  control={control}
                  name="invoiceNumber"
                  rules={{ required: true }}
                  defaultValue={
                    location?.state?.ticket.truckDocumentation?.invoiceNumber &&
                    location.state.ticket.truckDocumentation?.invoiceNumber
                  }
                  render={({ field }) => (
                    <Input
                      {...field}
                      value={field.value ?? undefined}
                      disabled={isDisabled(location.state ? location.state.ticket : undefined)}
                    />
                  )}
                />
                <Col>
                  <StyledErrorMessage>
                    <ErrorMessage errors={errors} name="invoiceNumber" />
                  </StyledErrorMessage>
                </Col>
              </Col>
            </StyledRow>
            <StyledRow>
              <Col span={8}>
                <StyledText>
                  {t(
                    'ta_invoice.add_truck_documentation.title_and_divider.supplier_documentation.invoice_amount',
                  )}
                  :<StyledRequired> *</StyledRequired>
                </StyledText>
              </Col>
              <Col span={16}>
                <Controller
                  control={control}
                  name="invoiceAmount"
                  defaultValue={
                    location?.state?.ticket.truckDocumentation?.invoiceAmount &&
                    location.state.ticket.truckDocumentation?.invoiceAmount
                  }
                  render={({ field }) => (
                    <InputNumber
                      style={{ width: '150px' }}
                      {...field}
                      value={field.value ?? undefined}
                      disabled={isDisabled(location.state ? location.state.ticket : undefined)}
                      step="0.01"
                      precision={2}
                      formatter={value => {
                        if (!value) return '$ '
                        const decimals =
                          value?.toString().search(/\./) >= 0
                            ? value?.toString().split('.').reverse()[0].slice(0, 2)
                            : null
                        const number = `$ ${Math.floor(value)}`.replace(/\B(?=(\d{3})+(?!.))/g, ',')
                        if (decimals) return `${number}.${decimals}`
                        return `${number}`
                      }}
                    />
                  )}
                />
                <Col>
                  <StyledErrorMessage>
                    <ErrorMessage errors={errors} name="invoiceAmount" />
                  </StyledErrorMessage>
                </Col>
              </Col>
            </StyledRow>
            <StyledRow>
              <Col span={8}>
                <StyledText>
                  {t(
                    'ta_invoice.add_truck_documentation.title_and_divider.supplier_documentation.gross_weight',
                  )}
                  : <StyledRequired> *</StyledRequired>
                </StyledText>
              </Col>
              <Col span={16}>
                <Controller
                  control={control}
                  name="grossWeight"
                  defaultValue={
                    location?.state?.ticket.truckDocumentation?.grossWeight &&
                    location.state.ticket.truckDocumentation?.grossWeight
                  }
                  render={({ field }) => (
                    <InputNumber
                      style={{ width: '150px' }}
                      {...field}
                      value={field.value ?? undefined}
                      disabled={isDisabled(location.state ? location.state.ticket : undefined)}
                      step="0.01"
                      precision={2}
                      formatter={value => {
                        if (!value) return ' '
                        const decimals =
                          value?.toString().search(/\./) >= 0
                            ? value?.toString().split('.').reverse()[0].slice(0, 2)
                            : null
                        const number = ` ${Math.floor(value)}`.replace(/\B(?=(\d{3})+(?!.))/g, ',')
                        if (decimals) return `${number}.${decimals}`
                        return `${number}`
                      }}
                    />
                  )}
                />
                <Col>
                  <StyledErrorMessage>
                    <ErrorMessage errors={errors} name="grossWeight" />
                  </StyledErrorMessage>
                </Col>
              </Col>
            </StyledRow>
            <StyledRow>
              <Col span={8}>
                <StyledText>
                  {t(
                    'ta_invoice.add_truck_documentation.title_and_divider.supplier_documentation.tare_weight',
                  )}
                  : <StyledRequired> *</StyledRequired>
                </StyledText>
              </Col>
              <Col span={16}>
                <Controller
                  control={control}
                  name="tareWeight"
                  defaultValue={
                    location?.state?.ticket.truckDocumentation?.tareWeight &&
                    location.state.ticket.truckDocumentation?.tareWeight
                  }
                  render={({ field }) => (
                    <InputNumber
                      style={{ width: '150px' }}
                      {...field}
                      value={field.value ?? undefined}
                      disabled={isDisabled(location.state ? location.state.ticket : undefined)}
                      step="0.01"
                      precision={2}
                      formatter={value => {
                        if (!value) return ' '
                        const decimals =
                          value?.toString().search(/\./) >= 0
                            ? value?.toString().split('.').reverse()[0].slice(0, 2)
                            : null
                        const number = ` ${Math.floor(value)}`.replace(/\B(?=(\d{3})+(?!.))/g, ',')
                        if (decimals) return `${number}.${decimals}`
                        return `${number}`
                      }}
                    />
                  )}
                />
                <Col>
                  <StyledErrorMessage>
                    <ErrorMessage errors={errors} name="tareWeight" />
                  </StyledErrorMessage>
                </Col>
              </Col>
            </StyledRow>
            <StyledRow>
              <Col span={8}>
                <StyledText>
                  {t(
                    'ta_invoice.add_truck_documentation.title_and_divider.supplier_documentation.material',
                  )}
                  :<StyledRequired> *</StyledRequired>
                </StyledText>
              </Col>
              <Col sm={{ span: 8 }} md={{ span: 16 }}>
                <Controller
                  control={control}
                  name="materialId"
                  defaultValue={
                    location?.state?.ticket.truckDocumentation?.materialId &&
                    location.state.ticket.truckDocumentation?.materialId.toString()
                  }
                  render={({ field }) => (
                    <>
                      <StyledSelect
                        placeholder={t('globals.placeholder_select_material')}
                        options={materialOptions}
                        loading={materialsLoading}
                        onSelect={handleMaterialChange}
                        disabled={isDisabled(location.state ? location.state.ticket : undefined)}
                        {...field}
                        value={field.value}
                      />
                      <Col>
                        <StyledErrorMessage>
                          <ErrorMessage errors={errors} name="materialId" />
                        </StyledErrorMessage>
                      </Col>
                    </>
                  )}
                />
                <Controller
                  control={control}
                  name="materialDescription"
                  defaultValue={
                    location?.state?.ticket.truckDocumentation?.materialDescription &&
                    location.state.ticket.truckDocumentation?.materialDescription.toString()
                  }
                  render={({ field }) => (
                    <Input {...field} value={field.value ?? undefined} hidden />
                  )}
                />
              </Col>
            </StyledRow>
            <StyledRow>
              <Col span={8}>
                <StyledText>
                  {t(
                    'ta_invoice.add_truck_documentation.title_and_divider.supplier_documentation.PO',
                  )}
                  :
                </StyledText>
              </Col>
              <Col sm={{ span: 8 }} md={{ span: 16 }}>
                <Controller
                  control={control}
                  name="purchaseOrderId"
                  defaultValue={
                    location?.state?.ticket.truckDocumentation?.purchaseOrderId &&
                    location.state.ticket.truckDocumentation?.purchaseOrderId
                  }
                  render={({ field }) => (
                    <StyledSelect
                      placeholder={t('globals.placeholder_select_po')}
                      options={documentIdsOptions}
                      loading={documentNumberIdsLoading}
                      disabled={isDisabled(location.state ? location.state.ticket : undefined)}
                      {...field}
                      value={field.value}
                    />
                  )}
                />
              </Col>
            </StyledRow>
            <StyledRow>
              <Col span={8}>
                <StyledText>
                  {t(
                    'ta_invoice.add_truck_documentation.title_and_divider.supplier_documentation.invoice_sell',
                  )}
                  :<StyledRequired> *</StyledRequired>
                </StyledText>
              </Col>
              <Col span={16}>
                <Controller
                  control={control}
                  name="salesInvoice"
                  render={({ field }) => (
                    <UploadSingleFile
                      {...field}
                      fileData={ArrayUtils.reverse(
                        location.state?.ticket.truckDocumentation?.salesInvoice,
                      )?.map(salesInvoice => ({
                        key: salesInvoice,
                        name: StringUtils.formatFilename(
                          location.state?.ticket.id ?? 0,
                          salesInvoice,
                          'InvoiceSell',
                          'pdf',
                        ),
                      }))}
                    />
                  )}
                />
                <Col>
                  <StyledErrorMessage>
                    <ErrorMessage errors={errors} name="salesInvoice" />
                  </StyledErrorMessage>
                </Col>
              </Col>
            </StyledRow>
            <StyledRow>
              <Col span={8}>
                <StyledText>
                  {t(
                    'ta_invoice.add_truck_documentation.title_and_divider.supplier_documentation.weight_certificate',
                  )}
                  :<StyledRequired> *</StyledRequired>
                </StyledText>
              </Col>
              <Col span={16}>
                <Controller
                  control={control}
                  name="weightCertificate"
                  render={({ field }) => (
                    <UploadSingleFile
                      {...field}
                      fileData={ArrayUtils.reverse(
                        location.state?.ticket.truckDocumentation?.weightCertificate,
                      )?.map(weightCertificate => ({
                        key: weightCertificate,
                        name: StringUtils.formatFilename(
                          location.state?.ticket.id ?? 0,
                          weightCertificate,
                          'WeightCertificate',
                          'pdf',
                        ),
                      }))}
                    />
                  )}
                />
                <Col>
                  <StyledErrorMessage>
                    <ErrorMessage errors={errors} name="weightCertificate" />
                  </StyledErrorMessage>
                </Col>
              </Col>
            </StyledRow>
            <StyledRow>
              <Col span={8}>
                <StyledText>
                  {' '}
                  B/L:<StyledRequired> *</StyledRequired>
                </StyledText>
              </Col>
              <Col span={16}>
                <Controller
                  control={control}
                  name="BL"
                  render={({ field }) => (
                    <UploadSingleFile
                      {...field}
                      fileData={ArrayUtils.reverse(
                        location.state?.ticket.truckDocumentation?.bl,
                      )?.map(document => ({
                        key: document,
                        name: StringUtils.formatFilename(
                          location.state?.ticket.id ?? 0,
                          document,
                          'BL',
                          'pdf',
                        ),
                      }))}
                    />
                  )}
                />
                <Col>
                  <StyledErrorMessage>
                    <ErrorMessage errors={errors} name="BL" />
                  </StyledErrorMessage>
                </Col>
              </Col>
            </StyledRow>
            <StyledRow>
              <Col span={8}>
                <StyledText>
                  No Radioact. Cert.:<StyledRequired> *</StyledRequired>
                </StyledText>
              </Col>
              <Col span={16}>
                <Controller
                  control={control}
                  name="radioactiveCertificate"
                  render={({ field }) => (
                    <UploadSingleFile
                      {...field}
                      fileData={ArrayUtils.reverse(
                        location.state?.ticket.truckDocumentation?.radioactiveCertificate,
                      )?.map(radioactiveCertificate => ({
                        key: radioactiveCertificate,
                        name: StringUtils.formatFilename(
                          location.state?.ticket.id ?? 0,
                          radioactiveCertificate,
                          'RadioactiveCertificate',
                          'pdf',
                        ),
                      }))}
                    />
                  )}
                />
                <Col>
                  <StyledErrorMessage>
                    <ErrorMessage errors={errors} name="radioactiveCertificate" />
                  </StyledErrorMessage>
                </Col>
              </Col>
            </StyledRow>
            <StyledRow>
              <Col span={8}>
                <StyledText>
                  {t(
                    'ta_invoice.add_truck_documentation.title_and_divider.supplier_documentation.photos',
                  )}
                  :<StyledRequired> *</StyledRequired>
                </StyledText>
              </Col>
              <Col span={16}>
                <Controller
                  control={control}
                  name="photos"
                  render={({ field }) => (
                    <UploadButton
                      accept="image/*"
                      {...field}
                      fileData={ArrayUtils.reverse(
                        location.state?.ticket.truckDocumentation?.photos,
                      )?.map(photo => ({
                        key: `${photo}`,
                        name: StringUtils.formatFilename(
                          location.state?.ticket.id ?? 0,
                          photo,
                          'photo',
                          'png',
                        ),
                      }))}
                    />
                  )}
                />
                <Col>
                  <StyledErrorMessage>
                    <ErrorMessage errors={errors} name="photos" />
                  </StyledErrorMessage>
                </Col>
              </Col>
            </StyledRow>
            <StyledRow>
              <Col span={8}>
                <StyledText>
                  (<StyledRequired>* </StyledRequired>required)
                </StyledText>
              </Col>
            </StyledRow>
            <Button
              style={{ marginRight: '40px' }}
              onClick={location.state ? handleSubmit(handleUpdateForm) : handleSubmit(handleForm)}
              disabled={isDisabled(location.state ? location.state.ticket : undefined)}
            >
              {location.state
                ? t('ta_invoice.add_truck_documentation.buttons.update')
                : t('ta_invoice.add_truck_documentation.buttons.upload')}
            </Button>

            <Button
              style={{ marginRight: '40px' }}
              onClick={() => handleCancelTicket(location.state?.ticket.id!)}
              disabled // al momento de hacer esto el supplier no puede cancelar trucks, se deja preparado por si algún día se necesita
            >
              {t('ta_invoice.add_truck_documentation.buttons.cancel')}
            </Button>
          </Container>
        </Spin>
      </Wrapper>
    </Container>
  )
}

export { AddTruckDocumentation }
