import { Table, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'

import { Container } from './components'
import { useCountTickesByCarrier } from './hooks'

const ServiceLevel = () => {
  const { t } = useTranslation()
  const { carriers, isLoading } = useCountTickesByCarrier()

  const renderInfo = ({ total, confirmed }: { total: number; confirmed: number }) => (
    <div>
      <Tooltip title={t('admin_router.filter_options.status.confirmed')}>
        <span>{`${total !== 0 ? Math.round((confirmed / total) * 100) : 0}% `}</span>
      </Tooltip>
      <Tooltip title="Total">
        <span>{`(${total})`}</span>
      </Tooltip>
    </div>
  )

  const columns = [
    {
      title: 'Carrier',
      dataIndex: 'businessName',
      key: 'businessName',
    },
    {
      title: '30 d',
      dataIndex: 'lastMonth',
      key: 'lastMonth',
      render: renderInfo,
    },
    {
      title: '7 d',
      dataIndex: 'lastWeek',
      key: 'lastWeek',
      render: renderInfo,
    },
  ]

  return (
    <Container>
      <Table
        columns={columns}
        dataSource={carriers}
        loading={isLoading}
        rowKey="id"
        pagination={false}
      />
    </Container>
  )
}

export { ServiceLevel }
