import { notification } from 'antd'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'

import { TruckDocumentationApi } from './api'

const useCreateTruckDocumentation = () => {
  const history = useHistory()

  const { t } = useTranslation()

  const onError = (error: AxiosError) => {
    const message = error.response?.data.error.message.includes(
      'duplicate key value violates unique constraint',
    )
      ? t('ta_invoice.add_truck_documentation.errors.duplicate_invoice_number')
      : t('ta_invoice.add_truck_documentation.errors.generic')
    notification.error({ message })
  }

  const onSuccess = () => {
    notification.success({
      message: 'Truck successfully loaded',
    })
    history.push('/dashboard/suppliers')
  }

  const { mutate, isLoading } = useMutation(TruckDocumentationApi.createTruckDocumentation, {
    onSuccess,
    onError,
  })

  return {
    createTruckDocumentation: mutate,
    isLoading,
  }
}

export { useCreateTruckDocumentation }
