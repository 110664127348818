import { Row, Select, Typography } from 'antd'
import styled from 'styled-components'

const StyledRow = styled(Row)`
  margin: 15px 0 15px;
  .ant-input-number-handler-wrap {
    display: none;
  }
`
const StyledSelect = styled(Select)`
  width: 100%;
`

const StyledText = styled(Typography)`
  font-weight: bold;
`

export { StyledRow, StyledSelect, StyledText }
