/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useContext, Dispatch, SetStateAction } from 'react'

import { ContractFilters } from '@types'

type ContextState = {
  filters?: ContractFilters
  setFilters: Dispatch<SetStateAction<ContractFilters>>
}

const initialState = {
  filters: { page: 1, limit: 5 },
  setFilters: () => {},
}

const ContractFiltersContext = React.createContext<ContextState>(initialState)

export function ContractFiltersProvider({ children }: { children: React.ReactNode }) {
  const [filters, setFilters] = useState<ContractFilters>({ page: 1, limit: 5 })

  return (
    <ContractFiltersContext.Provider value={{ filters, setFilters }}>
      {children}
    </ContractFiltersContext.Provider>
  )
}

export function useContractFilters(): ContextState {
  const context = useContext(ContractFiltersContext)
  if (!context)
    throw Error('This hook can only be used under the ContractFiltersProvider component')
  return context
}
