import { useHistory, useParams } from 'react-router-dom'
import { useEffect } from 'react'

import { useTicketsById } from '@hooks'
import { useUser } from '@contexts'
import { ROUTE_KEYS } from '@constants'
import { RoleEnum, StatusTicketEnum, CarrierModeEnum } from '@types'

import { LoadingSpinner } from '../../../../components/LoadingSpinner'
import { RailcarDocumentation } from '../../RailcarDocumentation'
import { TruckDocumentation } from '../../TruckDocumentation'

const { CUSTOMS } = RoleEnum

const allowedRoles = [CUSTOMS]
const invalidStatuses = [
  StatusTicketEnum.PENDING,
  StatusTicketEnum.CONFIRMED,
  StatusTicketEnum.CANCELED,
  StatusTicketEnum.SUPPLIER_INVOICED,
]

const PreDoc = () => {
  const { id } = useParams<{ id: string }>()
  const { ticketById, isTicketByIdLoading } = useTicketsById(Number(id))
  const history = useHistory()
  const { state: user } = useUser()

  useEffect(() => {
    if (
      !(id && user && allowedRoles.includes(user.roles[0].name)) ||
      (!isTicketByIdLoading && !ticketById?.ticket)
    ) {
      history.push(user ? ROUTE_KEYS[user.roles[0].name].HOME : ROUTE_KEYS.LOGIN)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketById, isTicketByIdLoading, id, user, history])

  if (isTicketByIdLoading) return <LoadingSpinner />

  // Check que el customs agents tenga asignado el viaje
  if (
    ticketById?.ticket &&
    user?.userDetail &&
    ticketById.ticket.invoice?.borderCrossing.customsAgent.id !== user.userDetail.customsAgent?.id
  )
    history.push(user ? ROUTE_KEYS[user.roles[0].name].HOME : ROUTE_KEYS.LOGIN)

  if (ticketById?.ticket && invalidStatuses.includes(ticketById.ticket.status.name))
    history.push(user ? ROUTE_KEYS[user.roles[0].name].HOME : ROUTE_KEYS.LOGIN)

  if (ticketById?.ticket && !invalidStatuses.includes(ticketById.ticket.status.name)) {
    // console.log(`carier.mode ${ticketById.ticket.carrier.mode}`)
    return ticketById.ticket.carrier.mode === CarrierModeEnum.RAILCAR ? (
      <RailcarDocumentation ticket={ticketById.ticket} />
    ) : (
      <TruckDocumentation ticket={ticketById.ticket} />
    )
  }
  return null
}

export default PreDoc
