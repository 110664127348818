import { useEffect } from 'react'

import { setAuthHeader } from '@api'
import { LOCAL_STORAGE_KEYS } from '@constants'

const SetToken = () => {
  const location = new URLSearchParams(window.location.search)
  useEffect(() => {
    const token = location.get(LOCAL_STORAGE_KEYS.TOKEN)
    if (token) {
      window.localStorage.clear()
      window.localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, token)
      setAuthHeader(token)
      window.location.replace('/')
    } else if (window.localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN)) {
      setAuthHeader(window.localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN) as string)
      window.location.replace('/')
    } else {
      window.location.replace('login')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return <></>
}

export { SetToken }
