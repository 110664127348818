import styled from 'styled-components'
import { Button, Layout, Col, Checkbox } from 'antd'

const StyledHeader = styled(Layout.Header)`
  padding: 0 20px 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${props => props.theme.lightGray};
`

const LogoContainerMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
`

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const SearchContainer = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
  & > * {
    font-size: 14px;
    margin-bottom: 8px;
  }

  @media ${props => props.theme.xs} {
    width: 70%;
    & > * {
      font-size: 13px;
    }
  }

  @media ${props => props.theme.sm} {
    width: 60%;
  }
`

const StyledSider = styled(Layout.Sider)`
  background: white;
  padding: 20px 20px 0 10px;
  @media (max-width: 992px) {
    padding: 0;
    margin: 0;
    position: fixed;
    z-index: 10;
    max-width: 100% !important;
    width: 100% !important;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 75px;
    & ul {
      border: 0;
      display: flex;
    }
  }
`

const ContainerButtons = styled.div`
  & > button {
    margin-top: 15px;
  }
  & button:last-child {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    & > span {
      margin-left: 8px;
    }
  }
  & button:nth-child(2) {
    background-color: transparent;
    color: ${({ theme }) => theme.gray};
  }
  margin-bottom: 20%;
`

const ContainerCheckbox = styled(Col)`
  & > label {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 4px 0 4px;
    & span:last-child {
      padding-left: 0;
      flex: 1;
    }
  }
`
const StyledCheckboxGroup = styled(Checkbox.Group)`
  width: 100%;
  & > p {
    margin-bottom: 5px;
    margin-top: 15px;
    font-weight: bold;
  }
`

interface ButtonProps {
  svgColor: string
}
const StyledButton = styled(Button)<ButtonProps>`
  & > span > svg {
    color: ${({ svgColor }) => svgColor};
  }
`

const StyledContainer = styled(Layout.Content)`
  background-color: white;
  @media (max-width: 768px) {
    background: ${props => props.theme.lightGray};
    margin-bottom: 75px;
  }

  @media (max-width: 992px) {
    margin-bottom: 75px;
  }
`
const ContainerTable = styled.div`
  padding: 2%;
`
interface UserColorProps {
  role: string | undefined
}

const RoleUserColor: { [key: string]: string } = {
  TA: '#3C5390',
  TX: '#CB6C0D',
  ADMIN: '#7A7A7A',
  ADMINRO: '#ffaf05',
  CARRIER: '#E04040',
  SUPPLIER: '#6DC363',
  CUSTOMS: '#EAD645',
}

const UserColor = styled.div<UserColorProps>`
  width: 100%;
  height: 3px;
  background-color: ${props => {
    return props.role ? RoleUserColor[props.role] : 'transparent'
  }};
`

const LogoutContainer = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 2em;
  & > svg {
    width: 75%;
    height: 75%;
  }
  & > svg:hover {
    -webkit-filter: drop-shadow(3px 2px 1px rgba(0, 0, 0, 0.5));
    filter: drop-shadow(3px 2px 1px rgba(0, 0, 0, 0.5));
  }

  @media (max-width: 768px) {
    & > svg {
      margin-top: 1em;
      width: 70%;
      height: 70%;
    }
  }
`

const LoginInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 1.2em;
  padding-top: 1.2em;
  color: #939393;
  font-family: Roboto, Arial, Helvetica;
  font-style: normal;
  line-height: 16px;
  & > h4 {
    font-family: inherit;
    color: inherit;
    font-style: inherit;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 0em;
    margin-bottom: 0.35em;
  }

  & > p {
    font-family: inherit;
    color: inherit;
    font-style: inherit;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0em;
  }
`

const UserContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const UserContainerMobile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`

export {
  StyledHeader,
  LogoContainer,
  SearchContainer,
  StyledSider,
  StyledButton,
  ContainerButtons,
  ContainerCheckbox,
  StyledCheckboxGroup,
  ContainerTable,
  StyledContainer,
  UserColor,
  LogoutContainer,
  LoginInfoContainer,
  UserContainer,
  LogoContainerMobile,
  UserContainerMobile,
}
