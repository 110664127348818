import { Button } from 'antd'

import { useDownloadPDF } from '@hooks'

type Props = {
  text: string
  rpaId: number
  filename: string
}

const DownloadPDF: React.FC<Props> = ({ text, rpaId, filename }) => {
  const { downloadZip, isLoading } = useDownloadPDF(filename)

  return (
    <>
      <Button onClick={() => downloadZip({ rpaId })} loading={isLoading}>
        {text}
      </Button>
    </>
  )
}

export { DownloadPDF }
