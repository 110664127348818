import { Form, Input, Spin } from 'antd'
import { LockOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { Redirect, useLocation } from 'react-router-dom'

import { LogoExiros } from '@icons'

import { StyledWrapper, IconWrapper, FormWrapper, StyledButton, StyledRow } from '../components'
import { useChangePassword } from '../hooks'

const ChangePassword = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const token = new URLSearchParams(search).get('token')

  const { changePassword, isLoading } = useChangePassword()

  const handleChangePassword = (values: { password: string }) => {
    const { password } = values
    if (token) {
      changePassword({ password, token })
    }
  }

  return token ? (
    <StyledWrapper>
      <StyledRow align="middle">
        <IconWrapper span={24}>
          <LogoExiros />
        </IconWrapper>
        <FormWrapper span={24}>
          <Spin spinning={isLoading}>
            <Form
              name="recover_pass"
              initialValues={{ remember: true }}
              onFinish={handleChangePassword}
            >
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: t('login.enter_password') },
                  {
                    min: 8,
                    message: t('login.password_min_length'),
                  },
                ]}
              >
                <Input prefix={<LockOutlined />} type="password" placeholder="New password" />
              </Form.Item>

              <Form.Item
                name="passwordConfirm"
                rules={[
                  {
                    required: true,
                    message: t('login.confirm_password'),
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve()
                      }
                      return Promise.reject(new Error(t('login.password_match_error')))
                    },
                  }),
                ]}
              >
                <Input prefix={<LockOutlined />} type="password" placeholder="Confirm password" />
              </Form.Item>
              <Form.Item>
                <StyledButton type="primary" htmlType="submit" size="large">
                  {t('login.change_password')}
                </StyledButton>
              </Form.Item>
            </Form>
          </Spin>
        </FormWrapper>
      </StyledRow>
    </StyledWrapper>
  ) : (
    <Redirect to="/login" />
  )
}

export { ChangePassword }
