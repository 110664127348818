import { notification } from 'antd'
import { useMutation, useQuery } from 'react-query'
import { useState } from 'react'
import moment from 'moment'

import { QUERY_KEYS } from '@api'

import { PurchasesApi } from './api'

type PurchaseOrderResponse = {
  documentNumberId: string
  materialId: string
  materialDesc: string
  availableQuantity: string
  earlyDeliveryDate: string
}

const usePurchases = (supplierId?: number) => {
  const [documentIdsOptions, setDocumentIdsOptions] = useState<{ label: string; value: string }[]>(
    [],
  )

  const onError = () => {
    notification.error({
      message: 'Error retrieving purchases',
    })
  }

  const { data, isLoading } = useQuery(
    [QUERY_KEYS.PURCHASES, { supplierId }],
    () => PurchasesApi.getPurchases({ supplierId }),
    {
      onError,
    },
  )

  const materialOptions = data?.map((po: PurchaseOrderResponse) => {
    return {
      label: po.materialDesc,
      value: po.materialId,
    }
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeSuccess = (response: any) => {
    const options = response?.map((po: PurchaseOrderResponse) => {
      return {
        label: `${+po.documentNumberId} - ${moment(po.earlyDeliveryDate).format(
          'MMM-YY',
        )} (remaining: ${po.availableQuantity} tn)`,
        value: po.documentNumberId,
      }
    })

    setDocumentIdsOptions(options)
  }

  const { mutate: getPurchasesByMaterial, isLoading: documentNumberIdsLoading } = useMutation(
    PurchasesApi.getPurchasesByMaterial,
    {
      onSuccess: onChangeSuccess,
    },
  )

  return {
    materials: data,
    materialsLoading: isLoading,
    materialOptions,
    getPurchasesByMaterial,
    documentNumberIdsLoading,
    documentIdsOptions,
  }
}

export { usePurchases }
