import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Col, Table, Button } from 'antd'
import { SelectValue } from 'antd/lib/select'
import { useState } from 'react'

import { useContractFilters, useUser } from '@contexts'
import { CarrierModeEnum, Contract, ContractFilters, FilterSelectEnum } from '@types'
import { EditIcon } from '@icons'
import { useCarriers } from '@hooks'

import {
  ContainerTable,
  StyledRow,
  StyledSelect,
  CreateContractModal,
  UpdateContractModal,
} from './components'
import { useContracts, useContractPagination } from './hooks'
import { Form, UpdateForm } from './validations'

const Contracts = () => {
  const [contractTo, setContractTo] = useState<UpdateForm>()
  const { t } = useTranslation()
  const { carriersFiltered } = useCarriers({ mode: CarrierModeEnum.TRUCK })
  const { filters, setFilters } = useContractFilters()
  const { state: user } = useUser()
  const {
    contracts,
    isLoading,
    createContract,
    createContractLoading,
    isCreateContractModalOpen,
    setIsCreateContractModalOpen,
    updateContract,
    updateContractLoading,
    isUpdateContractModalOpen,
    setIsUpdateContractModalOpen,
  } = useContracts(filters)
  const pagination = useContractPagination({ filters, setFilters }, contracts?.total)

  const convertToUTC = (date: Date) => new Date(moment.utc(date).format())
  const dateFormat = 'll'

  const createHandler = (form: Form, resetForm: () => void) => {
    createContract({ ...form, from: convertToUTC(form.from), to: convertToUTC(form.to) })
    resetForm()
  }

  const updateHandler = (form: UpdateForm, resetForm: () => void) => {
    updateContract({ ...form, to: convertToUTC(form.to) })
    resetForm()
  }

  const columns = [
    {
      title: t('admin_router.admin_dashboard.contracts.columns.carrier'),
      dataIndex: 'carrier',
      key: 'id',
      render: (carrier?: { id: number; businessName: string }) =>
        carrier && <>{carrier.businessName}</>,
    },
    {
      title: t('admin_router.admin_dashboard.contracts.columns.description'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('admin_router.admin_dashboard.contracts.columns.price'),
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: t('admin_router.admin_dashboard.contracts.columns.start_date'),
      dataIndex: 'from',
      key: 'from',
      render: (date: Date) => moment(date).format(dateFormat),
    },
    {
      title: t('admin_router.admin_dashboard.contracts.columns.end_date'),
      dataIndex: 'to',
      key: 'to',
      render: (date: Date) => moment(date).format(dateFormat),
    },
    {
      title: t('admin_router.admin_dashboard.contracts.columns.actions'),
      render: (contract: Contract) => (
        <Button
          disabled={user?.roles[0].name === 'ADMINRO'}
          onClick={() => {
            setContractTo({
              ...contractTo,
              id: contract.carrier.id,
              contractId: contract.id!,
              from: contract.from!,
              to: contract.to,
              price: contract.price,
              description: contract.description!,
            })
            setIsUpdateContractModalOpen(true)
          }}
        >
          <EditIcon width={21} height={21} />
        </Button>
      ),
    },
  ]

  const orderByOptions = [
    {
      label: `${t('admin_router.filter_options.order_by.ascending')}`,
      value: FilterSelectEnum.ASCENDING,
    },
    {
      label: `${t('admin_router.filter_options.order_by.descending')}`,
      value: FilterSelectEnum.DESCENDING,
    },
  ]

  const contractStatusOptions = [
    {
      label: `${t('admin_router.filter_options.contract_status.active')}`,
      value: FilterSelectEnum.ACTIVE,
    },
    {
      label: `${t('admin_router.filter_options.contract_status.inactive')}`,
      value: FilterSelectEnum.INACTIVE,
    },
  ]

  const handleOrderByFilterChange = (value: SelectValue) => {
    setFilters((prevState: ContractFilters) => ({
      ...prevState,
      page: 1,
      orderBy: value as FilterSelectEnum.ASCENDING | FilterSelectEnum.DESCENDING,
    }))
  }

  const handleCarrierChange = (value: SelectValue) => {
    setFilters((prevState: ContractFilters) => ({
      ...prevState,
      page: 1,
      carrierId: value as number,
    }))
  }

  const handleContractStatusChange = (value: SelectValue) => {
    setFilters((prevState: ContractFilters) => ({
      ...prevState,
      page: 1,
      contractStatus: [...(value as string[])],
    }))
  }

  return (
    <ContainerTable>
      <StyledRow gutter={[20, 20]}>
        <Col span={4}>
          <StyledSelect
            allowClear
            placeholder={t('admin_router.filter_options.order_by.title')}
            options={orderByOptions}
            onChange={handleOrderByFilterChange}
            value={filters?.orderBy}
          />
        </Col>
        <Col span={4}>
          <StyledSelect
            allowClear
            placeholder={t('admin_router.filter_options.by_carrier.title')}
            options={carriersFiltered}
            onChange={handleCarrierChange}
            value={filters?.carrierId}
          />
        </Col>
        <Col span={4}>
          <StyledSelect
            mode="multiple"
            allowClear
            placeholder={t('admin_router.filter_options.contract_status.title')}
            options={contractStatusOptions}
            onChange={handleContractStatusChange}
            value={filters?.contractStatus}
          />
        </Col>
        <Col span={6}>
          <Button
            disabled={user?.roles[0].name === 'ADMINRO'}
            onClick={() => setIsCreateContractModalOpen(true)}
          >
            {t('admin_router.admin_dashboard.contracts.button_create')}
          </Button>
        </Col>
      </StyledRow>
      <Table
        columns={columns}
        dataSource={contracts?.contracts}
        loading={isLoading}
        pagination={pagination}
        rowKey="id"
      />
      <CreateContractModal
        open={isCreateContractModalOpen}
        loading={createContractLoading}
        onClose={() => setIsCreateContractModalOpen(false)}
        onOk={createHandler}
      />
      <UpdateContractModal
        open={isUpdateContractModalOpen}
        loading={updateContractLoading}
        contractId={contractTo?.contractId}
        id={contractTo?.id}
        startDate={contractTo?.from}
        limitDate={contractTo?.to}
        price={contractTo?.price}
        description={contractTo?.description}
        onClose={() => setIsUpdateContractModalOpen(false)}
        onOk={updateHandler}
      />
    </ContainerTable>
  )
}

export { Contracts }
