import React from 'react'
import { Col, DatePicker, InputNumber, Input, Modal, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import moment, { Moment } from 'moment'

import { StyledRow, StyledText } from './components'
import { validationUpdateSchema, UpdateForm } from '../../validations'

type Props = {
  open: boolean
  loading: boolean
  id: number | undefined
  contractId: number | undefined
  startDate: Date | undefined
  limitDate: Date | undefined
  price: number | undefined
  description: string | undefined
  onClose: () => void
  onOk: (form: UpdateForm, reset: () => void) => void
}

const UpdateContractModal: React.FC<Props> = ({
  open,
  loading,
  contractId,
  id: carrierId,
  startDate,
  limitDate,
  price,
  description,
  onClose,
  onOk,
}) => {
  const { t } = useTranslation()
  const { TextArea } = Input

  const { control, handleSubmit, reset } = useForm<UpdateForm>({
    resolver: yupResolver(validationUpdateSchema),
  })

  const submitHandler = (form: UpdateForm) => {
    onOk(form, reset)
  }

  const disabledDate = (current: Moment) => {
    const contractDate = moment(limitDate)

    return current.startOf('day') < contractDate.startOf('day')
  }

  const handleDisable = () => {
    return moment(limitDate).startOf('day') < moment(new Date()).startOf('day')
  }

  return (
    <Modal
      visible={open}
      onCancel={onClose}
      title={t('admin_router.admin_dashboard.contracts.carrier_contracts.title_edit')}
      closable={false}
      okText={t('admin_router.admin_dashboard.contracts.button_ok')}
      cancelText={t('admin_router.admin_dashboard.contracts.button_cancel')}
      onOk={handleSubmit(submitHandler)}
      afterClose={reset}
      destroyOnClose
    >
      <Spin spinning={loading}>
        <StyledRow>
          <Col span={8}>
            <StyledText>
              {t('admin_router.admin_dashboard.contracts.carrier_contracts.id')}:
            </StyledText>
          </Col>
          <Col span={8}>
            <Controller
              name="contractId"
              control={control}
              defaultValue={contractId}
              render={({ field }) => <Input {...field} min={1} value={field.value} disabled />}
            />
            <Controller
              name="id"
              control={control}
              defaultValue={carrierId}
              render={({ field }) => <Input {...field} value={field.value} disabled hidden />}
            />
          </Col>
        </StyledRow>
        <StyledRow>
          <Col span={8}>
            <StyledText>
              {t('admin_router.admin_dashboard.contracts.carrier_contracts.start_date')}:
            </StyledText>
          </Col>
          <Col span={8}>
            <Controller
              name="from"
              control={control}
              defaultValue={startDate}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  allowClear={false}
                  showToday={false}
                  value={moment(field.value).utc().local()}
                  picker="date"
                  format="ll"
                  disabled
                />
              )}
            />
          </Col>
        </StyledRow>
        <StyledRow>
          <Col span={8}>
            <StyledText>
              {t('admin_router.admin_dashboard.contracts.carrier_contracts.finish_date')}:
            </StyledText>
          </Col>
          <Col span={8}>
            <Controller
              name="to"
              control={control}
              defaultValue={limitDate}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  allowClear={false}
                  showToday={false}
                  value={field.value ? moment(field.value).utc().local() : undefined}
                  disabledDate={disabledDate}
                  picker="date"
                  format="ll"
                  disabled={handleDisable()}
                />
              )}
            />
          </Col>
        </StyledRow>
        <StyledRow>
          <Col span={8}>
            <StyledText>
              {t('admin_router.admin_dashboard.contracts.carrier_contracts.price')}:
            </StyledText>
          </Col>
          <Col span={8}>
            <Controller
              name="price"
              control={control}
              defaultValue={price}
              render={({ field }) => <InputNumber {...field} min={1} disabled={handleDisable()} />}
            />
          </Col>
        </StyledRow>
        <StyledRow>
          <Col span={8}>
            <StyledText>
              {t('admin_router.admin_dashboard.contracts.carrier_contracts.description')}:
            </StyledText>
          </Col>
          <Col span={12}>
            <Controller
              name="description"
              control={control}
              defaultValue={description}
              render={({ field }) => <TextArea {...field} disabled={handleDisable()} />}
            />
          </Col>
        </StyledRow>
        {handleDisable() && (
          <>
            <StyledRow>
              <Col>The contract has ended, you can no longer change its conditions</Col>
            </StyledRow>
          </>
        )}
      </Spin>
    </Modal>
  )
}

export { UpdateContractModal }
