import { notification } from 'antd'
import { useMutation } from 'react-query'

import { DownloadExcelApi } from './api'

interface DownloadResponse {
  file: { data: ArrayBuffer }
}

const useDownloadExcel = (name: string) => {
  const onError = () => {
    notification.error({
      message: 'Error al descargar el archivo',
    })
  }

  const onSuccess = (data: DownloadResponse) => {
    const byteArray = new Uint8Array(data.file.data)
    const a = window.document.createElement('a')

    a.href = window.URL.createObjectURL(
      new Blob([byteArray], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
      }),
    )
    a.download = name

    document.body.appendChild(a)
    a.click()

    document.body.removeChild(a)
  }

  const { mutate: downloadFile, isLoading } = useMutation(DownloadExcelApi.downloadExcelFile, {
    onSuccess: data => onSuccess(data),
    onError,
  })

  return {
    downloadFile,
    isLoading,
  }
}

export { useDownloadExcel }
