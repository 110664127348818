import { Table, Row, Col, Input, DatePicker, Button, notification, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import moment, { Moment } from 'moment'
import { ExclamationCircleOutlined } from '@ant-design/icons/lib/icons'

import { RPA, RPAFilters } from '@types'
import { useRPAFilters, useUser } from '@contexts'
import { DownloadExcel, DownloadPDF } from '@components'

import { useRPA } from './hooks'
import { Container } from './components'

const Accounting = () => {
  const { filters, setFilters } = useRPAFilters()
  const { t } = useTranslation()
  const { rpa: dataRPA, isLoading: rpaLoading, createRPA, createLoading } = useRPA(filters)
  const { state: user } = useUser()

  const { confirm } = Modal

  const showConfirmModal = (from: string, invoiceQty: number) => {
    confirm({
      title: t('admin_router.admin_dashboard.accounting.create.title'),
      icon: <ExclamationCircleOutlined />,
      content: t('admin_router.admin_dashboard.accounting.create.content'),
      cancelText: t('admin_router.admin_dashboard.accounting.create.cancel_text'),
      okText: t('admin_router.admin_dashboard.accounting.create.ok_text'),
      onOk() {
        return onCreateRPA(from, invoiceQty)
      },
    })
  }

  const formatDate = 'YYYY-MM-DD HH:mm'

  const columns = [
    {
      title: t('admin_router.admin_dashboard.accounting.columns.RPA'),
      key: 'RPA',
      render: (R: RPA) => R.id && <>{R.id}</>,
    },
    {
      title: t('admin_router.admin_dashboard.accounting.columns.from'),
      key: 'from',
      render: (R: RPA) => R.from && <>{moment(R.from).format(formatDate)}</>,
    },
    {
      title: t('admin_router.admin_dashboard.accounting.columns.to'),
      render: (R: RPA) => R.id && <>{R.to === 'NOW' ? 'NOW' : moment(R.to).format(formatDate)}</>,
    },
    {
      title: t('admin_router.admin_dashboard.accounting.columns.invoiceQty'),
      key: 'invoiceQty',
      render: (R: RPA) => R.invoiceQty && <>{R.invoiceQty}</>,
    },
    {
      title: t('admin_router.admin_dashboard.accounting.columns.download'),
      key: 'download',
      children: [
        {
          title: 'Excel',
          key: 'excel',
          align: 'center',
          render: (R: RPA) =>
            R.id !== '-' ? (
              <DownloadExcel
                text={t('admin_router.admin_dashboard.accounting.columns.download')}
                rpaId={R.id}
                name="Facturas_USA.xlsx"
              />
            ) : (
              ''
            ),
        },
        {
          title: t('admin_router.admin_dashboard.accounting.columns.invoices'),
          key: 'invoices',
          align: 'center',
          render: (R: RPA) =>
            R.id !== '-' ? (
              <DownloadPDF
                text={t('admin_router.admin_dashboard.accounting.columns.download')}
                rpaId={R.id}
                filename={`RPA-${R.id}-invoices.zip`}
              />
            ) : (
              ''
            ),
        },
      ],
    },
    {
      title: t('admin_router.admin_dashboard.accounting.columns.actions'),
      render: (R: RPA) =>
        R.id === dataRPA?.rpa.rpa[0].id && !filters?.rpaId && !filters?.date ? (
          <Button
            disabled={user?.roles[0].name === 'ADMINRO'}
            onClick={() => showConfirmModal(R.from, R.invoiceQty)}
            loading={createLoading}
          >
            {t('admin_router.admin_dashboard.accounting.columns.create')}
          </Button>
        ) : (
          ''
        ),
    },
  ]

  const onCreateRPA = (from: string, invoiceQty: number) => {
    if (!invoiceQty)
      notification.error({ message: t('admin_router.admin_dashboard.accounting.create.error') })
    else createRPA({ from, to: moment().utc().local().toISOString(), invoiceQty })
  }

  const handleInputChange = (value: string) => {
    setFilters((prevState: RPAFilters) => ({
      ...prevState,
      page: 1,
      rpaId: value as string,
    }))
  }

  const handleEmptyInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '')
      setFilters((prevState: RPAFilters) => ({
        ...prevState,
        page: 1,
        rpaId: undefined,
        date: undefined,
      }))
  }

  const onChangeDate = (date: Moment | null) => {
    if (!date) {
      setFilters((prevState: RPAFilters) => ({
        ...prevState,
        page: 1,
        date: undefined,
      }))
    } else {
      setFilters((prevState: RPAFilters) => ({
        ...prevState,
        page: 1,
        date: date?.startOf('month').toDate() || undefined,
      }))
    }
  }

  if (!filters?.rpaId && !filters?.date) {
    const newRPA: RPA = {
      id: '-',
      from: dataRPA?.rpa.rpa?.length
        ? moment(dataRPA?.rpa.rpa[0].to).add(1, 'seconds').toISOString()
        : moment('2021/12/10').toISOString(),
      to: 'NOW',
      invoiceQty: dataRPA?.invoices?.count ?? 0,
    }
    if (dataRPA?.rpa.rpa[0]?.to !== 'NOW') {
      dataRPA?.rpa.rpa?.unshift(newRPA)
    }
  }

  return (
    <Container>
      <Row gutter={16}>
        <Col span={5}>
          <Input.Search
            placeholder="Search by RPA#"
            type="string"
            onSearch={handleInputChange}
            onChange={handleEmptyInput}
            loading={rpaLoading}
            defaultValue={filters?.rpaId ? filters.rpaId : undefined}
          />
        </Col>
        <Col span={5}>
          <DatePicker
            allowClear
            style={{ width: '100%' }}
            format="YYYY MMM"
            picker="month"
            onChange={onChangeDate}
            defaultValue={filters?.date ? moment(filters.date).local() : undefined}
          />
        </Col>
      </Row>
      <Table
        columns={columns}
        rowKey="id"
        dataSource={dataRPA?.rpa.rpa}
        pagination={{ position: ['bottomCenter'] }}
        loading={rpaLoading}
        scroll={{ y: 'calc(100vh - 330px)' }}
      />
    </Container>
  )
}

export { Accounting }
