import * as yup from 'yup'

export const validationSchema = yup.object().shape({
  carrierId: yup.number().required('Carrier is a required field'),
  destinationId: yup.number().required('Destination is a required field'),
  depotId: yup.mixed().required('Yard is a required field'),
  invoiceDate: yup.date().required('Invoice date is a required field'),
  invoiceNumber: yup.string().required('Invoice number is a required field'),
  invoiceAmount: yup
    .number()
    .moreThan(0, 'Invoice Amount must be a positive number')
    .required('Invoice amount is a required field'),
  tareWeight: yup
    .number()
    .required('Tare weight is a required field')
    .moreThan(0, 'Tare Weight must be a positive number'),
  grossWeight: yup
    .number()
    .required('Gross Weight is a required field')
    .moreThan(yup.ref('tareWeight'), 'Gross Weight must be greater than Tare Weight'),
  materialId: yup.string().required('Material is a required field'),
  materialDescription: yup.string().nullable().optional(),
  purchaseOrderId: yup.string().nullable().optional(),
  waybill: yup
    .string()
    .required('WayBill is a required field')
    .matches(/^[0-9]{5,}$/, 'WayBill must contain only numeric digits (min 5)')
    .notOneOf([yup.ref('invoiceNumber'), null], 'WayBill and Invoice Number must be different'),
  carEquipment: yup
    .string()
    .required('Car/Equipment is a required field')
    .matches(/^[A-Za-z0-9]{6,}$/, 'Car/Equipment must contain alphanumeric digits (min 6)')
    .test(
      'not-equal-to-waybill', // Test name
      'Car/Equipment and WayBill must be different', // Error message
      function notEqualToWaybill(value) {
        const { waybill } = this.parent
        return waybill !== value
      },
    )
    .test(
      'not-equal-to-invoiceNumber', // Test name
      'Car/Equipment and Invoice Number must be different', // Error message
      function notEqualToInvoiceNumber(value) {
        const { invoiceNumber } = this.parent
        return invoiceNumber !== value
      },
    ),
  salesInvoice: yup.mixed().required('Invoice is a required field'),
  weightCertificate: yup.mixed().required('Weight cert. is a required file'),
  BL: yup.mixed().required('B/L is a required file'),
  radioactiveCertificate: yup.mixed().required('Radioactive cert. is a required file'),
  photos: yup.mixed().required('This is a required file'),
  comments: yup.string().nullable().optional(),
})

export const validationUpdateSchema = yup.object().shape({
  invoiceDate: yup.date().optional(),
  invoiceNumber: yup.string().optional(),
  invoiceAmount: yup
    .number()
    .moreThan(0, 'Tare Weight must be a positive number')
    .required('Invoice amount is a required field'),
  grossWeight: yup
    .number()
    .required('Gross weight is a required field')
    .moreThan(yup.ref('tareWeight'), 'Gross Weight must be greater than Tare Weight'),
  tareWeight: yup
    .number()
    .required('Tare weight is a required field')
    .moreThan(0, 'Tare Weight must be a positive number'),
  materialId: yup.string().nullable().optional(),
  materialDescription: yup.string().nullable().optional(),
  purchaseOrderId: yup.string().nullable().optional(),
  waybill: yup
    .string()
    .required('WayBill is a required field')
    .matches(/^[0-9]{5,}$/, 'WayBill must contain only numeric digits (min 5)')
    .notOneOf([yup.ref('invoiceNumber'), null], 'WayBill and Invoice Number must be different'),
  carEquipment: yup
    .string()
    .required('Car/Equipment is a required field')
    .matches(/^[A-Za-z0-9]{6,}$/, 'Car/Equipment must contain alphanumeric digits (min 6)')
    .test(
      'not-equal-to-waybill', // Test name
      'Car/Equipment and WayBill must be different', // Error message
      function notEqualToWaybill(value) {
        const { waybill } = this.parent
        return waybill !== value
      },
    )
    .test(
      'not-equal-to-invoiceNumber', // Test name
      'Car/Equipment and Invoice Number must be different', // Error message
      function notEqualToInvoiceNumber(value) {
        const { invoiceNumber } = this.parent
        return invoiceNumber !== value
      },
    ),
  salesInvoice: yup.mixed().optional(),
  weightCertificate: yup.mixed().optional(),
  BL: yup.mixed().optional(),
  radioactiveCertificate: yup.mixed().optional(),
  photos: yup.mixed().optional(),
  comments: yup.string().nullable().optional(),
})

export interface Form {
  carrierId: number
  destinationId: number
  depotId: number
  invoiceDate: Date
  invoiceNumber: string
  invoiceAmount: number
  grossWeight: number
  tareWeight: number
  materialId: string
  materialDescription: string
  purchaseOrderId: string
  carEquipment?: string
  waybill?: string
  salesInvoice: Blob
  weightCertificate: Blob
  BL: Blob
  radioactiveCertificate: Blob
  photos: Blob[]
  comments: string
}

export interface UpdateForm {
  carrierId: number
  destinationId: number
  depotId: number
  invoiceDate?: Date
  invoiceNumber?: string
  invoiceAmount?: number
  grossWeight?: number
  tareWeight?: number
  materialId?: string
  materialDescription?: string
  purchaseOrderId?: string
  carEquipment?: string
  waybill?: string
  salesInvoice?: Blob
  weightCertificate?: Blob
  BL?: Blob
  radioactiveCertificate?: Blob
  photos?: Blob[]
  railcarDocumentationId: number
  comments?: string
}
