import { notification } from 'antd'
import { useQuery } from 'react-query'

import { QUERY_KEYS } from '@api'

import { TruckDocumentationApi } from './api'

const onError = () => {
  notification.error({
    message: 'Error al obtener los transportistas',
  })
}

const useTruckDocumentation = (ticketId: number) => {
  const { data, isLoading } = useQuery(
    QUERY_KEYS.TRUCK_DOCUMENTATION,
    () => TruckDocumentationApi.getTruckDocumentation(ticketId),
    {
      onError,
    },
  )

  return {
    truckDocumentation: data,
    isLoading,
  }
}

export { useTruckDocumentation }
