import { Col, Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { CarrierModeEnum, StatusTicketEnum, Ticket } from '@types'
import { RailcarIcon, TruckIcon } from '@icons'

import { StyledText, StyledRow, StyledTitle, StyledDivider, StyledCard } from './components'

type Props = {
  tickets?: Ticket[]
}

const getStatusName = (name: string) => {
  const statusName =
    name === StatusTicketEnum.SUPPLIER_INVOICED ||
    name === StatusTicketEnum.TA_INVOICED ||
    name === StatusTicketEnum.MX_TRANSIT ||
    name === StatusTicketEnum.DELIVERED
      ? StatusTicketEnum.INVOICED
      : name

  return statusName
}

const TicketCard: React.FC<Props> = ({ tickets }) => {
  const { t } = useTranslation()
  const dateFormat = 'll'

  return (
    <>
      {tickets?.map(ticket => {
        return (
          <StyledCard>
            <StyledRow justify="space-between">
              <Col span={8}>
                {ticket.carrier && ticket.carrier.mode === CarrierModeEnum.RAILCAR ? (
                  <RailcarIcon width={18} height={15} />
                ) : null}
                {ticket.ticketAssignments.length &&
                ticket.ticketAssignments[0].carrier.mode === CarrierModeEnum.TRUCK ? (
                  <TruckIcon width={18} height={15} />
                ) : null}
              </Col>
              <Col span={8}>
                <StyledText>#{ticket.id}</StyledText>
              </Col>
              <Col>
                <Tag color="volcano">{getStatusName(ticket.status.name)}</Tag>
              </Col>
            </StyledRow>
            <StyledRow justify="space-between">
              <Col span={4}>
                <StyledTitle>
                  {t('suppliers_router.suppliers_dashboard.columns.order_date')}
                </StyledTitle>
              </Col>
              <Col span={8}>{moment(ticket.createAt).format(dateFormat)}</Col>
            </StyledRow>
            <StyledDivider />
            <StyledRow justify="space-between">
              <Col span={12}>
                <StyledTitle>{t('tpl_router.tpl_dashboard.columns.load_date')}</StyledTitle>
              </Col>
              <Col span={8}>
                {ticket.truckDocumentation
                  ? moment(ticket.truckDocumentation.createAt).format(dateFormat)
                  : '-'}
              </Col>
            </StyledRow>
            <StyledDivider />
            <StyledRow justify="space-between">
              <Col span={12}>
                <StyledTitle>
                  {t('suppliers_router.suppliers_dashboard.columns.invoice_number')}
                </StyledTitle>
              </Col>
              <Col span={8}>
                {' '}
                {ticket.truckDocumentation ? ticket.truckDocumentation.invoiceNumber : '-'}
              </Col>
            </StyledRow>
            <StyledDivider />

            <StyledRow justify="space-between">
              <Col span={12}>
                <StyledTitle>
                  {t('suppliers_router.suppliers_dashboard.columns.carrier')}
                </StyledTitle>
              </Col>
              <Col span={8}>{ticket.carrier ? ticket.carrier.businessName : '-'}</Col>
            </StyledRow>
            <StyledDivider />

            <StyledRow justify="space-between">
              <Col span={12}>
                <StyledTitle>{t('tpl_router.tpl_dashboard.columns.destination')}</StyledTitle>
              </Col>
              <Col span={8}>{ticket.destination ? ticket.destination.name : '-'}</Col>
            </StyledRow>
          </StyledCard>
        )
      })}
    </>
  )
}

export { TicketCard }
