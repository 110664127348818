import React from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox, Col, Row } from 'antd'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import { valueType } from 'antd/lib/statistic/utils'

import { Filters, StatusTicketEnum } from '@types'

import {
  StyledDrawer,
  ContainerCheckbox,
  StyledCheckboxGroup,
  StyledInputNumber,
  StyledInput,
  Title,
  RowMargin,
} from './components'

type Props = {
  open: boolean
  onClose: () => void
  onChange: (checkedValue: CheckboxValueType[]) => void
  onChangeTicketIdFilter?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onChangeTaInvoiceNumberFilter?: (value: valueType) => void
  filters?: Filters
}

const MobileFilters: React.FC<Props> = ({
  open,
  onClose,
  onChange,
  onChangeTicketIdFilter,
  onChangeTaInvoiceNumberFilter,
  filters,
}) => {
  const { t } = useTranslation()

  const checkboxesGroup = [
    {
      name: t('tpl_router.filterOptions.title'),
      checkboxes: [
        {
          label: `${t('tpl_router.filterOptions.pending')}`,
          value: StatusTicketEnum.PENDING,
        },
        {
          label: `${t('tpl_router.filterOptions.confirmed')}`,
          value: StatusTicketEnum.CONFIRMED,
        },
        {
          label: `${t('tpl_router.filterOptions.delivered')}`,
          value: StatusTicketEnum.DELIVERED,
        },
        {
          label: `${t('tpl_router.filterOptions.transit')}`,
          value: StatusTicketEnum.TRANSIT,
        },
      ],
    },
    // TODO: uncomment when needed
    // {
    //   name: t('tpl_router.filterOptions.lote.title'),
    //   checkboxes: [
    //     `${t('tpl_router.filterOptions.lote.awaiting_hes')}`,
    //     `${t('tpl_router.filterOptions.lote.hes')}`,
    //     `${t('tpl_router.filterOptions.lote.invoiced')}`,
    //   ],
    // },
  ]
  return (
    <StyledDrawer
      title="Filter By"
      placement="right"
      onClose={onClose}
      visible={open}
      getContainer={false}
    >
      <RowMargin gutter={30} align="middle">
        <Col span={4}>
          <Title>Trip ID</Title>
        </Col>
        <Col>
          <StyledInput value={filters?.ticketId} onChange={onChangeTicketIdFilter} min={1} />
        </Col>
      </RowMargin>
      <Row gutter={30} align="middle">
        <Col span={4}>
          <Title>TA Invoice</Title>
        </Col>
        <Col>
          <StyledInputNumber
            value={filters?.taInvoiceNumber}
            onChange={onChangeTaInvoiceNumberFilter}
            min={1}
          />
        </Col>
      </Row>
      {checkboxesGroup?.map(({ name, checkboxes }) => (
        <StyledCheckboxGroup onChange={onChange} value={filters?.statusName}>
          <p>{name}</p>
          {checkboxes.map(({ value, label }) => (
            <ContainerCheckbox span={24}>
              <Checkbox value={value}>{label}</Checkbox>
            </ContainerCheckbox>
          ))}
        </StyledCheckboxGroup>
      ))}
    </StyledDrawer>
  )
}

export { MobileFilters }
