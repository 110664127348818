import { api } from '@api'

interface DownloadResponse {
  invoiceZip: {
    AcceptRanges: string
    LastModified: string
    ContentLength: number
    ETag: string
    ContentType: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Metadata: any
    StorageClass: string
    Body: {
      type: string
      data: number[]
    }
  }
}

type Params = {
  rpaId: number
}

const donwloadZipFolder = async (params: Params) => {
  const { rpaId } = params
  const response = await api.get<DownloadResponse>('/tickets/pdf', {
    params: {
      rpaId,
    },
  })

  return response.data
}

export const DownloadPDFApi = { donwloadZipFolder }
