import { useState } from 'react'
import { notification } from 'antd'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'

import { QUERY_KEYS } from '@api'
import { CarrierFilters, CarrierModeEnum } from '@types'

import { CarriersApi } from './api'

const onError = () => {
  notification.error({
    message: 'Error al obtener los carriers',
  })
}

const useCarriers = (filters?: CarrierFilters) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const [isCarrierOptionsModalOpen, setIsCarrierOptionsModalOpen] = useState(false)

  const { data, isLoading } = useQuery([QUERY_KEYS.CARRIERS], CarriersApi.getCarriers, {
    onError,
  })

  const { data: filteredData, isLoading: isFilteredLoading } = useQuery(
    [QUERY_KEYS.CARRIERS, { filters }],
    () => CarriersApi.getCarriersWithFilters({ filters }),
    {
      onError,
    },
  )

  const { data: truckData, isLoading: isTrucksLoading } = useQuery(
    [QUERY_KEYS.CARRIERS, { filters: { mode: CarrierModeEnum.TRUCK } }],
    () => CarriersApi.getCarriersWithFilters({ filters: { mode: CarrierModeEnum.TRUCK } }),
    {
      onError,
    },
  )

  const carriersOptions = data?.map(carrier => {
    return {
      label: carrier.businessName,
      value: carrier.id,
    }
  })

  const carriersFiltered = filteredData?.carriers.map(carrier => {
    return {
      label: carrier.businessName,
      value: carrier.id,
    }
  })

  const truckOptions = truckData?.carriers.map(carrier => {
    return {
      label: carrier.businessName,
      value: carrier.id,
    }
  })

  const onChangeSuccess = () => {
    notification.success({
      message: t('admin_router.admin_dashboard.categories.notifications.category_success'),
    })
    setIsCarrierOptionsModalOpen(false)
    queryClient.invalidateQueries(QUERY_KEYS.CARRIERS)
  }

  const onChangeError = () => {
    notification.error({
      message: t('admin_router.admin_dashboard.categories.notifications.category_error'),
    })
    setIsCarrierOptionsModalOpen(false)
    queryClient.invalidateQueries(QUERY_KEYS.CARRIERS)
  }

  const { mutate: changeOptions, isLoading: changeOptionsLoading } = useMutation(
    CarriersApi.changeOptions,
    {
      onSuccess: onChangeSuccess,
      onError: onChangeError,
    },
  )

  return {
    carriers: data,
    filteredCarriers: filteredData,
    isLoading,
    isFilteredLoading,
    carriersOptions,
    carriersFiltered,
    isCarrierOptionsModalOpen,
    setIsCarrierOptionsModalOpen,
    changeOptions,
    changeOptionsLoading,
    truckData,
    isTrucksLoading,
    truckOptions,
  }
}

export { useCarriers }
