/* eslint-disable @typescript-eslint/no-unused-expressions */
import { notification } from 'antd'
import { useQuery } from 'react-query'

import { QUERY_KEYS } from '@api'

import { TicketsApi } from '../useTickets/api'

const useTicketsById = (ticketId?: number) => {
  const onError = () => {
    notification.error({
      message: 'Error al obtener las solicitudes',
    })
  }

  const { data: ticketById, isLoading: isTicketByIdLoading } = useQuery(
    [QUERY_KEYS.TICKET],
    () => TicketsApi.getTicketById(ticketId),
    {
      onError,
      enabled: !!(ticketId && ticketId > 0),
    },
  )

  return {
    ticketById,
    isTicketByIdLoading,
  }
}

export { useTicketsById }
