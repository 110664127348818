import { notification } from 'antd'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AxiosError } from 'axios'

import { QUERY_KEYS } from '@api'
import { Filters, StatusTicketEnum } from '@types'
import { TicketNotifContext } from '@contexts'

import { TicketsApi } from './api'

const useTickets = (filters?: Filters) => {
  const [isAcceptTicketModalOpen, setIsAcceptTicketModalOpen] = useState(false)
  const [isDeclineTicketModalOpen, setIsDeclineTicketModalOpen] = useState(false)
  const [isChangeCarrierModalOpen, setIsChangeCarrierModalOpen] = useState(false)
  const queryClient = useQueryClient()
  const ticketNotification = useContext(TicketNotifContext)

  const { t } = useTranslation()

  const onError = () => {
    notification.error({
      message: 'Error al obtener las solicitudes',
    })
  }

  const { data, isLoading } = useQuery(
    [QUERY_KEYS.TICKETS, { filters }],
    () => TicketsApi.getTickets({ filters }),
    {
      onError,
    },
  )

  const { data: ticketsDelivered, isLoading: isDeliveredLoading } = useQuery(
    [QUERY_KEYS.TICKETS, { filters: { ...filters, statusName: [StatusTicketEnum.DELIVERED] } }],
    () =>
      TicketsApi.getTickets({ filters: { ...filters, statusName: [StatusTicketEnum.DELIVERED] } }),
    {
      onError,
    },
  )

  const statusFilters = filters?.statusName.length
    ? filters.statusName
    : [
        StatusTicketEnum.CONFIRMED,
        StatusTicketEnum.MX_TRANSIT,
        StatusTicketEnum.PENDING,
        StatusTicketEnum.SUPPLIER_INVOICED,
        StatusTicketEnum.TA_INVOICED,
        StatusTicketEnum.CANCELED,
      ]

  const { data: ticketsOngoing, isLoading: isOngoingLoading } = useQuery(
    [
      QUERY_KEYS.TICKETS,
      {
        filters: {
          ...filters,
          statusName: statusFilters,
        },
      },
    ],
    () =>
      TicketsApi.getTickets({
        filters: {
          ...filters,
          statusName: statusFilters,
        },
      }),
    {
      onError,
    },
  )

  const onAcceptTicketSuccess = () => {
    notification.success({
      message: [
        `Ticket ID ${ticketNotification.state} `,
        t('tpl_router.tpl_dashboard.accept_decline_notification.accept'),
      ],
    })
    setIsAcceptTicketModalOpen(false)
    queryClient.invalidateQueries(QUERY_KEYS.TICKETS)
  }
  const onDecilneTicketSuccess = () => {
    notification.success({
      message: [
        `Ticket ID ${ticketNotification.state} `,
        t('tpl_router.tpl_dashboard.accept_decline_notification.decline'),
      ],
    })
    setIsDeclineTicketModalOpen(false)
    queryClient.invalidateQueries(QUERY_KEYS.TICKETS)
  }

  const onManualAssignSuccess = () => {
    notification.success({
      message: 'Carrier asignado con éxito',
    })
    setIsChangeCarrierModalOpen(false)
    queryClient.invalidateQueries(QUERY_KEYS.TICKETS)
  }

  const onAcceptTicketError = (error: AxiosError) => {
    notification.error({
      message: error.response?.data.error.message,
    })
  }

  const onDeclineTicketError = () => {
    notification.error({
      message: 'Error al rechazar la solicitud',
    })
  }

  const onManualAssignError = () => {
    notification.error({
      message: 'Error al cambiar el carrier',
    })
  }

  const { mutate: acceptTicket, isLoading: acceptTicketLoading } = useMutation(
    TicketsApi.acceptTicket,
    {
      onSuccess: onAcceptTicketSuccess,
      onError: onAcceptTicketError,
    },
  )

  const { mutate: declineTicket, isLoading: declineTicketLoading } = useMutation(
    TicketsApi.declineTicket,
    {
      onSuccess: onDecilneTicketSuccess,
      onError: onDeclineTicketError,
    },
  )

  const { mutate: changeTicket, isLoading: changeTicketLoading } = useMutation(
    TicketsApi.changeTicket,
    {
      onSuccess: () => {
        queryClient.refetchQueries(QUERY_KEYS.TICKETS)
      },
    },
  )

  const { mutate: manualAssign, isLoading: manualAssignLoading } = useMutation(
    TicketsApi.manualAssign,
    {
      onSuccess: onManualAssignSuccess,
      onError: onManualAssignError,
    },
  )

  return {
    tickets: data,
    isLoading,
    acceptTicket,
    acceptTicketLoading,
    declineTicket,
    declineTicketLoading,
    manualAssign,
    manualAssignLoading,
    changeTicket,
    changeTicketLoading,
    isAcceptTicketModalOpen,
    setIsAcceptTicketModalOpen,
    isDeclineTicketModalOpen,
    setIsDeclineTicketModalOpen,
    isChangeCarrierModalOpen,
    setIsChangeCarrierModalOpen,
    ticketsDelivered,
    isDeliveredLoading,
    ticketsOngoing,
    isOngoingLoading,
  }
}

export { useTickets }
