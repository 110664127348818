import React, { useState, Dispatch, SetStateAction } from 'react'

type ContextState = {
  ticketDeleteId?: number
  setTicketDeleteId: Dispatch<SetStateAction<number | undefined>>
}

const initialState = {
  ticketDeleteId: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setTicketDeleteId: () => {},
}

export const DeleteTicketNotificationContext = React.createContext<ContextState>(initialState)

export function DeleteTicketNotificationProvider({ children }: { children: React.ReactNode }) {
  const [ticketDeleteId, setTicketDeleteId] = useState<number | undefined>()

  return (
    <DeleteTicketNotificationContext.Provider value={{ ticketDeleteId, setTicketDeleteId }}>
      {children}
    </DeleteTicketNotificationContext.Provider>
  )
}
