import { useHistory, useParams } from 'react-router-dom'
import { useEffect } from 'react'

import { useTicketsById } from '@hooks'
import { useUser } from '@contexts'
import { ROUTE_KEYS } from '@constants'
import { RoleEnum, StatusTicketEnum } from '@types'

import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { TAInvoice } from './TAInvoice'

const { ADMIN, ADMINRO } = RoleEnum

const allowedRoles = [ADMIN, ADMINRO]
const invalidStatuses = [
  StatusTicketEnum.PENDING,
  StatusTicketEnum.CONFIRMED,
  StatusTicketEnum.CANCELED,
]

const TAPreInvoice = () => {
  const { id } = useParams<{ id: string }>()
  const { ticketById, isTicketByIdLoading } = useTicketsById(Number(id))
  const history = useHistory()
  const { state: user } = useUser()

  useEffect(() => {
    if (
      !(id && user && allowedRoles.includes(user.roles[0].name)) ||
      (!isTicketByIdLoading && !ticketById?.ticket)
    ) {
      history.push(user ? ROUTE_KEYS[user.roles[0].name].HOME : ROUTE_KEYS.LOGIN)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketById, isTicketByIdLoading, id, user, history])

  if (isTicketByIdLoading) return <LoadingSpinner />

  if (ticketById?.ticket && invalidStatuses.includes(ticketById.ticket.status.name))
    history.push(user ? ROUTE_KEYS[user.roles[0].name].HOME : ROUTE_KEYS.LOGIN)

  return ticketById?.ticket && !invalidStatuses.includes(ticketById.ticket.status.name) ? (
    <TAInvoice ticket={ticketById.ticket} />
  ) : null
}

export default TAPreInvoice
