const DASHBOARD_ROUTE = '/dashboard'
const ADMIN_HOME = `${DASHBOARD_ROUTE}/admin`
const ADMINRO_HOME = `${DASHBOARD_ROUTE}/adminro`
const CARRIER_HOME = `${DASHBOARD_ROUTE}/carrier`
const SUPPLIER_HOME = `${DASHBOARD_ROUTE}/suppliers`
const CUSTOMS_HOME = `${DASHBOARD_ROUTE}/customs`
const ONGOING_ROUTE = '/ongoing'
const EVOUCHERS_ROUTE = '/evouchers'
const LOGIN = '/login'
const TICKET_ROUTE = '/ticket'
const TRUCK_ROUTE = '/truck-documentation'
const RAILCAR_ROUTE = '/railcar-documentation'

export const ROUTE_KEYS = {
  ADMIN: {
    HOME: ADMIN_HOME,
    ONGOING: ADMIN_HOME + ONGOING_ROUTE,
    EVOUCHERS: ADMIN_HOME + EVOUCHERS_ROUTE,
    TICKET: ADMIN_HOME + TICKET_ROUTE,
  },
  CARRIER: {
    HOME: CARRIER_HOME,
    ALL: `${CARRIER_HOME}/all`,
    EVOUCHERS: CARRIER_HOME + EVOUCHERS_ROUTE,
    VEHICLES: `${CARRIER_HOME}/vehicles`,
    TICKET: CARRIER_HOME + TICKET_ROUTE,
  },
  SUPPLIER: {
    HOME: SUPPLIER_HOME,
    PREVIOUS: `${SUPPLIER_HOME}/previous`,
    TICKET: SUPPLIER_HOME + TICKET_ROUTE,
  },
  CUSTOMS: {
    HOME: CUSTOMS_HOME,
    TRUCK: CUSTOMS_HOME + TRUCK_ROUTE,
    RAILCAR: CUSTOMS_HOME + RAILCAR_ROUTE,
    TICKET: CUSTOMS_HOME + TICKET_ROUTE,
  },
  ADMINRO: {
    HOME: ADMINRO_HOME,
    ONGOING: ADMINRO_HOME + ONGOING_ROUTE,
    EVOUCHERS: ADMINRO_HOME + EVOUCHERS_ROUTE,
    TICKET: ADMINRO_HOME + TICKET_ROUTE,
  },
  TICKET: DASHBOARD_ROUTE + TICKET_ROUTE,
  LOGIN,
}
