import React, { useContext } from 'react'
import { Row, Col, Modal, DatePicker, Spin } from 'antd'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ErrorMessage } from '@hookform/error-message'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import moment, { Moment } from 'moment'

import { TicketNotifContext } from '@contexts'

import { StyledErrorMessage } from '..'

type Props = {
  open: boolean
  onClose: () => void
  onOk: (withdrawalDate: { withdrawalDate: Date }) => void
  loading: boolean
}

export const validationSchema = yup.object().shape({
  withdrawalDate: yup
    .date()
    .test('is-now', 'Minimum 30 minutes anticipation', value => {
      if (
        moment(value).isSame(moment(), 'day') &&
        moment(value).isSame(moment(), 'hour') &&
        moment(value).isSame(moment(), 'minute')
      ) {
        return false
      }
      const currentDatePlus30Mins = moment().add(30, 'minutes')
      if (moment(value).isBefore(currentDatePlus30Mins)) {
        return false
      }
      return true
    })
    .required('Campo obligatorio'),
})

const AcceptTicketModal: React.FC<Props> = ({ open, onClose, onOk, loading }) => {
  const { t } = useTranslation()

  const DAY_HOURS = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
  ]
  const FIRST_HOUR = 9
  const LAST_HOUR = 18

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<{ withdrawalDate: Date }>({
    resolver: yupResolver(validationSchema),
  })

  const disabledTime = (dateSelected: Moment | null) => {
    const newDate = new Date()

    let dayNow =
      moment(newDate).hour() >= LAST_HOUR - 1 ? moment(newDate).day() + 1 : moment(newDate).day()
    if (dayNow === 7) dayNow = 0

    const hourNow =
      moment(newDate).hour() < FIRST_HOUR && LAST_HOUR - 1 <= moment(newDate).hour()
        ? FIRST_HOUR
        : moment(newDate).hour() + 1

    let dayOfWeek
    if (dateSelected) {
      if (moment(newDate).hour() >= LAST_HOUR - 1) dayOfWeek = dateSelected.day() + 1
      else dayOfWeek = dateSelected!.day()
    }
    if (dayOfWeek === 7) dayOfWeek = 0

    // Thursday config
    if (dayNow === 4 && dayOfWeek) {
      switch (dayOfWeek) {
        case 4:
          return {
            disabledHours: () => {
              const start = DAY_HOURS.slice(0, hourNow)
              const end = DAY_HOURS.slice(LAST_HOUR, 24)
              return start.concat(end)
            },
          }
        case 5:
          return {
            disabledHours: () => {
              const start = DAY_HOURS.slice(0, FIRST_HOUR)
              const end = DAY_HOURS.slice(LAST_HOUR, 24)
              return start.concat(end)
            },
          }
        case 1:
          return {
            disabledHours: () => {
              const start = DAY_HOURS.slice(0, FIRST_HOUR)
              const end = DAY_HOURS.slice(hourNow, 24)
              return start.concat(end)
            },
          }
        default:
          break
      }
    }

    // Friday config
    if (dayNow === 5 && dayOfWeek) {
      switch (dayOfWeek) {
        case 5:
          return {
            disabledHours: () => {
              const start = DAY_HOURS.slice(0, hourNow)
              const end = DAY_HOURS.slice(LAST_HOUR, 24)
              return start.concat(end)
            },
          }
        case 1:
          return {
            disabledHours: () => {
              const start = DAY_HOURS.slice(0, FIRST_HOUR)
              const end = DAY_HOURS.slice(LAST_HOUR, 24)
              return start.concat(end)
            },
          }
        case 2:
          return {
            disabledHours: () => {
              const start = DAY_HOURS.slice(0, FIRST_HOUR)
              const end = DAY_HOURS.slice(hourNow, 24)
              return start.concat(end)
            },
          }
        default:
          break
      }
    }

    // Weekend config
    if ((dayNow === 6 || dayNow === 0) && dayOfWeek) {
      return {
        disabledHours: () => {
          const start = DAY_HOURS.slice(0, FIRST_HOUR)
          const end = DAY_HOURS.slice(LAST_HOUR, 24)
          return start.concat(end)
        },
      }
    }

    // Rest of days
    if ((dayNow === 1 || dayNow === 2 || dayNow === 3) && dayOfWeek) {
      switch (dayOfWeek) {
        case dayNow:
          return {
            disabledHours: () => {
              const start = DAY_HOURS.slice(0, hourNow)
              const end = DAY_HOURS.slice(LAST_HOUR, 24)
              return start.concat(end)
            },
          }
        case dayNow + 1:
          return {
            disabledHours: () => {
              const start = DAY_HOURS.slice(0, FIRST_HOUR)
              const end = DAY_HOURS.slice(LAST_HOUR, 24)
              return start.concat(end)
            },
          }
        case dayNow + 2:
          return {
            disabledHours: () => {
              const start = DAY_HOURS.slice(0, FIRST_HOUR)
              const end = DAY_HOURS.slice(hourNow, 24)
              return start.concat(end)
            },
          }
        default:
          break
      }
    }

    return { disabledHours: () => DAY_HOURS, disabledMinutes: () => [0, 10, 20, 30, 40, 50] }
  }

  const disabledDate = (current: Moment) => {
    // Can not select days before today and today
    const rightNow = new Date()
    const hourNow = moment(rightNow).hour()
    const startDay =
      hourNow < LAST_HOUR - 1 ? moment(rightNow).dayOfYear() : moment(rightNow).dayOfYear() + 1
    const endDay =
      hourNow < LAST_HOUR - 1 ? moment(rightNow).dayOfYear() : moment(rightNow).dayOfYear() + 1
    const dayOfWeek = hourNow < LAST_HOUR - 1 ? moment(rightNow).day() : moment(rightNow).day() + 1
    const currentDoY = current.dayOfYear()

    switch (dayOfWeek) {
      case 4:
        return (
          currentDoY < startDay ||
          (currentDoY > startDay + 1 && currentDoY < endDay + 4) ||
          (currentDoY !== startDay && currentDoY > endDay + 4)
        )
      case 5:
        return (
          (currentDoY !== startDay && currentDoY < startDay) ||
          (currentDoY !== startDay && currentDoY < endDay + 3) ||
          (currentDoY !== startDay && currentDoY > endDay + 4)
        )
      case 6:
        return currentDoY > endDay + 3 || currentDoY < startDay + 2
      case 0:
        return currentDoY > endDay + 2 || currentDoY < startDay + 1

      default:
        if (hourNow < LAST_HOUR - 1) return currentDoY < startDay || currentDoY > endDay + 2
        return currentDoY < startDay || currentDoY > endDay + 1
    }
  }

  const Ticket = useContext(TicketNotifContext)

  return (
    <Modal
      visible={open}
      onCancel={onClose}
      title={[t('tpl_router.tpl_dashboard.accept_decline_modal.accept.title'), ` ${Ticket.state}`]}
      closable={false}
      okText={t('tpl_router.tpl_dashboard.accept_decline_modal.accept.button_ok')}
      cancelText={t('tpl_router.tpl_dashboard.accept_decline_modal.accept.button_cancel')}
      onOk={handleSubmit(onOk)}
      afterClose={reset}
      destroyOnClose
      confirmLoading={loading}
    >
      <>
        <Spin spinning={loading}>
          <Row align="middle" gutter={[16, 16]}>
            <Col xs={{ span: 24 }} sm={{ span: 6 }}>
              {t('tpl_router.tpl_dashboard.accept_decline_modal.accept.date')}:
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 18 }}>
              <Controller
                name="withdrawalDate"
                control={control}
                // defaultValue={defaultValue}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    allowClear={false}
                    style={{ width: '172px' }}
                    showTime={{ format: 'HH:mm' }}
                    format="ll HH:mm"
                    showNow={false}
                    minuteStep={15}
                    disabledTime={disabledTime}
                    disabledDate={disabledDate}
                    value={field.value ? moment(field.value).utc().local() : undefined}
                    onSelect={selected => disabledTime(selected)}
                  />
                )}
              />
            </Col>
            <Col>
              <StyledErrorMessage>
                <ErrorMessage errors={errors} name="withdrawalDate" />
              </StyledErrorMessage>
            </Col>
          </Row>
        </Spin>
      </>
    </Modal>
  )
}

export { AcceptTicketModal }
