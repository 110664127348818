import { api } from '@api'

interface CountTicketItem {
  id: number
  businessName: string
  lastWeek: {
    total: number
    confirmed: number
  }
  lastMonth: {
    total: number
    confirmed: number
  }
}

interface GetCountTickesResponse {
  carriers: CountTicketItem[]
}

const getCount = async () => {
  const response = await api.get<GetCountTickesResponse>('/tickets/count/carrier')

  return response.data.carriers
}

export const CountTickesApi = { getCount }
