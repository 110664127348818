import { notification } from 'antd'
import { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { AxiosError } from 'axios'

import { QUERY_KEYS } from '@api'
import { ContractFilters } from '@types'

import { ContractsApi } from './api'

const useContracts = (filters?: ContractFilters) => {
  const [isCreateContractModalOpen, setIsCreateContractModalOpen] = useState(false)
  const [isUpdateContractModalOpen, setIsUpdateContractModalOpen] = useState(false)
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const onError = () => {
    notification.error({
      message: t('admin_router.admin_dashboard.contracts.notifications.api_error'),
    })
  }

  const { data, isLoading } = useQuery(
    [QUERY_KEYS.CONTRACTS, { filters }],
    () => ContractsApi.getContracts({ filters }),
    {
      onError,
    },
  )

  const createContractSuccess = () => {
    notification.success({
      message: t('admin_router.admin_dashboard.contracts.notifications.create_success'),
    })
    setIsCreateContractModalOpen(false)
    queryClient.invalidateQueries(QUERY_KEYS.CONTRACTS)
  }

  const updateContractSuccess = () => {
    notification.success({
      message: t('admin_router.admin_dashboard.contracts.notifications.update_success'),
    })
    setIsUpdateContractModalOpen(false)
    queryClient.invalidateQueries(QUERY_KEYS.CONTRACTS)
  }

  const createContractError = (error: AxiosError) => {
    if (error.response?.statusText === 'Unauthorized') {
      notification.error({
        message: t('admin_router.admin_dashboard.contracts.notifications.date_error'),
      })
      setIsUpdateContractModalOpen(false)
    } else {
      notification.error({
        message: t('admin_router.admin_dashboard.contracts.notifications.create_error'),
      })
      setIsUpdateContractModalOpen(false)
    }
  }

  const updateContractError = () => {
    notification.error({
      message: t('admin_router.admin_dashboard.contracts.notifications.update_error'),
    })
    setIsUpdateContractModalOpen(false)
  }

  const { mutate: createContract, isLoading: createContractLoading } = useMutation(
    ContractsApi.createContract,
    {
      onSuccess: createContractSuccess,
      onError: createContractError,
    },
  )

  const { mutate: updateContract, isLoading: updateContractLoading } = useMutation(
    ContractsApi.updateContract,
    {
      onSuccess: updateContractSuccess,
      onError: updateContractError,
    },
  )

  return {
    contracts: data,
    isLoading,
    createContract,
    createContractLoading,
    isCreateContractModalOpen,
    setIsCreateContractModalOpen,
    updateContract,
    updateContractLoading,
    isUpdateContractModalOpen,
    setIsUpdateContractModalOpen,
  }
}

export { useContracts }
