import styled from 'styled-components'
import { Menu } from 'antd'

export const StyledMenu = styled(Menu)`
  height: 100%;
`
export const StyledMenuItem = styled(Menu.Item)`
  & span {
    margin-left: 5px;
  }
  @media ${props => props.theme.xs} {
    & .ant-menu-title-content {
      line-height: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      flex-grow: 1;
    }
  }
`
