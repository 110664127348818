import { api } from '@api'
import { Invoice } from '@types'

interface GetInvoicesResponse {
  invoices: Invoice[]
}

const getInvoices = async (ticketId: number) => {
  const response = await api.get<GetInvoicesResponse>(`/invoices/${ticketId}/ticket`)

  return response.data.invoices
}

export const InvoiceApi = { getInvoices }
