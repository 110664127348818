import { useTranslation } from 'react-i18next'
import { Table, Col } from 'antd'
import { SelectValue } from 'antd/lib/select'
import moment from 'moment'

import { useSupplierFilters } from '@contexts'
import { useSuppliers } from '@hooks'
import { Supplier, SupplierFilters } from '@types'

import { ContainerTable, StyledRow, StyledSelect } from './components'
import { useSuppliersPagination } from './hooks'

const SuppliersAbm = () => {
  const dateFormat = 'll'
  const { t } = useTranslation()
  const { filters, setFilters } = useSupplierFilters()
  const { filteredSuppliers, isFilteredLoading, suppliersOptions } = useSuppliers(filters)
  const pagination = useSuppliersPagination({ filters, setFilters }, filteredSuppliers?.total)

  const columns = [
    {
      title: t('admin_router.admin_dashboard.depotsabm.columns.sap_id'),
      dataIndex: 'sapId',
      key: 'sapId',
    },
    {
      title: t('admin_router.admin_dashboard.depotsabm.columns.business_name'),
      dataIndex: 'businessName',
      key: 'businessName',
    },
    {
      title: t('admin_router.admin_dashboard.depotsabm.columns.trade_name'),
      dataIndex: 'tradeName',
      key: 'tradeName',
    },
    {
      title: t('admin_router.admin_dashboard.depotsabm.columns.city'),
      dataIndex: 'city',
      key: 'city',
      render: (supplier: Supplier) => <>{supplier}</>,
    },
    {
      title: t('admin_router.admin_dashboard.depotsabm.columns.cellphone'),
      dataIndex: 'cellphone',
      key: 'cellphone',
    },
    {
      title: t('admin_router.admin_dashboard.depotsabm.columns.create_at'),
      dataIndex: 'createAt',
      key: 'createAt',
      render: (date: Date) => moment(date).format(dateFormat),
    },
    // {
    //   title: t('admin_router.admin_dashboard.depotsabm.columns.actions'),
    //   render: () => <>ADD DEPOT</>,
    // },
  ]

  const handleSupplierFilterChange = (value: SelectValue) => {
    setFilters((prevState: SupplierFilters) => ({
      ...prevState,
      page: 1,
      supplierId: [...(value as number[])],
    }))
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFilterOptions = (input: string, option: any) => {
    if (option.label) return option.label.toString().toLowerCase().includes(input.toLowerCase())
    return true
  }

  return (
    <ContainerTable>
      <StyledRow gutter={[20, 20]}>
        <Col span={6}>
          <StyledSelect
            mode="multiple"
            allowClear
            placeholder={`${t('admin_router.admin_dashboard.depotsabm.filters.filter_by')} ${t(
              'admin_router.admin_dashboard.depotsabm.columns.business_name',
            )}`}
            options={suppliersOptions}
            filterOption={handleFilterOptions}
            onChange={handleSupplierFilterChange}
            loading={isFilteredLoading}
            value={filters?.supplierId}
          />
        </Col>
      </StyledRow>
      <Table
        columns={columns}
        dataSource={filteredSuppliers?.suppliers}
        loading={isFilteredLoading}
        pagination={pagination}
        rowKey="id"
      />
    </ContainerTable>
  )
}

export { SuppliersAbm }
