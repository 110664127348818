/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useContext, Dispatch, SetStateAction } from 'react'

import { SupplierFilters } from '@types'

type ContextState = {
  filters?: SupplierFilters
  setFilters: Dispatch<SetStateAction<SupplierFilters>>
}

const initialState = {
  filters: { page: 1, limit: 10 },
  setFilters: () => {},
}

const SupplierFiltersContext = React.createContext<ContextState>(initialState)

export function SupplierFiltersProvider({ children }: { children: React.ReactNode }) {
  const [filters, setFilters] = useState<SupplierFilters>({ page: 1, limit: 10 })

  return (
    <SupplierFiltersContext.Provider value={{ filters, setFilters }}>
      {children}
    </SupplierFiltersContext.Provider>
  )
}

export function useSupplierFilters(): ContextState {
  const context = useContext(SupplierFiltersContext)
  if (!context)
    throw Error('This hook can only be used under the SupplierFiltersProvider component')
  return context
}
