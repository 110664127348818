import { Row } from 'antd'
import styled from 'styled-components'

const StyledCard = styled.div`
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  height: 70px;
  width: 150px;
`

const TotalText = styled.p`
  font-size: 14px;
  text-align: center;
  margin-bottom: 3px;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

const MoreThan24HoursText = styled.p`
  font-size: 14px;
  text-align: center;
  margin-bottom: 3px;
  margin-left: 3px;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  span {
    color: #eb2f96;
  }
`

const StatusName = styled.p`
  font-size: 12px;
  text-align: center;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

const TotalContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const StyledRow = styled(Row)`
  margin-bottom: 30px;
`

export { StyledCard, StatusName, TotalText, MoreThan24HoursText, TotalContainer, StyledRow }
