import { api } from '@api'

interface DownloadExcelResponse {
  file: {
    data: ArrayBuffer
  }
}

type Params = {
  rpaId: number
}

const downloadExcelFile = async (params: Params) => {
  const { rpaId } = params
  const response = await api.get<DownloadExcelResponse>('/data-sap/excel', {
    params: {
      rpaId,
    },
  })

  return response.data
}

export const DownloadExcelApi = { downloadExcelFile }
