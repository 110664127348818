import { useState } from 'react'
import { notification } from 'antd'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'

import { QUERY_KEYS } from '@api'
import { SupplierFilters } from '@types'

import { SuppliersApi } from './api'

const onError = () => {
  notification.error({
    message: 'Error al obtener los suppliers',
  })
}

const useSuppliers = (filters?: SupplierFilters) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const [isSupplierOptionsModalOpen, setIsSupplierOptionsModalOpen] = useState(false)

  const { data, isLoading } = useQuery([QUERY_KEYS.SUPPLIERS], SuppliersApi.getSuppliers, {
    onError,
  })

  const { data: filteredData, isLoading: isFilteredLoading } = useQuery(
    [QUERY_KEYS.SUPPLIERS, { filters }],
    () => SuppliersApi.getSuppliersWithFilters({ filters }),
    {
      onError,
    },
  )

  const onChangeSuccess = () => {
    notification.success({
      message: t('admin_router.admin_dashboard.categories.notifications.category_success'),
    })
    setIsSupplierOptionsModalOpen(false)
    queryClient.invalidateQueries(QUERY_KEYS.SUPPLIERS)
  }

  const suppliersOptions = data?.map(supplier => {
    return {
      label: supplier.businessName,
      value: supplier.id,
    }
  })

  const suppliersFiltered = filteredData?.suppliers.map(suppliers => {
    return {
      label: suppliers.businessName,
      value: suppliers.id,
    }
  })

  const onChangeError = () => {
    notification.error({
      message: t('admin_router.admin_dashboard.categories.notifications.category_error'),
    })
    setIsSupplierOptionsModalOpen(false)
    queryClient.invalidateQueries(QUERY_KEYS.SUPPLIERS)
  }

  const { mutate: changeOptions, isLoading: changeOptionsLoading } = useMutation(
    SuppliersApi.changeOptions,
    {
      onSuccess: onChangeSuccess,
      onError: onChangeError,
    },
  )

  return {
    suppliers: data,
    filteredSuppliers: filteredData,
    isLoading,
    isFilteredLoading,
    suppliersOptions,
    suppliersFiltered,
    isSupplierOptionsModalOpen,
    changeOptions,
    changeOptionsLoading,
    setIsSupplierOptionsModalOpen,
  }
}

export { useSuppliers }
