import { api } from '@api'
import { Carrier, CarrierFilters } from '@types'

interface GetCarriersResponse {
  carriers: Carrier[]
}

interface FilteredCarriersResponse {
  carriers: Carrier[]
  total: number
}

interface GetFilteredCarriersResponse {
  carriers: FilteredCarriersResponse
}

type Params = {
  filters?: CarrierFilters
}

export interface Form {
  carrierId: number
  category?: number
  enabledForAlgorithm?: boolean
}

const getCarriers = async () => {
  const response = await api.get<GetCarriersResponse>('/carriers')

  return response.data.carriers
}

const getCarriersWithFilters = async (params: Params) => {
  const { filters } = params
  const response = await api.get<GetFilteredCarriersResponse>('/carriers/filters', {
    params: {
      ...filters,
    },
  })

  return response.data.carriers
}

const changeOptions = async (payload: Form) => {
  const { carrierId, ...restOfValues } = payload
  const response = await api.put<GetCarriersResponse>(`/carriers/${carrierId}`, {
    carrier: restOfValues,
  })

  return response.data
}

export const CarriersApi = { getCarriers, getCarriersWithFilters, changeOptions }
