import styled from 'styled-components'
import { Typography } from 'antd'

const Container = styled.div`
  padding: 2%;
`

const StyledText = styled(Typography)`
  font-weight: bolder;
`

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify content: space-around;
  svg {
    margin: 0 0.3rem;
    cursor: pointer;
  }
`

export { Container, StyledText, IconContainer }
