import { notification } from 'antd'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'

import { QUERY_KEYS } from '@api'

import { PaymentApi } from './api'

const useCreatePayment = (ticketId: number) => {
  const { t } = useTranslation()

  const { push } = useHistory()

  const queryClient = useQueryClient()

  const onSuccess = () => {
    queryClient.invalidateQueries(QUERY_KEYS.INVOICES_BY_TICKET_ID)
    notification.success({
      message: `Trip ID ${ticketId}: ${t('ta_invoice.payment_voucher.success')}`,
    })
    push('/dashboard/admin')
  }

  const onError = (error: AxiosError) => {
    const message = error.response?.data.error.message.includes(
      'duplicate key value violates unique constraint',
    )
      ? t('ta_invoice.add_truck_documentation.errors.duplicate_invoice_number')
      : t('globals.failed_to_create_invoice')
    notification.error({ message })
  }

  const { mutate, isLoading, data } = useMutation(PaymentApi.createPayment, {
    onSuccess,
    onError,
  })

  return {
    createPayment: mutate,
    isLoading,
    createdPayment: data,
  }
}

export { useCreatePayment }
