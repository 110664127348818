import { useEffect, useState } from 'react'
import { Form, Row, Spin } from 'antd'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Recaptcha from 'react-google-recaptcha'

import { config } from '@config'
import { WebTitleEnum } from '@types'

import { LoginButton, StyledInput, LoginTitle, LoginContentContainer } from '../components'
import { useLogin } from '../hooks'

const MAX_ERROR_LOGIN_COUNT = 3

const LoginTraditional = () => {
  const [errorLoginCount, setErrorLoginCount] = useState(0)
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)

  const { login, isLoading } = useLogin({
    onError: () => {
      const newCount = errorLoginCount + 1
      setErrorLoginCount(newCount)
      if (newCount === MAX_ERROR_LOGIN_COUNT) {
        setIsSubmitDisabled(true)
      }
    },
  })
  useEffect(() => {
    document.title = WebTitleEnum.TITLE
  }, [])

  const { t } = useTranslation()

  const onChangeCaptcha = () => {
    setIsSubmitDisabled(false)
  }

  const onExpiredCaptcha = () => {
    setIsSubmitDisabled(true)
  }

  return (
    <Spin spinning={isLoading}>
      <LoginContentContainer>
        <LoginTitle>{t('login.login')}</LoginTitle>
        <Form
          name="normal_login"
          initialValues={{ remember: true }}
          onFinish={login}
          style={{ width: '100%' }}
        >
          <Form.Item
            name="userName"
            rules={[{ required: true, message: t('login.username_validation') }]}
          >
            <StyledInput placeholder={t('login.username')} />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: t('login.password_validation') }]}
          >
            <StyledInput type="password" placeholder={t('login.password')} />
          </Form.Item>
          <Form.Item>
            <Row justify="center">
              <Link to="/login/recover">{t('login.forgot_password')}</Link>
            </Row>
          </Form.Item>
          {errorLoginCount >= MAX_ERROR_LOGIN_COUNT && (
            <Form.Item>
              <Row justify="center">
                <Recaptcha
                  sitekey={config.recaptchaKey!}
                  onChange={onChangeCaptcha}
                  onExpired={onExpiredCaptcha}
                />
              </Row>
            </Form.Item>
          )}
          <Form.Item>
            <Row justify="center">
              <LoginButton
                type="primary"
                htmlType="submit"
                size="large"
                disabled={isSubmitDisabled}
              >
                {t('login.login')}
              </LoginButton>
            </Row>
          </Form.Item>
        </Form>
      </LoginContentContainer>
    </Spin>
  )
}

export { LoginTraditional }
