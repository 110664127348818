import { notification } from 'antd'
import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'

import { QUERY_KEYS } from '@api'

import { TicketsApi } from './api'

const useCancelTicket = (ticketId: number) => {
  const queryClient = useQueryClient()

  const { t } = useTranslation()

  const onError = () => {
    notification.error({
      message: t('suppliers_router.suppliers_dashboard.use_cancel_tickets.error'),
    })
  }

  const onSuccess = () => {
    queryClient.invalidateQueries(QUERY_KEYS.TICKETS)

    notification.success({
      message: [
        t('suppliers_router.suppliers_dashboard.use_cancel_tickets.success'),
        ` ID ${ticketId}`,
      ],
    })
  }

  const { mutate, isLoading } = useMutation(TicketsApi.cancelTicket, {
    onSuccess,
    onError,
  })

  return {
    cancelTicket: mutate,
    isLoading,
  }
}

export { useCancelTicket }
