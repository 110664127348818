import { Dispatch, SetStateAction } from 'react'
import { TablePaginationConfig } from 'antd/lib/table'

import { Filters } from '@types'

interface FiltersContextType {
  filters?: Filters
  setFilters: Dispatch<SetStateAction<Filters>>
}

const usePagination = ({ filters, setFilters }: FiltersContextType, total?: number) => {
  return {
    total,
    pageSize: filters?.limit,
    current: filters?.page || 1,
    position: ['bottomCenter'],
    size: 'small',
    showSizeChanger: false,
    showQuickJumper: false,
    onChange: page => setFilters(prev => ({ ...prev, page })),
  } as TablePaginationConfig
}

export { usePagination }
