/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useContext } from 'react'

type ContextState = {
  isDeleteTicketModalOpen: boolean
  setIsDeleteTicketModalOpen: (newstate: boolean) => void
}

const initialState = {
  isDeleteTicketModalOpen: false,
  setIsDeleteTicketModalOpen: () => {},
}

const HandleDeleteTicketModalContext = React.createContext<ContextState>(initialState)

export function HandleDeleteTicketModalProvider({ children }: { children: React.ReactNode }) {
  const [isDeleteTicketModalOpen, setIsDeleteTicketModalOpen] = useState<boolean>(false)

  return (
    <HandleDeleteTicketModalContext.Provider
      value={{ isDeleteTicketModalOpen, setIsDeleteTicketModalOpen }}
    >
      {children}
    </HandleDeleteTicketModalContext.Provider>
  )
}

export function useHandleDeleteTicketModal(): ContextState {
  const context = useContext(HandleDeleteTicketModalContext)
  if (!context)
    throw Error(
      'This hook can only be used under the HandleDeleteTicketModalContextProvider component',
    )
  return context
}
