import { api } from '@api'
import { Destination } from '@types'

interface GetDestinationsResponse {
  destinations: Destination[]
}

const getDestinations = async () => {
  const response = await api.get<GetDestinationsResponse>('/destinations')

  return response.data.destinations
}

export const DestinationsApi = { getDestinations }
