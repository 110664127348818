import { notification } from 'antd'
import { useMutation } from 'react-query'

import { DownloadApi } from './api'

const onError = () => {
  notification.error({
    message: 'Error al descargar el archivo',
  })
}

interface DownloadResponse {
  file: {
    Body: { data: ArrayBuffer }
  }
  name: string
}

const useDownload = () => {
  const onSuccess = (data: DownloadResponse) => {
    const byteArray = new Uint8Array(data.file.Body.data)
    const a = window.document.createElement('a')

    a.href = window.URL.createObjectURL(new Blob([byteArray], { type: 'application/octet-stream' }))
    a.download = data.name

    document.body.appendChild(a)
    a.click()

    document.body.removeChild(a)
  }

  const { mutate, isLoading } = useMutation(DownloadApi.downloadFile, {
    onSuccess: data => onSuccess(data),
    onError,
  })

  return {
    downloadFile: mutate,
    isLoading,
  }
}

export { useDownload }
