import styled from 'styled-components'

const Container = styled.div`
  padding: 2%;
`

const Wrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export { SupplierDocumentation } from './SupplierDocumentation'
export { NewInvoice } from './NewInvoice'
export { PaymentVoucher } from './PaymentVoucher'
export { Wrapper, Container }
