import { api } from '@api'
import { Ticket } from '@types'

import { Form } from '../../validations'

interface CreateRailcarDocumentationResponse {
  railcarDocumentation: Ticket
}

const createRailcarDocumentation = async (payload: Form) => {
  const { salesInvoice, weightCertificate, BL, radioactiveCertificate, photos, ...restOfValues } =
    payload

  const railcarDocumentationPayload = new FormData()

  const files = [salesInvoice, weightCertificate, BL, radioactiveCertificate, ...photos]

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < files.length; i++) {
    railcarDocumentationPayload.append('files[]', files[i])
  }

  railcarDocumentationPayload.append('railcarDocumentation', JSON.stringify(restOfValues))
  const response = await api.post<CreateRailcarDocumentationResponse>(
    '/railcar-documentation',
    railcarDocumentationPayload,
  )

  return response.data
}

export const RailcarDocumentationApi = { createRailcarDocumentation }
