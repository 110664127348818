/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useContext } from 'react'

type ContextState = {
  isNewTicketModalOpen: boolean
  setIsTicketModalOpen: (newstate: boolean) => void
}

const initialState = {
  isNewTicketModalOpen: false,
  setIsTicketModalOpen: () => {},
}

const HandleNewTicketModalContext = React.createContext<ContextState>(initialState)

export function HandleNewTicketModalProvider({ children }: { children: React.ReactNode }) {
  const [isNewTicketModalOpen, setIsTicketModalOpen] = useState<boolean>(false)

  return (
    <HandleNewTicketModalContext.Provider value={{ isNewTicketModalOpen, setIsTicketModalOpen }}>
      {children}
    </HandleNewTicketModalContext.Provider>
  )
}

export function useHandleNewTicketModal(): ContextState {
  const context = useContext(HandleNewTicketModalContext)
  if (!context)
    throw Error(
      'This hook can only be used under the HandleNewTicketModalContextProvider component',
    )
  return context
}
